import { useRouter } from "next/router";
import { APP } from "@config/app";
import AppLayout from "./AppLayout";
import SiteLayout from "@components/site/layout/SiteLayout";
import SocialShare from "@components/socialshare/SocialShare";
import BetaSignupToast from "@components/site/JoinBeta/BetaSignupToast";
import SignUpModal from "@components/auth/modals/SignUpModal";

type BaseLayoutProps = {
    children: React.ReactNode;
};

const BaseLayout = ({ children }: BaseLayoutProps): JSX.Element => {
    const router = useRouter();
    const currentPath = router.asPath;

    return (
        <>
            {/* Remove when we remove betagate or replace with beta specific social share info */}
            {/* <SocialShare image="https://www.beyondrarity.com/images/br-socialshare.png" /> */}
            <SignUpModal />
            {currentPath.startsWith(APP.PATH) ? (
                <AppLayout>{children}</AppLayout>
            ) : (
                <SiteLayout>
                    {children}
                    {/* <BetaSignupToast /> */}
                </SiteLayout>
            )}
        </>
    );
};

export default BaseLayout;
