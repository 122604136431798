const layerStyles = {
    collectionPage: {
        w: "100%",
        minH: "100vh",
        overflow: "hidden",
        display: "flex",
    },

    darkBluePanel: {
        background: "panel.bg.dark",
        py: "6px",
        px: "24px",
        rounded: "lg",
        position: "relative",
    },
    lightBluePanel: {
        background: "spaceCadetLight",
        py: "6px",
        px: "24px",
        rounded: "lg",
        position: "relative",
    },

    slidingPanel: {
        bg: "panel.bg.dark",
        borderRightRadius: "lg",
        p: "10px 15px",
        position: "relative",
    },

    slidingPanelTab: {
        fontFamily: "regular",
        bg: "panel.bg.dark",
        whiteSpace: "nowrap",
        borderRightRadius: "md",
        p: "15px 5px",
        fontSize: "13px",
        transform: "translate(calc(100% - 1px), -50%)",
        top: "50%",
        right: "0",
        boxShadow: "inherit",
        userSelect: "none",
        cursor: "pointer",
    },
    header: {
        container: {
            background: "spaceCadetLight",
            py: "6px",
            px: "24px",
            rounded: "lg",
            position: "relative",
            flexDirection: "row",
            gap: 4,
            justifyContent: "flex-end",
            alignItems: "center",
            overflow: "hidden",
            h: "auto",
            color: "rythm",
        },
        dataContainer: {
            display: "flex",
            minH: "28px",
            position: "relative",
            overflow: "hidden",
            w: "100%",
            alignItems: "center",
            justifyContent: "space-between",
        },
    },
    chartContainer: {
        background: "panel.bg.dark",
        rounded: "lg",
        position: "relative",
        flex: "1",
        w: "100%",
        minW: "0",
        h: "100%",
        p: "10px 10px 10px 30px",
    },

    gridItem: {
        border: "1px dotted yellow",
    },
    skeleton: {
        color: "steelblue.400",
        bg: "steelblue.400",
        opacity: 0.5,
    },
    mainGrid: {
        position: "relative",
        bg: "spaceCadetLight",
        p: "15px 15px 15px 0",
        w: "100%",
        rounded: "xl",
        overflow: "hidden",
        filter: "drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.35))",
    },
    fluidChart: {
        container: {
            flexDirection: "row",
            h: "100%",
            position: "relative",
            gap: "15px",
        },
        slidingPanel: {
            position: "relative",
            h: "100%",
            flex: "0 1 auto",
            transition: "width .2s",
            pointerEvents: "none",
        },
        innerPanel: {
            h: "100%",
            position: "absolute",
            right: 0,
        },
    },
    // Chart
    chart: {
        mobile: {
            container: {
                // px: "5px",
                w: "100%",
                flexDirection: "column",
                gap: 2,
            },
            hotspotChart: {
                flex: 1,
                flexDirection: "column",
                w: "100%",
            },
        },
    },
    // Breadrcumbs bar
    breadcrumbs: {
        container: {
            pl: "20px",
        },
        chip: {
            background: "#464E6C",
            p: ".4rem .3rem .4rem .6rem",
            rounded: ".2rem",
            alignItems: "center",
            minH: "28px",
            maxW: "140px",
        },
        button: {
            background: "blue.dark",
            p: ".4rem .3rem .4rem .6rem",
            rounded: ".2rem",
            alignItems: "center",
            cursor: "pointer",
            minH: "28px",
            transition: "background-color .2s, color .2s",
            _hover: {
                color: "gold.500",
            },
        },
    },
    // Sort menu
    sortMenu: {
        desktop: {
            gap: 3,
            p: "12px 25px 18px 25px",
            minW: "342px",
            bg: "blue.darker",
            rounded: "lg",
            display: "inline-flex",
            alignItems: "flex-start",
        },
    },
    // Filters sidebar
    filters: {
        drawerContainer: {
            position: "fixed",
            left: 0,
            top: 0,
            mt: "0 !important",
            transform: "translate3d(-100%,0,0)",
            zIndex: 20,
            h: "100%",
            w: "100vw",
            background: "panel.bg.dark",
            transition: "transform .3s",
        },
        drawerScroll: {
            p: "0 0 50px 0",
            w: "100%",
            maxH: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            overscrollBehavior: "contain",
            webkitOverflowScrolling: "touch",
        },
        drawer: {
            background: "panel.bg.dark",
            p: "5px 0px 0px 0px",
            position: "relative",
        },
        addToken: {
            errorMsg: {
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: "4px",
                right: "38px",
                h: "calc(100% - 8px)",
                bg: "rgba(38,44,60,.85)",
                p: "0 6px",
                rounded: "3px",
                zIndex: "10",
                pointerEvents: "none",
            },
        },
        collapsable: {
            container: {
                width: "100%",
                rounded: "lg",
                bg: "#2F3546",
                border: "1px solid #464E6C",
                fontSize: "14px",
                lineHeight: "20px",
                overflow: "hidden",
            },
            toggleButton: {
                w: "100%",
                px: 4,
                py: 2,
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
            },
            content: {
                w: "100%",
                px: 4,
                margin: 0,
            },
        },
        traits: {
            accordion: {
                item: {
                    border: "1px solid #464E6C",
                    rounded: "md",
                    mb: 2,
                    bg: "#2F3546",
                },
                button: {
                    py: "0.2rem",
                    _focus: {
                        ring: "none",
                        boxShadow: "none",
                    },
                    _active: {
                        ring: "none",
                    },
                },
                labelContainer: {
                    flex: "1",
                    h: "20px",
                    textAlign: "left",
                    fontSize: "14px",
                    justifyContent: "space-between",
                },
                buttonLabel: {
                    display: "inline",
                    flex: "1 1",
                    wordBreak: "break-all",
                },
                labelCount: {
                    pl: "8px",
                    pr: "4px",
                    color: "#a3a3a3",
                },
                virtualListScroll: {
                    position: "relative",
                    h: "auto",
                    w: "calc(100% + 10px)",
                    pr: "10px",
                    overflow: "auto",
                    overflowX: "hidden",
                },
            },
            value: {
                item: {
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                },
                checkbox: {
                    w: "12px",
                    h: "12px",
                    border: "1px solid",
                    borderColor: "gray.faint",
                    flex: "0 1 12px",
                    rounded: "3px",
                    transition: "background-color .15s",
                    cursor: "pointer",
                    color: "steelblue.900",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
            },
        },
        marketplaces: {
            item: {
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: 2,
            },
        },
    },
    // Token Modal
    tokenModal: {
        invalid: {
            overlay: {
                flexDirection: "column",
                inset: "0",
                bg: "#262C3Cee", // blue.dark with some transparency
                zIndex: "10",
                position: "absolute",
            },
        },
        imageLoadError: {
            direction: "column",
            fontSize: "18px",
            bg: "app.light",
            h: "100%",
            w: "100%",
            justifyContent: "center",
            alignItems: "center",
        },
    },
    tutorials: {
        modal: {
            overlay: {
                bgColor: "#000a",
                backdropFilter: "auto",
                backdropBlur: "3px",
            },
            headerContainer: {
                bg: "raisinBlack",
                w: "100%",
                h: "70px",
                position: "relative",
                overflow: "hidden",
                roundedTop: {
                    base: "none",
                    sm2: "lg",
                },
            },
            header: {
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                fontSize: "12px",
                alignItems: "center",
                transition: "all .25s",
                cursor: "pointer",
            },
            body: {
                p: "0",
                position: {
                    base: "initial",
                    sm2: "relative",
                },
                overflow: "hidden",
                roundedBottom: "lg",
                bg: "spaceCadetLight",
            },
            grid: {
                gap: "16px",
                w: "100%",
                p: "16px",
                overflowY: "auto",
                overflowX: "hidden",
            },
            closeButton: {
                pos: "absolute",
                w: "30px",
                h: "30px",
                borderRadius: "50%",
                bgColor: "gray.light",
                top: "-10px",
                right: "-10px",
                color: "blue.dark",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 200,
            },
        },
    },
    auth: {
        modals: {
            base: {
                rounded: { base: "none", md: "lg" },
                w: { base: "100%", md: "95%" },
                maxW: { base: "none", md: "740px" },
                bg: "#F4F7FA",
                color: "#000",
                minH: { base: "100vh", md: "initial" },
            },
        },
    },
};

export default layerStyles;
