import { DESKTOP_SIZE, MAX_SIZE, MIN_SIZE } from "@config/app";

const powers: number[] = [
    1, 1e1, 1e2, 1e3, 1e4, 1e5, 1e6, 1e7, 1e8, 1e9, 1e10, 1e11, 1e12, 1e13, 1e14, 1e15, 1e16, 1e17,
    1e18, 1e19, 1e20, 1e21, 1e22,
];

export const disableBodyScroll = (): void => {
    document.body.style.setProperty("overflow", "hidden", "important");
    document.body.style.overscrollBehavior = "contain";
    document.body.style.setProperty("position", "relative", "important");
    document.body.style.paddingLeft = "0px";
    document.body.style.paddingTop = "0px";
    document.body.style.paddingRight = "0px";
    document.body.style.marginLeft = "0";
    document.body.style.marginTop = "0";
    document.body.style.setProperty("marginRight", "15px", "important");
    document.body.style.touchAction = "none";
};

export const enableBodyScroll = (): void => {
    document.body.style.overflow = null;
    document.body.style.overscrollBehavior = null;
    document.body.style.position = null;
    document.body.style.paddingLeft = null;
    document.body.style.paddingTop = null;
    document.body.style.paddingRight = null;
    document.body.style.marginLeft = null;
    document.body.style.marginTop = null;
    document.body.style.marginRight = null;
    document.body.style.touchAction = null;
};

const intPow10 = (power: number): number => {
    if (power < 0 || power > 22) {
        return Math.pow(10, power);
    }
    return powers[power];
};

export const isInteger = (val: string | number): boolean => /^[0-9]+$/.test(String(val));

export const isNumber = (val: string): boolean => /^[0-9]\d*(\.\d+)?$/.test(String(val));

export const isValidRange = (min: number | null, max: number | null): boolean => {
    return min !== null && max !== null && min >= 0 && max >= 0 && max >= min;
};

export const isValidNumber = (val: number | null): boolean => {
    return val !== null;
};

export const roundNumber = (num: number, decimalPlaces: number): number => {
    var p: number = intPow10(decimalPlaces || 0);
    var n: number = num * p * (1 + Number.EPSILON);
    return Math.round(n) / p;
};

const compactNumberFormatter = Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
});

export const compactNumber = (val: number): string => {
    return compactNumberFormatter.format(val);
};

export const compactDecimalsNumber = (val: number, maximumFractionDigits: number = 2): string => {
    const formatter = new Intl.NumberFormat("en-US", {
        notation: "compact",
        compactDisplay: "short",
        maximumFractionDigits,
    });

    return formatter.format(val);
};

export const parseFeaturedCollections = (
    allCollections: CollectionBaseData[],
    minItems = 0,
    skipStartInclusive = null,
    skipStopExclusive = null,
) => {
    const now = new Date();
    const slottedFeatured = new Array<CollectionBaseData>();
    const unslottedFeaturedActive = new Array<CollectionBaseData>();
    const unslottedFeaturedStartedExpired = new Array<CollectionBaseData>();

    allCollections.forEach((collection) => {
        if (collection.featured && collection.featured > 0) {
            slottedFeatured.push(collection);
        } else if (
            collection.featured &&
            collection.featured < 0 &&
            collection.featuredStartDate.getTime() <= now.getTime() &&
            collection.featuredEndDate.getTime() >= now.getTime()
        ) {
            unslottedFeaturedActive.push(collection);
        } else if (
            collection.featured &&
            collection.featured < 0 &&
            collection.featuredStartDate.getTime() <= now.getTime() &&
            collection.featuredEndDate.getTime() < now.getTime()
        ) {
            unslottedFeaturedStartedExpired.push(collection);
        }
    });

    slottedFeatured.sort((a, b) => a.featured - b.featured);

    unslottedFeaturedActive.sort((a, b) => {
        if (a.featuredStartDate.getTime() != b.featuredStartDate.getTime()) {
            return b.featuredStartDate.getTime() - a.featuredStartDate.getTime();
        }

        if (a.featuredEndDate.getTime() != b.featuredEndDate.getTime()) {
            return b.featuredEndDate.getTime() - a.featuredEndDate.getTime();
        }

        return b.firstPublishedToProduction.getTime() - a.firstPublishedToProduction.getTime();
    });

    unslottedFeaturedStartedExpired.sort((a, b) => {
        if (a.featuredEndDate.getTime() != b.featuredEndDate.getTime()) {
            return b.featuredEndDate.getTime() - a.featuredEndDate.getTime();
        }

        if (a.featuredStartDate.getTime() != b.featuredStartDate.getTime()) {
            return b.featuredStartDate.getTime() - a.featuredStartDate.getTime();
        }

        return b.firstPublishedToProduction.getTime() - a.firstPublishedToProduction.getTime();
    });

    let numberOfFeaturedItems = Math.max(
        slottedFeatured.length +
            unslottedFeaturedActive.length +
            (skipStopExclusive && skipStartInclusive ? skipStopExclusive - skipStartInclusive : 0),
        minItems,
    );

    const parsedFeatured = new Array<CollectionBaseData>();
    let slottedArrayIndex = 0;
    let unslottedActiveArrayIndex = 0;
    let unslottedExpiredArrayIndex = 0;
    for (let i = 0; i < numberOfFeaturedItems; i++) {
        const currentSlottedCollection =
            slottedArrayIndex < slottedFeatured.length ? slottedFeatured[slottedArrayIndex] : null;
        const currentUnslottedActiveCollection =
            unslottedActiveArrayIndex < unslottedFeaturedActive.length
                ? unslottedFeaturedActive[unslottedActiveArrayIndex]
                : null;
        const currentUnslottedExpiredCollection =
            unslottedExpiredArrayIndex < unslottedFeaturedStartedExpired.length
                ? unslottedFeaturedStartedExpired[unslottedExpiredArrayIndex]
                : null;

        // console.debug("index: ", i);
        // console.debug("currentSlottedCollection: ", currentSlottedCollection);
        // console.debug("currentUnslottedActiveCollection: ", currentUnslottedActiveCollection);
        // console.debug("currentUnslottedExpiredCollection: ", currentUnslottedExpiredCollection);

        if (
            skipStartInclusive &&
            skipStopExclusive &&
            i >= skipStartInclusive &&
            i < skipStopExclusive
        ) {
            // console.debug("getlisted card");
            parsedFeatured.push(null);
            continue;
        } else if (currentSlottedCollection != null && currentSlottedCollection.featured == i + 1) {
            // console.debug("slotted");
            parsedFeatured.push(currentSlottedCollection);
            slottedArrayIndex++;
            continue;
        } else if (currentUnslottedActiveCollection != null) {
            // console.debug("unslotted");
            parsedFeatured.push(currentUnslottedActiveCollection);
            unslottedActiveArrayIndex++;
            continue;
        } else if (currentUnslottedExpiredCollection != null) {
            // console.debug("unslotted");
            parsedFeatured.push(currentUnslottedExpiredCollection);
            unslottedExpiredArrayIndex++;
            continue;
        } else {
            // console.debug("dummy");
            parsedFeatured.push(null);
        }
    }

    // console.debug("all: ", allCollections);
    // console.debug("slotted: ", slottedFeatured);
    // console.debug("unslotted-active: ", unslottedFeaturedActive);
    // console.debug("unslotted-expired: ", unslottedFeaturedStartedExpired);
    // console.debug("parsed: ", parsedFeatured);

    return {
        slottedFeatured,
        unslottedFeaturedActive,
        unslottedFeaturedStartedExpired,
        parsedFeatured,
    };
};

export const splitFeaturedCollections = (allCollections) => {
    let featuredList = [];
    let standardList = [];

    if (allCollections?.length) {
        allCollections.forEach((collection) => {
            if (!collection.featured) {
                standardList.push(collection);
            }
        });

        standardList.sort((a, b) => a.name.localeCompare(b.name));

        const {
            slottedFeatured,
            unslottedFeaturedActive,
            unslottedFeaturedStartedExpired,
            parsedFeatured,
        } = parseFeaturedCollections(allCollections);

        featuredList = parsedFeatured;
    }

    return {
        featured: featuredList,
        standard: standardList,
    };
};

type SortOrderLabels = {
    sortLabel: string;
    orderLabel: string;
};

type RankSortOrderLabels = {
    sortLabel: "Rarity Rank";
    orderLabel: "Worst Rank First" | "Best Rank First";
};

type PriceSortOrderLabels = {
    sortLabel: "Price";
    orderLabel: "Lowest Price First" | "Highest Price First";
};

type PriceDeviationSortOrderLabels = {
    sortLabel: "Price Deviation";
    orderLabel: "Most Underpriced First" | "Most Overpriced First";
};

export const getSortOrderLabels = (field: SortCriteria, order: SortOrder): SortOrderLabels => {
    if (field === "rank") {
        const result: RankSortOrderLabels = {
            sortLabel: "Rarity Rank",
            orderLabel: order == "asc" ? "Worst Rank First" : "Best Rank First",
        };
        return result;
    } else if (field === "currentPrice") {
        const result: PriceSortOrderLabels = {
            sortLabel: "Price",
            orderLabel: order == "asc" ? "Lowest Price First" : "Highest Price First",
        };
        return result;
    } else if (field === "priceDeviation") {
        const result: PriceDeviationSortOrderLabels = {
            sortLabel: "Price Deviation",
            orderLabel: order == "asc" ? "Most Overpriced First" : "Most Underpriced First",
        };
        return result;
    }
};

export const getWindowOrientation = (): ScreenOrientationType => {
    if (typeof window === "undefined") return "landscape";

    const iosOrientation = window?.orientation;
    const screenOrientation = screen?.orientation?.type;

    // if (screenOrientation) {
    //     return screenOrientation.toLowerCase().startsWith("portrait") ? "portrait" : "landscape";
    // } else if (!isNaN(iosOrientation) && iosOrientation !== undefined) {
    //     return iosOrientation === 0 ? "portrait" : "landscape";
    // }

    // Use dimensions instead of screen orientation
    if (window.innerHeight > window.innerWidth) return "portrait";

    return "landscape";
};

export const isDesktopSize = (basisType: "app" | "site", basisWidth: number = null): boolean => {
    if (typeof window === "undefined") return false;
    if (basisType === "app") {
        return getWindowOrientation() === "landscape" && window.innerWidth >= (basisWidth || 960);
    } else {
        return window.innerWidth >= (basisWidth || 960);
    }
};

export const isPortraitSize = (): boolean => {
    if (typeof window === "undefined") return false;
    return getWindowOrientation() === "portrait" && window.innerWidth < window.innerHeight;
};

export const isNarrowPortraitSize = (): boolean => {
    if (typeof window === "undefined") return false;
    return isPortraitSize() && window.innerWidth <= 380;
};

export const scale = (
    currentSize: number,
    minSize: number,
    maxSize: number,
    minOutput: number,
    maxOutput: number,
): number => {
    const range = maxSize - minSize;
    const outputRange = maxOutput - minOutput;

    const result =
        Math.max(0, Math.min(((currentSize - minSize) / range) * outputRange, outputRange)) +
        minOutput;

    // console.debug("currentSize: ", currentSize);
    // console.debug("minSize: ", minSize);
    // console.debug("maxSize: ", maxSize);
    // console.debug("minOutput: ", minOutput);
    // console.debug("maxOutput: ", maxOutput);
    // console.debug("range: ", range);
    // console.debug("outputRange: ", outputRange);
    // console.debug("scale result: ", result);
    return result;
};

export const standardScale = (
    currentSize: number,
    isDesktop: boolean,
    minOutput: number,
    maxOutput: number,
): number => {
    const minSize = isDesktop ? DESKTOP_SIZE : MIN_SIZE;
    const maxSize = isDesktop ? MAX_SIZE : DESKTOP_SIZE;
    const range = maxSize - minSize;
    const outputRange = maxOutput - minOutput;

    const result = scale(currentSize, minSize, maxSize, minOutput, maxOutput);
    
    return result;
};

export const scaledPixel = (base: number, scale: number): string => {
    return `${base * scale}px`;
};

type ScaleValues = {
    scale: number;
    scalePercent: number;
    scalePercentText: string;
};
export const getScaleValues = (scale: number): ScaleValues => {
    return {
        scale: scale,
        scalePercent: scale * 100,
        scalePercentText: `${scale * 100}%`,
    };
};

type OverRangeValues = {
    overRangePointY: number;
    maxY: number;
};
export const getOverRangeValues = (overRangePrice: number, isDesktop: boolean): OverRangeValues => {
    const pointYSpaceMultiplier = isDesktop ? 1.05 : 1.15;
    const maxYSpaceMultiplier = isDesktop ? 1.015 : 1.06;

    const overRangePointY = overRangePrice * pointYSpaceMultiplier;
    const maxY = overRangePointY * maxYSpaceMultiplier;

    return {
        overRangePointY,
        maxY,
    };
};

export const getResponsiveFunction = (isDesktopFunction: Function) => {
    return (desktopValue: any, mobileValue: any) => {
        if (isDesktopFunction()) {
            return desktopValue;
        } else {
            return mobileValue;
        }
    };
};

export const shortenEthAddress = (address: string, segmentSize: number = 5): string => {
    if (!address) {
        return "";
    } else if (address.length > 10) {
        return `${address.slice(0, segmentSize)}...${address.slice(-segmentSize)}`;
    } else {
        return address;
    }
};
