import { Box, Flex, Text } from "@chakra-ui/react";
import QuickTourDynamic from "@components/quickTour/QuickTourDynamic";
import { Footer } from "@components/site/layout";
import RecentlyAddedProjectsSlider from "@components/site/projects/RecentlyAddedProjectsSlider";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";

type AppLayoutProps = {
    children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps): JSX.Element => {
    // const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: true });
    return (
        <>
            {/* <QuickTourDynamic /> */}

            <Box position="relative" minW="100vw" minH="100vh" zIndex={2}>
                {children}
            </Box>
            
            {/* MOVED Footer TO COLLECTION PAGE WHILE BETAGATE */}
            <Footer /> 
           
        </>
    );
};

export default AppLayout;
