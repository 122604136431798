import { FC, ReactElement } from "react";
import { forwardRef, Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps} from "@chakra-ui/react";

type TooltipProps = {
    children?: React.ReactNode;
};

export const Tooltip = ({ children, ...props } : TooltipProps & ChakraTooltipProps) => {
    return (
        <>
            <ChakraTooltip bgColor="#0003" fontSize="xs" fontWeight="light" {...props}>
                {children}
            </ChakraTooltip>
        </>
    );
};

export default Tooltip;
