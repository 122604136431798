import { Box, Flex } from "@chakra-ui/react";
import useUser from "@hooks/useUser";
import UserMenuButton from "./UserMenuButton";
import UserSignInButton from "./UserSignInButton";
import UserSignUpButton from "./UserSignUpButton";

type UserLoginProps = { isMobile?: boolean; onMobileModalMenuClose?: Function };

export const UserLogin = ({ isMobile, onMobileModalMenuClose }: UserLoginProps): JSX.Element => {
    const { user, status } = useUser();
    const userButtonSize = isMobile ? "36px" : "26px";

    if (!status || status == "loading") {
        return null;
    }

    if (isMobile) {
        return (
            <>
                {!user ? (
                    <Flex direction="column" w="100%" justify="center" align="center" gap={4}>
                        <Box w="100%">
                            <UserSignUpButton
                                isMobile
                                onMobileModalMenuClose={onMobileModalMenuClose}
                            />
                        </Box>
                        <Box w="100%">
                            <UserSignInButton
                                isMobile
                                onMobileModalMenuClose={onMobileModalMenuClose}
                            />
                        </Box>
                    </Flex>
                ) : (
                    <UserMenuButton
                        buttonSize={userButtonSize}
                        isMobile
                        onMobileModalMenuClose={onMobileModalMenuClose}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {!user ? (
                <Flex direction="row" gap="6px" justify="center" align="center">
                    <UserSignUpButton />
                    <UserSignInButton />
                </Flex>
            ) : (
                <UserMenuButton buttonSize={userButtonSize} />
            )}
        </>
    );
};

export default UserLogin;
