import create from "zustand";
import produce, { enableMapSet } from "immer";
import { subscribeWithSelector } from "zustand/middleware";
import { isValidRange, isValidNumber } from "@utils/helpers";
import useCollectionStore from "@store/collectionStore";
import useSessionStore, { getDefaultSortOptions } from "./sessionStore";

// Enables ES6 Map support for immer https://immerjs.github.io/immer/map-set
enableMapSet();

type FiltersState = {
    minPrice: number | null;
    maxPrice: number | null;
    priceFilterLabel: string | null;
    minRank: number | null;
    maxRank: number | null;
    rankFilterLabel: string | null;
    minDeviation: number | null;
    marketplaces: MarketplacesFilters;
    saleStatus: SaleStatus;
    tokenStringIds: TokenStringId[];
    traits: FilteredTraitsValues;
    vibes: FilteredVibes;
    sortBy: SortCriteria;
    sortOrder: SortOrder;
    // isUserSignedIn: boolean;
};

const initialState: FiltersState = {
    minPrice: null,
    maxPrice: null,
    priceFilterLabel: null,
    minRank: null,
    maxRank: null,
    rankFilterLabel: null,
    minDeviation: null,
    marketplaces: new Map<number, boolean>(),
    saleStatus: "all",
    tokenStringIds: [],
    traits: new Map<TraitId, TraitFilter>(),
    vibes: new Map<VibeId, string>(),
    sortBy: getDefaultSortOptions().sortCriteria,
    sortOrder: getDefaultSortOptions().sortOrder,
    // isUserSignedIn: true,
};

// Store for filters and sort state.
export const useFiltersStore = create(subscribeWithSelector(() => ({ ...initialState })));

// Reset the collection specific filters on collection change
const unsubscribeResetFilters = useCollectionStore.subscribe(
    (state) => state.collectionId,
    (newCollectionId) => {
        useFiltersStore.setState((state) => ({ ...initialState }));
    },
);

// Set default sorting based on user status (connected/disconnected)
export const unsubscribeConnected = useSessionStore.subscribe(
    (state) => state.connected,
    (isConnected) => {
        if (isConnected) {
            setSortBy("priceDeviation");
            setSortOrder("desc");
        } else {
            setSortBy("rank");
            setSortOrder("desc");
        }
    },
);

export const resetFilters = (): void => {
    useFiltersStore.setState(() => ({
        minPrice: null,
        maxPrice: null,
        priceFilterLabel: null,
        minRank: null,
        maxRank: null,
        rankFilterLabel: null,
        minDeviation: null,
        marketplaces: new Map<number, boolean>(),
        saleStatus: "all",
        tokenStringIds: [],
        traits: new Map<TraitId, TraitFilter>(),
        vibes: new Map<VibeId, string>(),
    }));
};

export const setPriceFilter = (
    minPrice: number | null,
    maxPrice: number | null,
    label?: string,
): void => {
    if (!isNaN(minPrice) && !isNaN(maxPrice) && minPrice > maxPrice) {
        console.warn(
            `'minPrice' (${minPrice}) should be equal or greater than 'maxPrice' (${maxPrice})`,
        );
        return;
    }

    // TODO: Check other not allowed values for minPrice and maxPrice
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            if (state.minPrice !== minPrice) state.minPrice = minPrice;
            if (state.maxPrice !== maxPrice) state.maxPrice = maxPrice;
            state.priceFilterLabel = label || null;
        }),
    );
};

export const resetPriceFilter = (): void => {
    useFiltersStore.setState(() => ({
        minPrice: initialState.minPrice,
        maxPrice: initialState.maxPrice,
        priceFilterLabel: initialState.priceFilterLabel,
    }));
};

/**
 *
 * @param minRank
 * @param maxRank
 * @param label: An optional label to show in the breadcrumb for this particular filter.
 */
export const setRankFilter = (
    minRank: number | null,
    maxRank: number | null,
    label?: string,
): void => {
    // TODO: Check that minRank doesn't go below lowest rank value for collection
    // TODO: Check that maxRank doesn't go beyond highest rank value for collection
    // TODO: Check that minRank <= maxRank
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            if (state.minRank !== minRank) state.minRank = minRank;
            if (state.maxRank !== maxRank) state.maxRank = maxRank;
            state.rankFilterLabel = label || null;
        }),
    );
};

export const resetRankFilter = (): void => {
    useFiltersStore.setState(() => ({
        minRank: initialState.minRank,
        maxRank: initialState.maxRank,
        rankFilterLabel: initialState.rankFilterLabel,
    }));
};

export const setMinDeviationFilter = (minDeviation: number | null): void => {
    // TODO: minDeviation should be >= 0? Or can it be a negative value?
    // TODO: Define a range for minDeviation value, i.e.: [-10, 10]
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            if (state.minDeviation !== minDeviation) state.minDeviation = minDeviation;
        }),
    );
};

export const setSaleStatusFilter = (status: SaleStatus): void => {
    useFiltersStore.setState(() => ({ saleStatus: status }));
};

export const resetSaleStatusFilter = (status: SaleStatus): void => {
    useFiltersStore.setState(() => ({ saleStatus: "all" }));
};

export const resetMinDeviationFilter = (): void => {
    useFiltersStore.setState(() => ({
        minDeviation: initialState.minDeviation,
    }));
};

export const addTokenIdFilter = (tokenStringId: TokenStringId): void => {
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            const { tokenStringIds } = state;

            if (!tokenStringIds.includes(tokenStringId)) {
                tokenStringIds.push(tokenStringId);
            } else {
                console.warn("The tokenId", tokenStringId, "is already added to filters.");
            }
        }),
    );
};

export const removeTokenIdFilter = (tokenStringId: TokenStringId): void => {
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            const { tokenStringIds } = state;
            const tokenStringIdIndex = tokenStringIds.findIndex((tid) => tid === tokenStringId);

            if (tokenStringIdIndex >= 0) {
                tokenStringIds.splice(tokenStringIdIndex, 1);
            } else {
                console.warn("The tokenId", tokenStringId, "is not in filters.");
            }
        }),
    );
};

export const resetTokenIdFilter = (): void => {
    useFiltersStore.setState(() => ({
        tokenStringIds: initialState.tokenStringIds,
    }));
};

export const setSortValues = (sortBy: SortCriteria, sortOrder: SortOrder): void => {
    useFiltersStore.setState(() => ({ sortBy: sortBy, sortOrder: sortOrder }));
};

export const setSortBy = (sortBy: SortCriteria): void => {
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            if (state.sortBy !== sortBy) state.sortBy = sortBy;
        }),
    );
};

export const setSortOrder = (sortOrder: SortOrder): void => {
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            if (state.sortOrder !== sortOrder) state.sortOrder = sortOrder;
        }),
    );
};

export const addMarketplaceFilter = (marketplaceId: number): void => {
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            state.marketplaces.set(marketplaceId, true);
        }),
    );
};

export const removeMarketplaceFilter = (marketplaceId: number): void => {
    useFiltersStore.setState(
        produce((state: FiltersState) => {
            if (state.marketplaces.has(marketplaceId)) state.marketplaces.delete(marketplaceId);
        }),
    );
};

export const addTraitFilter = (trait: TraitFilter, traitValue: TraitValueFilter): void => {
    const traits: FilteredTraitsValues = useFiltersStore.getState().traits;

    if (!traits.get(trait.id)) {
        useFiltersStore.setState(
            produce((state: FiltersState) => {
                state.traits.set(trait.id, {
                    id: trait.id,
                    name: trait.name,
                    rankedNumerically: trait.rankedNumerically,
                    values: new Map([[traitValue.id, traitValue]]),
                });
            }),
        );
    } else if (!traits.get(trait.id).values.get(traitValue.id)) {
        useFiltersStore.setState(
            produce((state: FiltersState) => {
                state.traits.get(trait.id).values.set(traitValue.id, traitValue);
            }),
        );
    }
};

export const removeTraitFilter = (traitId: TraitId, traitValueId: TraitValueId): void => {
    const traits: FilteredTraitsValues = useFiltersStore.getState().traits;
    const traitValueToRemove = traits.get(traitId)?.values.get(traitValueId);
    if (traitValueToRemove) {
        useFiltersStore.setState(
            produce((state: FiltersState) => {
                const trait = state.traits.get(traitId);
                if (trait.values.size > 1) {
                    state.traits.get(traitId).values.delete(traitValueId);
                } else {
                    state.traits.delete(traitId);
                }
            }),
        );
    }
};

export const addVibeFilter = (vibeId: VibeId): void => {
    const vibesMap = useCollectionStore.getState().vibesMap;

    useFiltersStore.setState(
        produce((state: FiltersState) => {
            state.vibes.set(vibeId, vibesMap.get(vibeId).name);
        }),
    );
};

export const removeVibeFilter = (vibeId: VibeId): void => {
    const vibeToRemove = useFiltersStore.getState().vibes.get(vibeId);
    if (vibeToRemove) {
        useFiltersStore.setState(
            produce((state: FiltersState) => {
                state.vibes.delete(vibeId);
            }),
        );
    }
};

export const resetSort = (): void => {
    useFiltersStore.setState(() => ({
        sortBy: initialState.sortBy,
        sortOrder: initialState.sortOrder,
    }));
};

export const hasTokenIdFiltersApplied = (): boolean => {
    const { tokenStringIds } = useFiltersStore.getState();

    return tokenStringIds?.length > 0;
};

export const hasTopFiltersApplied = (): boolean => {
    const { saleStatus, minPrice, maxPrice, minRank, maxRank, minDeviation } =
        useFiltersStore.getState();

    const hasRankFilter = isValidRange(minRank, maxRank);
    const hasPriceFilter = isValidRange(minPrice, maxPrice);
    const hasMinDeviationFilter = isValidNumber(minDeviation);
    const hasSaleStatusFilter = saleStatus === "onSale";

    return hasRankFilter || hasPriceFilter || hasMinDeviationFilter || hasSaleStatusFilter;
};

export const hasMarketplaceFilterApplied = (): boolean => {
    const { marketplaces } = useFiltersStore.getState();

    return marketplaces?.size > 0;
};

export const hasFiltersApplied = (): boolean => {
    const { traits, vibes } = useFiltersStore.getState();

    const hasTraitsFilter = traits?.size > 0;
    const hasVibesFilter = vibes?.size > 0;

    return (
        hasTokenIdFiltersApplied() || hasTopFiltersApplied() || hasTraitsFilter || hasVibesFilter
    );
};

export const getFiltersCount = (): number => {
    const {
        saleStatus,
        minPrice,
        maxPrice,
        minRank,
        maxRank,
        minDeviation,
        tokenStringIds,
        traits,
        vibes,
    } = useFiltersStore.getState();

    let filtersCount = 0;
    if (saleStatus === "onSale") filtersCount++;
    if (isValidRange(minRank, maxRank)) filtersCount++;
    if (isValidRange(minPrice, maxPrice)) filtersCount++;
    if (isValidNumber(minDeviation)) filtersCount++;
    if (tokenStringIds !== null) filtersCount += tokenStringIds.length;
    if (traits !== null)
        filtersCount += Array.from(traits.values()).reduce(
            (previousValue, currentValue) => previousValue + currentValue.values?.size,
            0,
        );
    if (vibes != null) filtersCount += vibes.size;

    return filtersCount;
};

export default useFiltersStore;
