import { Box, FlexProps } from "@chakra-ui/react";
import GradientButton from "@components/ui/GradientButton";
import { SignInModalState, useBasicStore } from "@store/basicStore";

type UserSignUpButtonProps = {
    isMobile?: boolean;
    onMobileModalMenuClose?: Function;
    buttonStyle?: FlexProps;
};

export const UserSignUpButton = ({
    isMobile,
    onMobileModalMenuClose,
    buttonStyle,
}: UserSignUpButtonProps) => {
    const openSignInModal = (state: SignInModalState) => {
        useBasicStore.setState((oldState) => {
            return {
                signInModalState: state,
            };
        });
    };

    const onClickHandler = () => {
        openSignInModal(SignInModalState.SignUp);
        if (onMobileModalMenuClose) onMobileModalMenuClose();
    };

    return (
        <>
            <Box h="100%">
                <GradientButton
                    label="Create Account"
                    onClick={onClickHandler}
                    containerStyle={{ borderRadius: "4px" }}
                    buttonStyle={{
                        fontSize: isMobile ? "16px" : "12px",
                        px: "18px",
                        ...buttonStyle,
                    }}
                />
            </Box>
        </>
    );
};

export default UserSignUpButton;
