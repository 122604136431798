import type { NextPage } from "next";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { ChakraProvider } from "@chakra-ui/react";
import BaseLayout from "@components/layout/BaseLayout";
// import SmartlookController from "@components/auth/SmartlookController";
import useSafariMobilePinchZoom from "@hooks/useSafariMobilePinchZoomHack";
import WindowSiteStateManager from "@components/site/layout/WindowSiteStateManager";
import UserContextProvider from "auth/UserContext";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import theme from "@theme";
import "@styles/globals.css";
import { Router } from "next/router";
import GoogleAnalyticsController from "@components/GoogleAnalyticsController";
import useGoogleAnalytics from "@hooks/useGoogleAnalytics";

const PROJECT_ID = process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID;
const CHAINS = [mainnet];

// Wagmi client
const { publicClient } = configureChains(CHAINS, [w3mProvider({ projectId: PROJECT_ID })]);

const WAGMI_CONFIG = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({
        projectId: PROJECT_ID,
        chains: CHAINS,
        // version: "2",
        // appName: "web3Modal",
    }),
    publicClient,
});

// Web3Modal Ethereum Client
const ETHEREUM_CLIENT = new EthereumClient(WAGMI_CONFIG, CHAINS);

// DO NOT REMOVE THIS BLOCK OF CODE!! -------------------------------------------------
// We need to import the store files here to avoid webpack Circular Dependencies error.
import useCollectionTokensStore from "@store/collectionTokensStore";
import useFiltersStore from "@store/filtersStore";
import Init from "@components/Init";

useCollectionTokensStore.length === useFiltersStore.length;
//-------------------------------------------------------------------------------------

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = Record<string, unknown>> = {
    Component: NextPageWithLayout;
    router: Router;
    __N_SSG?: boolean;
    __N_SSP?: boolean;
    pageProps: P & {
        /** Initial session passed in from `getServerSideProps` or `getInitialProps` */
        session?: Session;
    };
};

function commonLayout(page) {
    return <BaseLayout>{page}</BaseLayout>;
}

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
    const getLayout = Component.getLayout || commonLayout;

    useGoogleAnalytics();
    useSafariMobilePinchZoom();

    return (
        <SessionProvider session={session}>
            <WagmiConfig config={WAGMI_CONFIG}>
                <UserContextProvider>
                    <Init />
                    {/* <SmartlookController
                        projectKey={process.env.NEXT_PUBLIC_SMARTLOOK_PROJECT_KEY}
                    /> */}
                    <WindowSiteStateManager />
                    <ChakraProvider theme={theme}>
                        <GoogleAnalyticsController
                            measurementId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}
                        />
                        {getLayout(<Component {...pageProps} />)}
                    </ChakraProvider>
                </UserContextProvider>
            </WagmiConfig>
            <Web3Modal
                projectId={PROJECT_ID}
                ethereumClient={ETHEREUM_CLIENT}
                enableAccountView={false}
                explorerRecommendedWalletIds={[
                    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
                    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0", // trust
                    "19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927", // ledger
                ]}
                themeMode="light"
                // themeColor="blackWhite"
                // themeBackground="themeColor"
                themeVariables={{
                    "--w3m-font-family": `"Fira Sans Condensed", sans-serif`,
                    "--w3m-background-color": "#3F4964",
                    "--w3m-z-index": "9999",
                    "--w3m-accent-fill-color": "#E93766",
                    "--w3m-accent-color" : "#E93766",
                    "--w3m-logo-image-url": "https://www.beyondrarity.com/images/br-mark-negative.png"
                }}
            />
        </SessionProvider>
    );
}

export default MyApp;
