import { Flex } from "@chakra-ui/react";
import NavArrow from "@components/tutorials/NavArrow";
import React from "react";

type QuickStartNavProps = {
    currentSlide: number;
    totalSlides: number;
    onNextSlide: () => void;
    onPrevSlide: () => void;
};

const QuickStartNav = ({
    currentSlide,
    totalSlides,
    onNextSlide,
    onPrevSlide,
}: QuickStartNavProps): JSX.Element => {
    return (
        <Flex
            w="100%"
            h="100%"
            px={{ base: "6px", sm2: "40px" }}
            flexDir="row"
            pointerEvents="none"
            justifyContent="space-between"
            alignItems="center"
            background={{
                base: "linear-gradient(90deg, #1C212F 0%, rgba(28, 33, 47, 0.5) 3%, rgba(28, 33, 47, 0) 50%, rgba(28, 33, 47, 0.5) 97%, #1C212F 100%)",
                sm2: "linear-gradient(90deg, #1C212F 0%, rgba(28, 33, 47, 0.5) 20%, rgba(28, 33, 47, 0) 50%, rgba(28, 33, 47, 0.5) 80%, #1C212F 100%)",
            }}
        >
            <NavArrow side="left" onClick={onPrevSlide} disabled={currentSlide === 0} />
            <NavArrow side="right" onClick={onNextSlide} completed={currentSlide === totalSlides} />
        </Flex>
    );
};

export default QuickStartNav;
