import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(["menu", "item", "divider"]);

const Menu = helpers.defineMultiStyleConfig({
    baseStyle: {
        item: {
            _hover: { bgColor: "lavenderGray" },
            _active: { bgColor: "lavenderGray" },
            _focus: { bgColor: "lavenderGray" },
        },
        divider: {
            borderColor: "lavenderGray",
        },
    },
});

export default Menu;
