import useCollectionStore from "./collectionStore";
import useCollectionTokensStore from "./collectionTokensStore";

/**
 * Builds a token name if is null
 */
export const buildTokenName = (rawTokenName, collectionName, tokenStringId) => {
    const tokenName = rawTokenName
        ? rawTokenName
        : collectionName
        ? `${collectionName} #${tokenStringId}`
        : `Token ${tokenStringId}`;

    return tokenName;
};

/**
 *  Builds a Token object from an API response flattened token data.
 */
export const buildTokenFromFlatData = (
    tokenStringId: TokenStringId,
    flatToken: FlatTokenData,
    isOwned?: boolean,
): Token => {
    const { collection } = useCollectionStore.getState();

    const rawTokenName = flatToken[0];
    const tokenName = buildTokenName(rawTokenName, collection?.name, tokenStringId);

    if (isOwned) {
        return {
            tokenStringId: tokenStringId,
            tokenId: +tokenStringId,
            rank: flatToken[1],
            name: tokenName,
            currentPrice: flatToken[2],
            deviation: flatToken[2] === null ? null : flatToken[3] - flatToken[2],
            priceProjection: flatToken[3],
            confidence: flatToken[4] * 100, // Raw data is decimal from 0 to 1, converting to percentage
            imageUrl: flatToken[5],
            animationUrl: flatToken[6],
            traits: flatToken[7],
            vibes: flatToken[8],
            marketplace: flatToken[9],
            own: isOwned,
        };
    } else {
        return {
            tokenStringId: tokenStringId,
            tokenId: +tokenStringId,
            rank: flatToken[1],
            name: tokenName,
            currentPrice: flatToken[2],
            deviation: flatToken[2] === null ? null : flatToken[3] - flatToken[2],
            priceProjection: flatToken[3],
            confidence: flatToken[4] * 100, // Raw data is decimal from 0 to 1, converting to percentage
            imageUrl: flatToken[5],
            animationUrl: flatToken[6],
            traits: flatToken[7],
            vibes: flatToken[8],
            marketplace: flatToken[9],
        };
    }
};

/**
 * Builds a Tokens Map (tokenStringId => {tokenData}) from an API response flattened tokens list.
 */
export const buildTokensMapFromFlatData = (
    flatTokensList: FlatToken[],
    userOwnedTokens: TokenStringId[] | null,
): TokensMap => {
    const tokensMap = new Map<TokenStringId, Token>();

    flatTokensList.forEach((token) => {
        const [tokenId, ...tokenData] = token;
        // tokenData.push = true;
        const isOwned = userOwnedTokens.includes(String(tokenId));
        // console.log("isOwned", isOwned);
        // console.log("TOKEN DATA", tokenData);
        tokensMap.set(String(tokenId), buildTokenFromFlatData(String(tokenId), tokenData, isOwned));
    });

    return tokensMap;
};

/**
 * Builds a Tokens Map (tokenStringId => {tokenData}) from an API response flattened tokens list.
 */
export const buildTokensMapFromFlatList = (flatTokensList: FlatTokensList): TokensMap => {
    const tokensMap: TokensMap = new Map<TokenStringId, Token>(
        Object.entries(flatTokensList).map((token) => [
            token[0],
            buildTokenFromFlatData(token[0], token[1]),
        ]),
    );

    return tokensMap;
};

/**
 * Builds a list of tokens sorted by rank from a TokensMap.
 */
export const buildSortedTokensListFromTokensMap = (tokensMap: TokensMap): Token[] => {
    const tokensList: Token[] = Array.from(tokensMap)
        .map(([tokenStringId, token]) => token)
        .sort((a: Token, b: Token) => sortTokens(a, b, "rank", "desc"));

    return tokensList;
};

/**
 * Returns the first and last token IDs (Number) for a collection
 */
export const findStartEndTokenIds = (tokensMap: TokensMap) => {
    const sortedTokens: Token[] = [...tokensMap.values()].sort(function (a, b) {
        return a.tokenId - b.tokenId; // Need to use the "Number" version of the id not the tokenStringId to sort by number and not by text
    });

    if (sortedTokens.length > 0) {
        // return number not strings to do number comparison
        return {
            startTokenId: sortedTokens[0].tokenId,
            endTokenId: sortedTokens[sortedTokens.length - 1].tokenId,
        };
    } else {
        return {
            startTokenId: null,
            endTokenId: null,
        };
    }
};

/**
 * Returns the first and last rank values for a collection
 */
export const findStartEndRanks = (tokensMap: TokensMap) => {
    const sortedTokens: Token[] = [...tokensMap.values()].sort((a: Token, b: Token) =>
        sortTokens(a, b, "rank", "desc"),
    );

    if (sortedTokens.length > 0) {
        return {
            topRank: sortedTokens[0].rank,
            bottomRank: sortedTokens[sortedTokens.length - 1].rank,
        };
    } else {
        return {
            topRank: null,
            bottomRank: null,
        };
    }
};

/**
 * Returns the highest and lowest price NFT prices inside a collection
 */
export const findPricesRange = (tokensMap: TokensMap) => {
    const sortedTokens: Token[] = [...tokensMap.values()]
        .filter(
            (token) =>
                token.currentPrice !== null &&
                token.currentPrice !== undefined &&
                !isNaN(token.currentPrice),
        )
        .sort((a: Token, b: Token) => sortTokens(a, b, "currentPrice", "asc"));

    if (sortedTokens.length > 0) {
        return {
            lowestPrice: sortedTokens[0]?.currentPrice,
            highestPrice: sortedTokens[sortedTokens.length - 1]?.currentPrice,
        };
    } else {
        return {
            lowestPrice: null,
            highestPrice: null,
        };
    }
};

/**
 * Returns the highest and lowest price deviations inside a collection
 */
export const findDeviationsRange = (tokensMap: TokensMap) => {
    const sortedTokens: Token[] = [...tokensMap.values()]
        .filter(
            (token) =>
                token.currentPrice !== null &&
                token.currentPrice !== undefined &&
                !isNaN(token.currentPrice),
        )
        .sort((a: Token, b: Token) => sortTokens(a, b, "priceDeviation", "asc"));

    if (sortedTokens.length > 0) {
        const lowestDeviationToken = sortedTokens[0];
        const highestDeviationToken = sortedTokens[sortedTokens.length - 1];
        return {
            lowestDeviation:
                lowestDeviationToken?.priceProjection - lowestDeviationToken?.currentPrice,
            highestDeviation:
                highestDeviationToken?.priceProjection - highestDeviationToken?.currentPrice,
        };
    } else {
        return {
            lowestDeviation: null,
            highestDeviation: null,
        };
    }
};

/**
 * Returns the highest and lowest price projections inside a collection
 */
export const findProjectionsRange = (tokensMap: TokensMap) => {
    const sortedTokenIds: Token[] = [...tokensMap.values()].sort(
        (a: Token, b: Token) => a.priceProjection - b.priceProjection,
    );

    return {
        lowestProjection: sortedTokenIds[0].priceProjection,
        highestProjection: sortedTokenIds[sortedTokenIds.length - 1].priceProjection,
    };
};

/**
 * Returns an object { min, max} for the highest and lowest 10% prices in a collection.
 */
export const findPriceEdges = (tokens: Token[]) => {
    // First let's remove all the unpriced tokens and sort the priced ones by currentPrice ascending.
    const list = tokens
        .filter(
            (token) =>
                token.currentPrice !== null &&
                token.currentPrice !== undefined &&
                !isNaN(token.currentPrice),
        )
        .sort((a, b) => sortTokens(a, b, "currentPrice", "asc"));

    // once we isolated all the tokens with currentPrice, let's find the 10% of the list length
    // ie: If the filtered list has 347 tokens, the 10% of these tokens is 35.
    const tenPct: number = Math.round(list.length * 0.1);

    // now extract the min and max values from the start and end of the list.
    return {
        lowest10PctPrices: { min: list[0]?.currentPrice, max: list[tenPct]?.currentPrice },
        highest10PctPrices: {
            min: list[list.length - 1 - tenPct]?.currentPrice,
            max: list[list.length - 1]?.currentPrice,
        },
    };
};

/**
 * Given a tokensList returns the same list sorted by the given criteria and order
 */
export const sortTokensList = (
    tokensList: Token[],
    sortCriteria: SortCriteria,
    sortOrder: SortOrder,
): Token[] => {
    const sortedTokensList: Token[] = [...tokensList].sort((a: Token, b: Token) =>
        sortTokens(a, b, sortCriteria, sortOrder),
    );

    return sortedTokensList;
};

/**
 * Given a tokensList returns a map of prev and next tokens
 */
export const getPrevNextTokensMap = (tokensList: Token[]): PrevNextMap => {
    const filteredSortedPrevNextMap = new Map();
    tokensList.forEach((token, index) => {
        const prevNextTokens: PrevNextTokens = {
            prevToken: index - 1 >= 0 ? tokensList[index - 1] : null,
            nextToken: index + 1 < tokensList.length ? tokensList[index + 1] : null,
        };
        filteredSortedPrevNextMap.set(token.tokenStringId, prevNextTokens);
    });

    return filteredSortedPrevNextMap;
};

/**
 * Builds a traits list injecting the traitId on each of its values.
 */
export const buildCollectionTraits = (traits: TraitResponse[]) => {
    const traitsMap = traits.map(({ values, ...traitData }) => ({
        ...traitData,
        values: values.map((value) => ({
            traitId: traitData.id,
            ...value,
        })),
    }));

    return traitsMap;
};

/**
 * Builds a traits Map injecting the traitId on each of its values.
 * NOTE: if "emptyTraitValuesCount" is TRUE, all values will have a count of 0.
 */
export const buildCollectionTraitsMap = (
    traits: TraitResponse[],
    emptyTraitValuesCount?: boolean,
): TraitsMap => {
    const traitsMap: TraitsMap = new Map();

    traits.forEach(({ values, ...traitData }) => {
        const traitValuesMap: TraitValuesMap = new Map();

        values.forEach((value) => {
            traitValuesMap.set(value.id, {
                traitId: traitData.id,
                id: value.id,
                label: value.label,
                amount: !emptyTraitValuesCount ? value.amount : 0,
            });
        });

        traitsMap.set(traitData.id, {
            id: traitData.id,
            name: traitData.name,
            values: traitValuesMap,
            rankedNumerically: traitData.rankedNumerically,
        });
    });

    return traitsMap;
};

/**
 * Given a tokens list builds a map between trait values and tokens. For each trait value we need
 * a list of tokens that have that trait value.
 *
 */
export const buildTokensPerTraitsMap = (tokens: Token[]): TokensPerTraitMap => {
    const map: TokensPerTraitMap = new Map();

    tokens?.forEach((token: Token) => {
        token.traits?.forEach((traitValue) => {
            if (traitValue) {
                const [traitId, valueId] = traitValue;
                if (!map.has(traitId)) map.set(traitId, new Map());

                const traitValues = map.get(traitId);

                if (!traitValues.has(valueId)) {
                    traitValues.set(valueId, [token.tokenStringId]);
                } else {
                    traitValues.get(valueId).push(token.tokenStringId);
                }
            } else {
                // token has a null trait!
            }
        });
    });

    return map;
};

/**
 * Builds a collection vibes map
 */
export const buildCollectionVibesMap = (vibes: VibeResponse[]): VibesMap => {
    if (!vibes || !vibes.length) return new Map();

    const vibesMap: VibesMap = new Map();

    vibes.forEach((vibe) => {
        vibesMap.set(vibe.id, {
            id: vibe.id,
            name: vibe.name,
            count: vibe.count,
            traits: vibe.traits,
        });
    });

    return vibesMap;
};

/**
 * Removes all vibes with 0 tokens from a vibes list
 */
export const removeEmptyVibes = (vibes: Vibe[]): Vibe[] => {
    if (!vibes || !vibes.length) return [];

    const cleanVibesList: Vibe[] = vibes
        .filter((vibe) => vibe.count > 0)
        .sort((a, b) => a.count - b.count);

    return cleanVibesList;
};

export const buildTokensPerVibeMap = (tokens: Token[]): TokensPerVibeMap => {
    const map: TokensPerVibeMap = new Map();

    tokens?.forEach((token: Token) => {
        token.vibes?.forEach(([vibeId, vibeTraits]) => {
            if (!map.has(vibeId)) map.set(vibeId, new Set());
            map.get(vibeId).add(token.tokenStringId);
        });
    });

    return map;
};

export const buildTokensPerMarketplaceMap = (tokens: Token[]): TokensPerMarketplaceMap => {
    const map: TokensPerMarketplaceMap = new Map();

    tokens?.forEach((token: Token) => {
        const { tokenStringId, marketplace } = token;

        if (marketplace) {
            if (map.has(marketplace)) {
                map.get(marketplace).add(tokenStringId);
            } else {
                map.set(marketplace, new Set<TokenStringId>([tokenStringId]));
            }
        }
    });

    return map;
};

/**
 * Returns all the tokenStringIds for a gibven trait value
 */
export const getTokenIdsWithTraitValue = (
    traitId: TraitId,
    traitValueId: TraitValueId,
): TokenStringId[] => {
    const tokensPerTraitMap = useCollectionTokensStore.getState().tokensPerTraitMap;
    return [...tokensPerTraitMap.get(traitId).get(traitValueId)];
};

/**
 * Given a list of token IDs returns a traitMap of all the traits + values present on this subset.
 */
export const getMatchingTraitsMapForTokenIds = (tokenStringIds: TokenStringId[]) => {
    const tokensMap = useCollectionTokensStore.getState().tokensMap;
    const matchingTraitsMap = new Map();

    tokenStringIds.forEach((tokenStringId) => {
        const token = tokensMap.get(tokenStringId);
        token.traits.forEach((tokenTraitValues) => {
            if (tokenTraitValues) {
                const [traitId, traitValueId] = tokenTraitValues;
                if (!matchingTraitsMap.has(traitId)) matchingTraitsMap.set(traitId, new Map());
                const trait = matchingTraitsMap.get(traitId);
                if (!trait.has(traitValueId)) {
                    trait.set(traitValueId, [token]);
                } else {
                    trait.get(traitValueId).push(token);
                }
            }
        });
    });
};

export const getMatchingTraits = (
    filteredTokens: Token[],
): Map<TraitId, Map<TraitValueId, number>> => {
    const matchingTraits: Map<TraitId, Map<TraitValueId, number>> = new Map();

    filteredTokens.forEach(({ traits }) => {
        traits.forEach((tokenTraitValues) => {
            if (tokenTraitValues) {
                const [traitId, traitValueId] = tokenTraitValues;

                if (!matchingTraits.has(traitId)) {
                    matchingTraits.set(traitId, new Map());
                    matchingTraits.get(traitId).set(traitValueId, 1);
                } else {
                    const currentTrait = matchingTraits.get(traitId);
                    if (!currentTrait.has(traitValueId)) {
                        currentTrait.set(traitValueId, 1);
                    } else {
                        currentTrait.set(traitValueId, currentTrait.get(traitValueId) + 1);
                    }
                }
            }
        });
    });

    return matchingTraits;
};

export const isBetween = (value, min, max) => {
    if (typeof min !== "number" && typeof max !== "number") return true;
    if (typeof min !== "number") return value <= max;
    if (typeof max !== "number") return value >= min;
    return value >= min && value <= max;
};

export const sortTokens = (
    tokenA: Token,
    tokenB: Token,
    sortBy: SortCriteria,
    sortOrder: SortOrder,
): number => {
    if (sortBy === "rank") return compareTokensRank(tokenA, tokenB, sortOrder);
    if (sortBy === "currentPrice") return compareTokensPrice(tokenA, tokenB, sortOrder);
    if (sortBy === "priceDeviation") return compareTokensMinDeviation(tokenA, tokenB, sortOrder);
};

/**
 * Tokens could have a null price.
 * All tokens with a null price should be placed at the end of the list,
 * after the ones with current price no matter the sort order defined by the user.
 * Also, this non-priced tokens should always be sorted by rank ascending.
 * To achieve this we always must have a price for the tokens, especially when
 * sorting by price ascending, because all the null priced will get to the top
 * of the list. That's why we use a default value for the sorting:
 *     (aPrice || 10e10)...
 * In case tokenA has no price it will be replaced by 10^10, a large enough value
 * to place it at the end of the sorted list.
 */
export const compareTokensPrice = (tokenA: Token, tokenB: Token, sortOrder: SortOrder): number => {
    const { currentPrice: aPrice, rank: aRank } = tokenA;
    const { currentPrice: bPrice, rank: bRank } = tokenB;

    let result =
        sortOrder === "asc" ? (aPrice || 10e10) - (bPrice || 10e10) : (bPrice || 0) - (aPrice || 0);

    if (result == null || result == 0) {
        result = tokenA.rank - tokenB.rank;
    }

    if (result == null || result == 0) {
        result = tokenA.tokenId - tokenB.tokenId;
    }

    return result;
};

export const compareTokensRank = (tokenA: Token, tokenB: Token, sortOrder: SortOrder): number => {
    let result = sortOrder === "asc" ? tokenB.rank - tokenA.rank : tokenA.rank - tokenB.rank;

    if (result == null || result == 0) {
        result = tokenA.tokenId - tokenB.tokenId;
    }

    return result;
};

export const compareTokensMinDeviation = (
    tokenA: Token,
    tokenB: Token,
    sortOrder: SortOrder,
): number => {
    const { currentPrice: aPrice, priceProjection: aPriceProjection, rank: aRank } = tokenA;
    const { currentPrice: bPrice, priceProjection: bPriceProjection, rank: bRank } = tokenB;

    // Not listed tokens (no currentPrice available) should be always at the end of the list
    // no matter if the order is ascending or descending.
    if (aPrice === null) return bPrice === null ? 0 || aRank - bRank : 1 || aRank - bRank;
    if (bPrice === null) return aPrice === null ? 0 || aRank - bRank : -1 || aRank - bRank;

    // otherwise calculate sort order based on tokens price & price projection
    const minDeviationA: number = aPriceProjection - aPrice;
    const minDeviationB: number = bPriceProjection - bPrice;

    let result =
        sortOrder === "asc" ? minDeviationA - minDeviationB : minDeviationB - minDeviationA;

    if (result == null || result == 0) {
        result = tokenA.rank - tokenB.rank;
    }

    if (result == null || result == 0) {
        result = tokenA.tokenId - tokenB.tokenId;
    }

    return result;
};

export const timeSince = (time: number): string => {
    return (performance.now() - time).toFixed(2) + " ms.";
};

let userTokensAbortController = new AbortController();

export const getUserCollectionTokensPage = async (collectionId: string, cursor?: string) => {
    userTokensAbortController.abort();
    userTokensAbortController = new AbortController();

    const queryURL = cursor
        ? `/api/${collectionId}/tokens/me?cursor=${cursor}`
        : `/api/${collectionId}/tokens/me`;

    const response = await fetch(queryURL, {
        signal: userTokensAbortController.signal,
    });

    if (response.ok) {
        const { tokens, next } = await response.json();
        return { tokens, next };
    } else {
        return { tokens: [], next: null };
    }
};
