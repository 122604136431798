import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

type WindowSiteState = {
    isMounted: boolean;
    windowHeight: number;
    windowWidth: number;
    isDesktop: boolean;
    isNarrowPortrait: boolean;
    isPortrait: boolean;
};

const defaultState: WindowSiteState = {
    isMounted: false,
    windowHeight: 0,
    windowWidth: 0,
    isDesktop: true,
    isNarrowPortrait: false,
    isPortrait: false,
};

export const useWindowSiteStore = create<WindowSiteState>()(
    subscribeWithSelector((set) => defaultState),
);
