const textStyles = {
    h2: {
        fontSize: { base: "1.25rem", lg: "1.5rem" },
        color: "gold.500",
        fontWeight: 700,
    },
    h3: {
        fontSize: { base: "15px", lg: "18px" },
        fontWeight: 800,
    },
    label: {
        fontSize: "10px",
        fontWeight: 300,
        color: "rythm",
        textAlign: "center",
    },
    traitOption: {
        fontSize: "12px",
        fontWeight: 400,
        color: "gray.faint",
    },
    onboarding: {
        headText: {
            fontSize: "36px",
            fontWeight: 400,
        },
        linkText: {
            color: "#fff",
            fontSize: "24px",
            textDecoration: "underline",
            fontWeight: 100,
            cursor: "pointer",
        },
        headImageText: {
            fontSize: "36px",
            fontWeight: 100,
        },
        text: {
            fontSize: "24px",
            fontWeight: 100,
        },
        imageText: {
            fontSize: "24px",
            fontWeight: 100,
            color: "lavenderGray",
        },

        buttonText: {
            fontSize: "20px",
            fontWeight: 100,
        },
    },
    collectionHeader: {
        name: {
            fontFamily: "regular",
            fontSize: { base: "1.25rem", lg: "1.5rem" },
            color: "gold.500",
            fontWeight: 700,
            letterSpacing: ".09rem",
            maxW: "100%",
            whiteSpace: "nowrap",
            minH: "20px",
            display: "block",
        },
    },
    itemsAmount: {
        fontSize: "13px",
    },
    filters: {
        traits: {
            value: {
                label: {
                    color: "gray.faint",
                    fontSize: "14px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mr: "5px",
                },
                amount: {
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "gray.faint",
                },
            },
        },
    },
    chipLabel: {
        fontSize: "11px",
        lineHeight: "105%",
        color: "gray.faint",
        mr: "4px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    chip: {
        fontSize: "11px",
        lineHeight: "105%",
        color: "white",
        mr: "8px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },

    // Token List Item -------------------------------------------------------
    tokenListItem: {
        label: {
            fontSize: "12px",
            color: "rythm",
            fontWeight: 300,
            textTransform: "uppercase",
            lineHeight: "100%",
        },
        id: {
            fontFamily: "regular",
            fontSize: "16px",
            lineHeight: "100%",
        },
        rank: {
            fontFamily: "regular",
            fontSize: "16px",
            // fontWeight: 600,
            lineHeight: "100%",
            color: "gold.500",
        },
        name: {
            fontFamily: "regular",
            fontSize: "18px",
            fontWeight: 300,
            lineHeight: "100%",
        },
        confidence: {
            fontFamily: "regular",
            fontSize: "13px",
            fontWeight: 300,
            color: "whiteAlpha.600",
            textTransform: "uppercase",
            lineHeight: "100%",
        },
        price: {
            fontFamily: "regular",
            fontSize: "18px",
            lineHeight: "100%",
            color: "white"
        },
        deviation: {
            fontFamily: "regular",
            fontSize: "18px",
            lineHeight: "100%",
        },
        projection: {
            fontFamily: "regular",
            fontSize: "18px",
            lineHeight: "100%",
            color: "sorbet",
        },
        deviationInfo: {
            header: {
                fontWeight: 400,
                color: "white",
                pl: "6px",
                fontSize: "18px",
            },
            subheader: {
                fontWeight: 400,
                borderRadius: "6px",
                bgColor: "sorbet",
                p: "0px 6px",
                fontSize: "13px",
            },
            body: {
                pl: "6px",
                color: "whiteAlpha.800",
                fontSize: "13px",
                fontWeight: 300,
                lineHeight: "110%",
                flex: 1,
                alignItems: "center",
            },
        },
        briteInfo: {
            header: {
                fontWeight: 400,
                color: "white",
                pl: "6px",
                fontSize: "18px",
            },
            subheader: {
                fontWeight: 400,
                borderRadius: "6px",
                bgColor: "sorbet",
                p: "0px 6px",
                fontSize: "13px",
            },
            body: {
                color: "whiteAlpha.800",
                fontSize: "11.8px",
                fontWeight: 300,
                lineHeight: "130%",
            },
        },
    },

    // Mobile Token List Item -------------------------------------------------------
    mobileTokenItem: {
        label: {
            fontSize: "10.5px",
            color: "whiteAlpha.500",
            textTransform: "uppercase",
            lineHeight: "100%",
        },
        id: {
            fontSize: "14px",
            lineHeight: "100%",
        },
        rank: {
            fontSize: "14px",
            fontWeight: 800,
            lineHeight: "100%",
            color: "gold.500",
        },
        confidence: {
            fontSize: "10px",
            fontWeight: 300,
            color: "whiteAlpha.600",
            textTransform: "uppercase",
            lineHeight: "100%",
        },
        price: {
            fontSize: "13px",
            lineHeight: "100%",
            textAlign: "right",
        },
        deviation: {
            fontSize: "13px",
            lineHeight: "100%",
            textAlign: "right",
        },
        projection: {
            fontSize: "13px",
            lineHeight: "100%",
            textAlign: "right",
            fontWeight: 800,
            color: "sorbet",
        },
        deviationInfo: {
            header: {
                fontWeight: 400,
                color: "white",
                fontSize: "12px",
            },
            subheader: {
                fontWeight: 400,
                borderRadius: "6px",
                bgColor: "sorbet",
                py: "2px",
                fontSize: "10px",
            },
            body: {
                pt: "8px",
                color: "whiteAlpha.800",
                fontSize: "10px",
                fontWeight: 300,
            },
        },
        briteInfo: {
            header: {
                fontWeight: 400,
                color: "white",
                fontSize: "12px",
            },
            subheader: {
                fontWeight: 400,
                borderRadius: "6px",
                bgColor: "sorbet",
                py: "2px",
                fontSize: "10px",
            },
            body: {
                pt: "8px",
                color: "whiteAlpha.800",
                fontSize: "10px",
                fontWeight: 300,
            },
        },
    },

    // Token Modal -------------------------------------------------------
    tokenModalLandscape: {
        name: {
            fontFamily: "regular",
            fontSize: "24px",
            // lineHeight: "100%",
        },
        idLabel: {
            fontSize: "20px",
            // lineHeight: "100%",
            fontWeight: "light",
            color: "rythm",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
        },
        id: {
            fontFamily: "regular",
            fontSize: "20px",
            // lineHeight: "100%",
        },
        rankLabel: {
            fontSize: "20px",
            // lineHeight: "100%",
            fontWeight: "light",
            color: "rythm",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
        },
        rank: {
            fontFamily: "regular",
            fontSize: "20px",
            // lineHeight: "100%",
        },
        prices: {
            label: {
                fontSize: "12px",
                color: "whiteAlpha.500",
                textTransform: "uppercase",
                // lineHeight: "100%",
            },
            tag: {
                fontSize: "14px",
                fontWeight: 300,
                color: "white",
                textTransform: "uppercase",
                lineHeight: "100%",
            },
            price: {
                fontFamily: "regular",
                fontSize: "22px",
                // fontWeight: 500,
                // lineHeight: "100%",
            },
            deviation: {
                fontFamily: "regular",
                fontSize: "22px",
                // fontWeight: 500,
                // lineHeight: "100%",
            },
            projection: {
                fontFamily: "regular",
                fontSize: "22px",
                // fontWeight: 800,
                // lineHeight: "100%",
                color: "sorbet",
            },
        },
        tooltip: {
            header: {
                fontWeight: 400,
                color: "white",
                fontSize: "20px",
            },
            subheader: {
                fontWeight: 400,
                borderRadius: "6px",
                bgColor: "sorbet",
                py: "2px",
                fontSize: "18px",
            },
            body: {
                pt: "8px",
                color: "white",
                fontSize: "16x",
                fontWeight: 300,
            },
        },
    },

    tokenModalPortrait: {
        name: {
            fontFamily: "regular",
            fontSize: "20px",
            lineHeight: "100%",
        },
        idLabel: {
            fontSize: "20px",
            // lineHeight: "100%",
            fontWeight: "light",
            color: "rythm",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
        },
        id: {
            fontFamily: "regular",
            fontSize: "20px",
            // lineHeight: "100%",
        },
        rankLabel: {
            fontSize: "20px",
            // lineHeight: "100%",
            fontWeight: "light",
            color: "rythm",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
        },
        rank: {
            fontFamily: "regular",
            fontSize: "20px",
            // lineHeight: "100%",
        },
        prices: {
            label: {
                fontSize: "10px",
                color: "whiteAlpha.500",
                textTransform: "uppercase",
                lineHeight: "100%",
            },
            tag: {
                fontSize: "11px",
                fontWeight: 300,
                color: "white",
                textTransform: "uppercase",
                lineHeight: "100%",
            },
            price: {
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "100%",
            },
            deviation: {
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "100%",
            },
            projection: {
                fontSize: "16px",
                fontWeight: 800,
                lineHeight: "100%",
                color: "sorbet",
            },
        },
        tooltip: {
            header: {
                fontWeight: 400,
                color: "white",
                fontSize: "16px",
            },
            subheader: {
                fontWeight: 400,
                borderRadius: "6px",
                bgColor: "sorbet",
                py: "2px",
                fontSize: "12px",
            },
            body: {
                pt: "2px",
                color: "white",
                fontSize: "11px",
                fontWeight: 300,
            },
        },
    },

    // site pages -------------------------------------------------------

    site: {
        bigHead: {
            fontFamily: "regular",
            fontWeight: 700,
            fontSize: ["28px", "28px", "36px", "36px", "44px", "58px"],
        },
        headXL: {
            fontFamily: "regular",
            fontSize: { base: "30px", lg: "42px" },
            fontWeight: 400,
            color: "white",
        },
        head: {
            fontFamily: "regular",
            fontSize: { base: "24px", md: "36px", lg: "40px" },
            fontWeight: 400,
            color: "white",
        },
        header1: {
            fontSize: { base: "20px", md: "30px" },
            fontWeight: 400,
            color: "white",
        },
        header: {
            fontFamily: "regular",
            fontSize: { base: "20px", md: "30px" },
            fontWeight: 400,
            color: "white",
        },
        siteText: {
            fontFamily: "regular",
            fontSize: { base: "16px", md: "20px" },
            fontWeight: 400,
            color: "white",
        },
        text: {
            color: "white",
            fontSize: { base: "16px", md: "20px" },
            fontWeight: 300,
        },
        link: {
            fontFamily: "regular",
            fontSize: { base: "16px", md: "20px" },
            fontWeight: 600,
            color: "sorbet",
        },
        linkStyle: {
            fontWeight: 600,
            color: "sorbet",
        },
        footerLink: {
            color: "white",
            fontSize: { base: "16px", md: "20px" },
            fontWeight: 300,
            cursor: "pointer",
            _hover: {
                color: "lavenderGray",
            },
        },
        tieredBoxLabel: {
            color: "white",
            fontSize: { base: "14px", md: "20px" },
            fontWeight: 300,
        },
        p: {
            color: "white",
            fontSize: "16px",
            fontWeight: 300,
        },
        rightElementText: {
            fontFamily: "regular",
            color: "white",
            fontSize: "18px",
            fontWeight: 300,
        },
        cardFooterText: {
            fontFamily: "regular",
            color: "white",
            fontSize: { base: "11px", lg: "14px" },
            fontWeight: 300,
        },
        buttonText: {
            fontFamily: "regular",
            fontSize: "20px",
            // fontWeight: 300,
        },
        gray: {
            header: {
                color: "lavenderGray",
                fontSize: { base: "14px", md: "20px", lg: "30px" },
                fontWeight: 300,
            },
            siteText: {
                fontFamily: "regular",
                color: "lavenderGray",
                fontSize: "20px",
                fontWeight: 300,
            },
            text: {
                color: "lavenderGray",
                fontSize: { base: "14px", md: "20px" },
                fontWeight: 300,
            },
            iconText: {
                color: "lavenderGray",
                fontSize: "14px",
                fontWeight: 300,
            },
        },
        forms: {
            label: {
                fontFamily: "regular",
                color: "white",
                fontSize: "20px", //{ base: "20px", md: "24px" },
                fontWeight: 500,
            },
            value: {
                fontFamily: "regular",
                color: "white",
                fontSize: { base: "14px", md: "18px" },
                fontWeight: 300,
            },
            smallValue: {
                fontFamily: "regular",
                color: "white",
                fontSize: { base: "12px", md: "14px" },
                fontWeight: 300,
            },
            placeholder: {
                opacity: 1,
                fontFamily: "body",
                fontWeight: 300,
                // fontStyle: "italic",
                color: "lavenderGray",
            },
            button: {
                fontFamily: "regular",
                // fontSize: { base: "14px", lg: "18px" },
                fontSize: { base: "18px", md: "22px" },
                // fontWeight: 300,
                px: { base: "30px", lg: "50px" },
            },
            statusMessage: {
                textAlign: "center",
                fontWeight: 300,
                fontSize: { base: "12px", lg: "14px" },
                // fontStyle: "italic",
                color: "lavenderGray",
            },
        },
        article: {
            title: {
                fontFamily: "regular",
                color: "white",
                fontSize: {
                    base: "20px",
                    sm2: "20px", //"clamp(18px, calc(1.2vw + 1.2vh) , 24px)",
                    md: "24px", //"clamp(16px, calc(1.15vw + 1.15vh) , 24px)",
                },
            },
            body: {
                color: "lavenderGray",
                fontWeight: 300,
                fontSize: { base: "14px", sm2: "14px", md: "16px" },
            },
        },
    },

    invalidTokenModal: {
        title: {
            fontWeight: 500,
            fontSize: { base: "64px", md: "78px", lg: "96px" },
            lineHeight: "100%",
        },
        copy: {
            fontSize: { base: "26px", md: "32px", lg: "40px" },
            fontStyle: "italic",
            textAlign: "center",
            lineHeight: "120%",
        },
    },

    // Onboard -------------------------------------------------------

    onboard: {
        desktop: {
            title: {
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
            },
            body: {
                color: "white",
                fontSize: "16px",
                fontStyle: "italic",
                fontWeight: "light",
            },
            footer: {
                color: "white",
                fontSize: "15px",
                fontWeight: "bold",
            },
        },
        mobile: {
            title: {
                color: "white",
                fontSize: "18px",
                fontWeight: "bold",
                lineHeight: "100%",
            },
            body: {
                color: "white",
                fontSize: "13px",
                fontStyle: "italic",
                fontWeight: "light",
                textAlign: "center",
            },
            footer: {
                color: "white",
                fontSize: "11",
                fontWeight: "bold",
            },
        },
    },
};

export default textStyles;
