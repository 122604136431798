import { useSessionStore } from "@store/sessionStore";
import { Flex, Icon } from "@chakra-ui/react";
import { MdOutlineNotifications } from "react-icons/md";
import Tooltip from "@components/ui/Tooltip";
import { useWindowSiteStore } from "@store/windowSiteStore";

export const BannerButton = () => {
    const displayBanner = useSessionStore((state) => state.displayBanner);
    const mobileMenueIsOpen = useSessionStore((state) => state.mobileMenuIsOpen);
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);

    const handleOnClick = () => {
        useSessionStore.setState(() => ({ displayBanner: true }));
    };

    if (displayBanner || mobileMenueIsOpen) return null;

    return (
        <>
            <Tooltip label="Open Banner Message" isDisabled={!isDesktop}>
                <Flex
                    h="100%"
                    // mt="-3px"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="150%"
                    lineHeight="100%"
                    color="white"
                    filter={`drop-shadow(0px 0px 2px #0009)`}
                    _hover={{ cursor: "pointer", filter: `drop-shadow(0px 0px 4px #fff5)` }}
                    onClick={handleOnClick}
                >
                    <Icon
                        as={MdOutlineNotifications}
                        color="lavenderGray"
                        _hover={{ color: "gold.500" }}
                    />
                </Flex>
            </Tooltip>
        </>
    );
};

export default BannerButton;
