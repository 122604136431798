import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Button: ComponentStyleConfig = {
    baseStyle: {
        fontWeight: 500,
        color: "white",
        borderRadius: "base",
        boxSizing: "border-box",
        minW: "70px",
        _focus: {
            ring: 0,
        },
    },

    sizes: {
        xs: {
            fontSize: "12px",
            px: 4,
            height: "12px",
            maxH: "12px",
        },
        sm: {
            fontSize: "12px",
            px: 4,
            height: "20px",
            maxH: "20px",
        },
        md: {
            fontSize: "12px",
            px: 8,
            height: "30px",
            maxH: "30px",
        },
    },
    variants: {
        outline: {
            border: "1px solid",
            borderColor: "#979797",
            color: "white",
            _hover: {
                background: "steelblue.500",
            },
            _active: {
                background: "steelblue.300",
            },
        },
        ghost: {
            fontFamily: "regular",
            color: "white",
            fontWeight: 300,
            fontSize: "20px"
        },
        solid: {
            color: "white",
        },
        sorbet: {
            bg: '#401A3E',
            border: '1px solid #E0058B',
            _hover: { background: '#E0058B' },
            borderRadius: "25px"
        },
        white: {
            fontFamily: "regular",
            bg: "white",
            borderRadius: "25px",
            textColor: 'arsenic',
            fontSize: { base: '18px', md: '20px' },
            _hover: { background: 'lavenderGray' },
        },
        gray: {
            fontFamily: "regular",
            bg: "gray.200",
            borderRadius: "25px",
            textColor: 'arsenic',
            fontSize: { base: '18px', md: '20px' },
            _hover: { background: 'lavenderGray' },
        },
        gradientWhiteSorbet: {
            fontFamily: "regular",
            color: "white",
            fontWeight: 300,
            fontSize: { base: '18px', md: '24px' },
            bgGradient: 'linear(to-r, #F77A59 0%, #EA3A65 100%)',
            rounded: '30',
            _hover: {
                bg: 'white',
                color: '#E93766'
            },
        }
    },

    defaultProps: {
        size: "sm",
        variant: "solid",
    },
};

export default Button;
