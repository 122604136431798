import { usePersistedStore, setLastVisited } from "@store/persistedStore";
import { useEffect } from "react";
import shallow from "zustand/shallow";

type InitProps = {};

export const Init = ({}: InitProps) => {
    const persistedLastVisited = usePersistedStore((state) => state.lastVisited, shallow);

    useEffect(() => {
        // console.debug("initializing");
        const now = Date.now();
        if (persistedLastVisited < now) setLastVisited(now);
    }, []);

    return null;
};

export default Init;
