import { Box, BoxProps, Flex, FlexProps, Link } from "@chakra-ui/react";
import NextLink from "next/link";
import { ExternalIcon } from "@components/icons";
import { ReactNode } from "react";

type GradientButtonProps = {
    label: string | ReactNode;
    tag?: string;
    link?: string;
    onClick?: Function;
    disabled?: boolean;
    buttonStyle?: FlexProps;
    isExternal?: boolean;
    gradientBoxStyle?: BoxProps;
    invertButtonColor?: boolean;
    containerStyle?: FlexProps;
    children?: React.ReactNode;
};

export const GradientButton = ({
    label,
    tag,
    link,
    onClick,
    disabled = false,
    buttonStyle,
    isExternal = false,
    gradientBoxStyle,
    invertButtonColor = false,
    containerStyle,
    children,
}: GradientButtonProps) => {
    const handleOnclick = (e) => {
        if (onClick && !disabled) onClick(e);
    };

    const MainContent = () => {
        return (
            <>
                <Flex
                    pos="relative"
                    // minW="100%"
                    // minH="100%"
                    overflow="hidden"
                    borderRadius="50em"
                    justifyContent={!children ? "center" : "flex-start"}
                    alignItems="center"
                    {...containerStyle}
                >
                    <Box
                        pos="absolute"
                        w="100%"
                        h="100%"
                        bgGradient={
                            invertButtonColor ? undefined : "linear(to-r, #F77A59 0%, #EA3A65 100%)"
                        }
                        bgColor={invertButtonColor ? "white" : undefined}
                        zIndex={0}
                        {...gradientBoxStyle}
                    />
                    <Flex
                        role="group"
                        w="100%"
                        h="100%"
                        px={{ base: "30px", md: "50px" }}
                        py={1}
                        fontFamily="regular"
                        fontSize={{ base: "18px", md: "22px" }}
                        justifyContent="center"
                        alignItems="center"
                        color={disabled ? "#999" : invertButtonColor ? "independence" : "#fff"}
                        bgColor={disabled ? "#3339" : undefined}
                        // borderRadius="50em"
                        _hover={
                            disabled
                                ? undefined
                                : {
                                      color: invertButtonColor ? "white" : "sorbet",
                                      bgColor: invertButtonColor ? undefined : "#fff",
                                      bgGradient: invertButtonColor
                                          ? "linear(to-r, #F77A59 0%, #EA3A65 100%)"
                                          : undefined,
                                  }
                        }
                        onClick={handleOnclick}
                        transition="all .2s"
                        cursor={disabled ? "not-allowed" : "pointer"}
                        zIndex={1}
                        {...buttonStyle}
                    >
                        {children ? (
                            children
                        ) : (
                            <Box whiteSpace="nowrap">
                                <Flex justify="center" align="center" gap={2}>
                                    {label}
                                    {tag && (
                                        <Flex
                                            fontSize="70%"
                                            mb={-0.5}
                                            fontWeight="medium"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {tag}
                                        </Flex>
                                    )}
                                </Flex>
                            </Box>
                        )}
                        {isExternal ? <ExternalIcon ml="4px" fontSize="60%" /> : null}
                    </Flex>
                </Flex>
            </>
        );
    };

    const LinkedContent = () => {
        return (
            <>
                <NextLink shallow href={link} passHref>
                    <Link _hover={{ textDecor: "none" }}>
                        <MainContent />
                    </Link>
                </NextLink>
            </>
        );
    };

    return <>{link ? <LinkedContent /> : <MainContent />}</>;
};

export default GradientButton;
