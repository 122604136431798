import { Box, Flex, IconButton } from "@chakra-ui/react";
import { MdClose, MdOutlineNotifications } from "react-icons/md";
import { useDisclosure, useBreakpointValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSessionStore } from "@store/sessionStore";
import { useWindowSiteStore } from "@store/windowSiteStore";
import BannerButton from "./BannerButton";

export const Banner = ({ useReopen = true, ...props }) => {
    const displayBanner = useSessionStore((state) => state.displayBanner);
    const displayBannerCount = useSessionStore((state) => state.displayBannerCount);
    const mobileMenueIsOpen = useSessionStore((state) => state.mobileMenuIsOpen);
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);

    const { isOpen, onOpen, onClose } = useDisclosure();

    // console.debug('[Banner] Banner.displayBanner: ', displayBanner);
    // console.debug('[Banner] Banner.displayBannerCount: ', displayBannerCount);

    const [internalDisplayBanner, setInternalDisplayBanner] = useState(displayBanner);

    useEffect(() => {
        setInternalDisplayBanner(displayBanner);
    }, [displayBanner]);

    const JoinBanner = (props) => {
        return (
            <>
                <Flex
                    // onClick={onOpen}
                    // cursor="pointer"
                    w="100%"
                    position="relative"
                    px={isDesktop ? "60px" : "10%"}
                    py="10px"
                    alignContent="center"
                    justifyContent="center"
                    textAlign="center"
                    onAnimationEnd={() => {
                        useSessionStore.setState((oldState) => {
                            return { displayBannerCount: displayBannerCount + 1 };
                        });
                    }}
                    // bgGradient='linear(130deg, rgb(255, 255, 255, 0) 0, rgb(255, 255, 255, 0.7) 20%, rgb(255, 255, 255, .9) 50%, rgb(255, 255, 255, .7) 80%, rgb(255, 255, 255, 0) 100%)'
                    animation={displayBanner && displayBannerCount == 0 ? "a 2.5s ease-in 1" : ""}
                    sx={{
                        "@property --l": {
                            syntax: "'<percentage>'",
                            inherits: false,
                            initialValue: "0%",
                        },

                        background: "linear-gradient(to right, #E1058C 0, #FA9B1E 100%)",
                        "--l": "60%",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 100%",
                        backgroundPosition: "center",

                        "@keyframes a": {
                            "0%": { "--l": "60%" },
                            "1%": { "--l": "61%" },
                            "2%": { "--l": "62%" },
                            "3%": { "--l": "63%" },
                            "4%": { "--l": "64%" },
                            "5%": { "--l": "65%" },
                            "6%": { "--l": "66%" },
                            "7%": { "--l": "67%" },
                            "8%": { "--l": "68%" },
                            "9%": { "--l": "69%" },
                            "10%": { "--l": "70%" },
                            "11%": { "--l": "71%" },
                            "12%": { "--l": "72%" },
                            "13%": { "--l": "73%" },
                            "14%": { "--l": "74%" },
                            "15%": { "--l": "75%" },
                            "16%": { "--l": "76%" },
                            "17%": { "--l": "77%" },
                            "18%": { "--l": "78%" },
                            "19%": { "--l": "79%" },
                            "20%": { "--l": "80%" },
                            "21%": { "--l": "81%" },
                            "22%": { "--l": "82%" },
                            "23%": { "--l": "83%" },
                            "24%": { "--l": "84%" },
                            "25%": { "--l": "85%" },
                            "26%": { "--l": "86%" },
                            "27%": { "--l": "87%" },
                            "28%": { "--l": "88%" },
                            "29%": { "--l": "89%" },
                            "30%": { "--l": "90%" },
                            "31%": { "--l": "91%" },
                            "32%": { "--l": "92%" },
                            "33%": { "--l": "93%" },
                            "34%": { "--l": "94%" },
                            "35%": { "--l": "95%" },
                            "36%": { "--l": "96%" },
                            "37%": { "--l": "97%" },
                            "38%": { "--l": "98%" },
                            "39%": { "--l": "99%" },
                            "40%": { "--l": "100%" },
                            "41%": { "--l": "0%" },
                            "42%": { "--l": "1%" },
                            "43%": { "--l": "2%" },
                            "44%": { "--l": "3%" },
                            "45%": { "--l": "4%" },
                            "46%": { "--l": "5%" },
                            "47%": { "--l": "6%" },
                            "48%": { "--l": "7%" },
                            "49%": { "--l": "8%" },
                            "50%": { "--l": "9%" },
                            "51%": { "--l": "10%" },
                            "52%": { "--l": "11%" },
                            "53%": { "--l": "12%" },
                            "54%": { "--l": "13%" },
                            "55%": { "--l": "14%" },
                            "56%": { "--l": "15%" },
                            "57%": { "--l": "16%" },
                            "58%": { "--l": "17%" },
                            "59%": { "--l": "18%" },
                            "60%": { "--l": "19%" },
                            "61%": { "--l": "20%" },
                            "62%": { "--l": "21%" },
                            "63%": { "--l": "22%" },
                            "64%": { "--l": "23%" },
                            "65%": { "--l": "24%" },
                            "66%": { "--l": "25%" },
                            "67%": { "--l": "26%" },
                            "68%": { "--l": "27%" },
                            "69%": { "--l": "28%" },
                            "70%": { "--l": "29%" },
                            "71%": { "--l": "30%" },
                            "72%": { "--l": "31%" },
                            "73%": { "--l": "32%" },
                            "74%": { "--l": "33%" },
                            "75%": { "--l": "34%" },
                            "76%": { "--l": "35%" },
                            "77%": { "--l": "36%" },
                            "78%": { "--l": "37%" },
                            "79%": { "--l": "38%" },
                            "80%": { "--l": "39%" },
                            "81%": { "--l": "40%" },
                            "82%": { "--l": "41%" },
                            "83%": { "--l": "42%" },
                            "84%": { "--l": "43%" },
                            "85%": { "--l": "44%" },
                            "86%": { "--l": "45%" },
                            "87%": { "--l": "46%" },
                            "88%": { "--l": "47%" },
                            "89%": { "--l": "48%" },
                            "90%": { "--l": "49%" },
                            "91%": { "--l": "50%" },
                            "92%": { "--l": "51%" },
                            "93%": { "--l": "52%" },
                            "94%": { "--l": "53%" },
                            "95%": { "--l": "54%" },
                            "96%": { "--l": "55%" },
                            "97%": { "--l": "56%" },
                            "98%": { "--l": "57%" },
                            "99%": { "--l": "58%" },
                            "100%": { "--l": "59%" },
                        },
                    }}
                    // borderBottom="1px solid"
                    // borderColor="app.gold"
                    {...props}
                >
                    <Box flex={1}>{props.children}</Box>
                    <Box position="absolute" right={isDesktop ? "15px" : "10px"} top={isDesktop?"8px": "6px"}>
                        <IconButton
                            icon={<MdClose fontSize="20px" />}
                            isRound={true}
                            variant="ghost"
                            colorScheme="whiteAlpha"
                            color="white"
                            onClick={(e) => {
                                e.stopPropagation();
                                useSessionStore.setState(() => ({ displayBanner: false }));
                            }}
                            aria-label="close banner"
                        />
                    </Box>
                </Flex>
            </>
        );
    };

    return (
        <>
            {internalDisplayBanner && !mobileMenueIsOpen ? (
                <Flex w="100%">
                    <JoinBanner {...props}>{props.children}</JoinBanner>
                </Flex>
            ) : useReopen ? (
                <Flex
                    position="fixed"
                    right="20px"
                    top="10px"
                    zIndex="tooltip"
                    justifyContent="end"
                >
                    <BannerButton />
                </Flex>
            ) : null}
        </>
    );
};

export default Banner;
