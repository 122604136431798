import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { Box, Center, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import SwiperClass, { Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import QuickStartNav from "./QuickStartNav";
import { isIOS } from "react-device-detect";
import ReactPlayer from "react-player";
import "swiper/css";

const MotionFlex = motion(Flex);

const BOTTOM_SLIDER_HEIGHT = "150px";

const LANDSCAPE_SLIDES = [
    { type: "image", src: "/images/onboard/landscape/beyond-rarity-onboard-1.jpg" },
    { type: "video", src: "/images/onboard/landscape/core_components.mp4" },
    { type: "video", src: "/images/onboard/landscape/under_over.mp4" },
    { type: "video", src: "/images/onboard/landscape/key_stats.mp4" },
];

const PORTRAIT_SLIDES = [
    { type: "image", src: "/images/onboard/portrait/beyond-rarity-onboard-1.jpg" },
    { type: "video", src: "/images/onboard/portrait/core_components.mp4" },
    { type: "video", src: "/images/onboard/portrait/under_over.mp4" },
    { type: "video", src: "/images/onboard/portrait/key_stats.mp4" },
];

const SLIDES_CONTENT = [
    {
        title: "Let's grab some deals!",
        body: null,
    },
    // {
    //     title: "Sign in for full access",
    //     body: (
    //         <Box>
    //             Click the connect button to unlock our full valuation
    //             <br />
    //             capabilities, price projection line, and deviation data.
    //         </Box>
    //     ),
    //     smallBody: (
    //         <Box>
    //             Click the connect button to unlock our full valuation capabilities, price projection
    //             line, and deviation data.
    //         </Box>
    //     ),
    // },
    {
        title: "Core components",
        body: (
            <Box>
                Full control over filtering criteria and a commanding
                <br />
                display of every NFT in a collection, all in one view.
            </Box>
        ),
        smallBody: (
            <Box>
                Full control over filtering criteria and a commanding display of every NFT in a
                collection, all in one view.
            </Box>
        ),
    },
    {
        title: (
            <>
                <Box>
                    See{" "}
                    <Box as="span" color="cold">
                        over
                    </Box>
                    /
                    <Box as="span" color="hot">
                        under
                    </Box>
                    priced tokens at a glance
                </Box>
            </>
        ),
        smallTitle: "Every deal at a glance",
        body: (
            <Box textAlign="center">
                Anything above our{" "}
                <Box as="span" color="sorbet">
                    <b>BR</b>ITE
                </Box>{" "}
                Line is priced higher than expected,
                <br />
                and everything below is priced lower than expected.
            </Box>
        ),
        smallBody: (
            <Box textAlign="center">
                Anything above our{" "}
                <Box as="span" color="sorbet">
                    <b>BR</b>ITE
                </Box>{" "}
                Line is priced higher than expected, and everything below is priced lower than
                expected.
            </Box>
        ),
    },
    {
        title: "Key stats",
        body: <Box>Click on any token to get the full details and breakdown. </Box>,
    },
];

type QuickStartProps = {
    onClose: () => void;
    isDesktop: boolean;
};

const QuickStart = ({ onClose, isDesktop }: QuickStartProps): JSX.Element => {
    const isMounted = useRef<boolean>(false);
    const container = useRef<HTMLDivElement>();
    const [swiperTop, setSwiperTop] = useState<SwiperClass>();
    const [swiperBottom, setSwiperBottom] = useState<SwiperClass>();
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    useEffect(() => {
        const onResize = () => {
            if (container.current) container.current.style.height = window.innerHeight - 70 + "px";
        };

        if (!isMounted.current) {
            isMounted.current = true;

            if (!isDesktop && isIOS) {
                window.addEventListener("resize", onResize);
                onResize();
            }
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const showPrevSlide = () => {
        swiperTop.slidePrev(500);
        if (isDesktop) setTimeout(() => swiperBottom.slidePrev(500), 50);
    };

    const showNextSlide = () => {
        if (swiperTop.realIndex === SLIDES_CONTENT.length - 1) {
            onClose();
        } else {
            swiperTop.slideNext(500);
            if (isDesktop) setTimeout(() => swiperBottom.slideNext(500), 50);
        }
    };

    const onSwiperChange = (swiper: SwiperClass) => {
        setCurrentSlide(swiper.realIndex);
    };

    const renderTopSwiper = () => {
        const slides = isDesktop ? LANDSCAPE_SLIDES : PORTRAIT_SLIDES;
        const sliderStyle = isDesktop
            ? { width: "100%", height: "100%" }
            : { aspectRatio: 600 / 850, maxHeight: "100%" };
        const objectFit = isDesktop ? "cover" : "contain";
        return (
            <Swiper
                slidesPerView={1}
                centeredSlides={true}
                initialSlide={0}
                spaceBetween={0}
                onSwiper={setSwiperTop}
                onSlideChange={onSwiperChange}
                allowTouchMove={!isDesktop}
                style={sliderStyle}
                modules={[Controller]}
                controller={{ control: !isDesktop ? swiperBottom : null }}
            >
                {slides.map((slide, index) => {
                    return (
                        <SwiperSlide
                            key={`slide-${index}`}
                            style={{ width: "100%", height: "100%" }}
                        >
                            <Box
                                w="100%"
                                h="100%"
                                position="relative"
                                bg="spaceCadet"
                                userSelect="none"
                            >
                                {slide.type === "video" ? (
                                    <ReactPlayer
                                        url={slide.src}
                                        width="100%"
                                        height="100%"
                                        controls={false}
                                        playing={currentSlide === index}
                                        playsinline
                                        muted={isIOS}
                                        loop
                                    />
                                ) : (
                                    <Image
                                        src={slide.src}
                                        objectFit={objectFit}
                                        objectPosition={"bottom"}
                                        layout="fill"
                                        alt=""
                                    />
                                )}
                            </Box>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    };

    const renderBottomSwiper = () => (
        <Swiper
            onSwiper={setSwiperBottom}
            modules={[Controller]}
            slidesPerView={1}
            centeredSlides={true}
            initialSlide={0}
            spaceBetween={0}
            allowTouchMove={false}
            style={{ width: "100%", height: "100%" }}
        >
            {SLIDES_CONTENT.map((slide, index) => {
                const title = !isDesktop && slide.smallTitle ? slide.smallTitle : slide.title;
                const body = !isDesktop && slide.smallBody ? slide.smallBody : slide.body;

                return (
                    <SwiperSlide key={`slide-${index}`} style={{ width: "100%", height: "100%" }}>
                        <Center
                            w={{ base: "calc(100% - 96px)", sm2: "100%" }}
                            mx="auto"
                            h="100%"
                            flexDir="column"
                            gap={2}
                            position="relative"
                            userSelect="none"
                        >
                            <Box
                                textStyle={{
                                    base: "onboard.mobile.title",
                                    sm2: "onboard.desktop.title",
                                }}
                            >
                                {title}
                            </Box>
                            <Box
                                textStyle={{
                                    base: "onboard.mobile.body",
                                    sm2: "onboard.desktop.body",
                                }}
                            >
                                {body}
                            </Box>
                        </Center>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );

    const maxH = isDesktop ? "auto" : Math.min(window.innerWidth, 420) * (850 / 600);

    return (
        <MotionFlex
            ref={container}
            position="absolute"
            top={{ base: "70px", sm2: "0" }}
            left="0"
            w="100%"
            h={{ base: "calc(100% - 70px)", sm2: "100%" }}
            zIndex="10"
            bg="raisinBlack"
            flexDir="column"
            transformOrigin="center center"
            initial={{ transform: "scale(0)", opacity: 0 }}
            animate={{ transform: "scale(1)", opacity: 1 }}
            exit={{ transform: "scale(0)", opacity: 0 }}
            transition={{ duration: 0.35, easing: "easeOut" }}
            overflow="hidden"
        >
            <Box
                position="relative"
                w="100%"
                h={{ base: `calc(100% - ${BOTTOM_SLIDER_HEIGHT})`, sm2: "auto" }}
                flex={{ base: "0 1 calc(100% - 150px)", sm2: "1 0 auto" }}
                maxH={maxH}
                overflow="hidden"
            >
                {renderTopSwiper()}
            </Box>
            <Box
                position="relative"
                w="100%"
                flex={`0 0 ${BOTTOM_SLIDER_HEIGHT}`}
                h={BOTTOM_SLIDER_HEIGHT}
                pb="30px"
            >
                {renderBottomSwiper()}
                <Box
                    zIndex={300}
                    position="absolute"
                    w="100%"
                    h={{ base: "calc(100% - 30px)", sm2: "100%" }}
                    top="0"
                >
                    <QuickStartNav
                        currentSlide={currentSlide}
                        totalSlides={SLIDES_CONTENT.length - 1}
                        onNextSlide={showNextSlide}
                        onPrevSlide={showPrevSlide}
                    />
                </Box>
            </Box>
            <Box
                position="absolute"
                bottom={currentSlide > 0 ? "12px" : "-20px"}
                textStyle="onboard.desktop.footer"
                left="50%"
                transform="translateX(-50%)"
                transition="opacity .55s, bottom .55s"
                opacity={currentSlide > 0 ? 1 : 0}
                userSelect="none"
            >
                {currentSlide || 1} of {SLIDES_CONTENT.length - 1}
            </Box>
        </MotionFlex>
    );
};

export default QuickStart;
