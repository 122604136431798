import useCollectionTokensStore from "./collectionTokensStore";
import { timeSince } from "./helpers";

export const getVibesFilteredTokens = (filteredVibes: FilteredVibes): TokenStringId[] => {
    if (!filteredVibes || filteredVibes.size === 0) {
        return null;
    }

    const tokensPerVibeMap: TokensPerVibeMap = useCollectionTokensStore.getState().tokensPerVibeMap;

    // collection tokens not loaded yet
    if (!tokensPerVibeMap.size) {
        return [];
    }

    let filteredVibesTokenIds: TokenStringId[] = [];

    for (const filteredVibeId of filteredVibes.keys()) {
        filteredVibesTokenIds = [...filteredVibesTokenIds, ...tokensPerVibeMap.get(filteredVibeId)];
    }

    // easy and fast way to remove duplicates. Convert array into a Set and back to Array!
    return [...new Set(filteredVibesTokenIds)];
};
