import { Box, Collapse, Flex, Grid, GridItem, Link } from "@chakra-ui/react";
import BannerButton from "@components/banners/BannerButton";
import CallingNFTBanner from "@components/banners/CallingNFTBanner";
import { BlockchainNetworkPricesDynamic } from "@components/ui/BlockchainNetworkPricesDynamic";
import HamburgerButton from "@components/ui/HamburgerButton";
import { useScrollPosition } from "@hooks/useScroll";
import { ScrollDirection, useScrollDirection } from "@hooks/useScrollDirection";
import useSessionStore from "@store/sessionStore";
import chakraTheme from "@theme";
import NextImage from "next/image";
import NextLink from "next/link";
import { memo } from "react";
import BRMonogram from "/public/images/br-mark-negative.png";

type MobileHeaderProps = {};

export const MobileHeader = ({}: MobileHeaderProps) => {
    const scrollPosition = useScrollPosition();
    const scrollDirecton = useScrollDirection();
    const mobileMenueIsOpen = useSessionStore((state) => state.mobileMenuIsOpen);

    const collapseTop = scrollPosition && scrollDirecton != ScrollDirection.up;

    const backdropFilter = collapseTop ? "blur(10px)" : "initial";
    const bgStyle = {
        transition: "all .5s",
        background: scrollPosition
            ? chakraTheme.colors.site.headerAlpha
            : chakraTheme.colors.site.header,
        backdropFilter: backdropFilter,
    };

    const onClose = () => {
        useSessionStore.setState((oldState) => ({ mobileMenuIsOpen: false }));
    };

    const onHamburguerToggle = () => {
        useSessionStore.setState((oldState) => ({
            mobileMenuIsOpen: !oldState.mobileMenuIsOpen,
        }));
    };

    const MemoBlockchainNetworkPricesDynamic = memo(BlockchainNetworkPricesDynamic);

    const HeaderContent = () => {
        return (
            <>
                <CallingNFTBanner useReopen={false} />
                <Flex w="100vw" mt="10px" px={2}>
                    <MemoBlockchainNetworkPricesDynamic isDesktop={false}/>
                </Flex>
                <Grid w="100vw" templateColumns={"1fr auto 1fr"} p={4}>
                    <GridItem></GridItem>
                    <GridItem>
                        <Box
                            maxW="156px"
                            w="90vw"
                            h={["50px", , , "55px"]}
                            position="relative"
                            cursor="pointer"
                        >
                            <NextLink shallow href="/" passHref={true}>
                                <Link
                                    href="/"
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    <NextImage
                                        src={BRMonogram}
                                        layout="fill"
                                        priority
                                        alt="BeyondRarity"
                                    />
                                </Link>
                            </NextLink>
                        </Box>
                    </GridItem>
                    <GridItem>
                        <Flex direction="row" justify="end" gap={2}>
                            <Flex
                                h="100%"
                                align="center"
                                justify="end"
                                mt="3px"
                                transition="all 1s"
                            >
                                {scrollPosition <= 150 && <BannerButton />}
                            </Flex>
                            <Flex justify="end">
                                <HamburgerButton
                                    onClickHamburger={onHamburguerToggle}
                                    isOpen={mobileMenueIsOpen}
                                    invisible={false}
                                />
                            </Flex>
                        </Flex>
                    </GridItem>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Flex
                direction="column"
                w="100vw"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
                visibility="hidden"
            >
                <HeaderContent />
            </Flex>
            <Flex
                direction="column"
                as="header"
                style={bgStyle}
                position="fixed"
                zIndex="sticky"
                w="100vw"
                top={0}
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
            >
                <Collapse
                    in={!collapseTop}
                    transition={
                        !scrollPosition
                            ? {
                                  enter: { duration: 0 },
                              }
                            : undefined
                    }
                >
                    <HeaderContent />
                </Collapse>
            </Flex>
        </>
    );
};

export default MobileHeader;
