import { BoxProps, Flex, FlexProps } from "@chakra-ui/react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { useWindowSiteStore } from "@store/windowSiteStore";
import FullOnMobile from "./FullOnMobile";

type PaddedOnMobileProps = {
    containerProps?: FlexProps;
    children?: React.ReactNode;
};

export const PaddedOnMobile = ({
    containerProps,
    children,
    ...props
}: PaddedOnMobileProps & FlexProps) => {
    // const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: { basisType: "site" } });
    const isDesktop = useWindowSiteStore(state => state.isDesktop);

    return (
        <>
            <FullOnMobile px={isDesktop ? "50px" : "30px"} containerProps={containerProps} {...props}>
                {children}
            </FullOnMobile>
        </>
    );
};

export default PaddedOnMobile;
