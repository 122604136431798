import create from "zustand";
import { subscribeWithSelector, persist } from "zustand/middleware";

type PersistedState = {
    lastVisited: number;
    lastCompletedQuickTour: number;
    betaEmail?: any,
    betaCode?: any,

    betaSignupToastLastAutoOpenedDate?: number | null,
    betaSignupToastLastClosedAfterAutoOpenedDate?: number | null,
    lastSubscribedToBetaDate?: number | null,

    tokenModalSwiped?: boolean,
};

const defaultState: PersistedState = {
    lastVisited: null,
    lastCompletedQuickTour: null,
};

// Store for Settings
export const usePersistedStore = create<PersistedState>()(
    persist(
        subscribeWithSelector(() => defaultState),
        {
            name: "beyond-rarity",
            // getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
        },
    ),
);

export const setLastVisited = (dateValue: number) => {
    usePersistedStore.setState(() => ({ lastVisited: dateValue }));
};

export const setCompletedQuickTour = (dateValue: number) => {
    usePersistedStore.setState(() => ({ lastCompletedQuickTour: dateValue }));
};

export default usePersistedStore;
