import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { useEffect, useState } from "react";

type WindowSiteStateManagerProps = {};

export const WindowSiteStateManager = ({}: WindowSiteStateManagerProps) => {
    const { isMounted, windowHeight, windowWidth, isDesktop, isNarrowPortrait, isPortrait } =
        useDebouncedWindowSize({
            useIsMounted: true,
            useWindowHeight: true,
            useWindowWidth: true,
            useIsDesktop: { basisType: "site" },
            useIsNarrowPortrait: true,
            useIsPortrait: true,
        });

    useEffect(() => {
        useWindowSiteStore.setState((oldState) => {
            if (oldState?.isMounted != isMounted) {
                return { isMounted: isMounted };
            }
        });
    }, [isMounted]);

    useEffect(() => {
        useWindowSiteStore.setState((oldState) => {
            if (oldState?.windowHeight != windowHeight) {
                return { windowHeight: windowHeight };
            }
        });
    }, [windowHeight]);

    useEffect(() => {
        useWindowSiteStore.setState((oldState) => {
            if (oldState?.windowWidth != windowWidth) {
                return { windowWidth: windowWidth };
            }
        });
    }, [windowWidth]);

    useEffect(() => {
        useWindowSiteStore.setState((oldState) => {
            if (oldState?.isDesktop != isDesktop) {
                return { isDesktop: isDesktop };
            }
        });
    }, [isDesktop]);

    useEffect(() => {
        useWindowSiteStore.setState((oldState) => {
            if (oldState?.isNarrowPortrait != isNarrowPortrait) {
                return { isNarrowPortrait: isNarrowPortrait };
            }
        });
    }, [isNarrowPortrait]);

    useEffect(() => {
        useWindowSiteStore.setState((oldState) => {
            if (oldState?.isPortrait != isPortrait) {
                return { isPortrait: isPortrait };
            }
        });
    }, [isPortrait]);

    return null;
};

export default WindowSiteStateManager;
