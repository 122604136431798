// App API Settings
const apiDomain = process.env.NEXT_PUBLIC_API_ROOT;
const rootUrl = process.env.VERCEL_URL;

export const API = {
    DOMAIN: apiDomain,
    PATH: "/api/v1",
};

// APP Settings
export const APP = {
    PATH: "/c/",
};

// Auth API
export const AUTH_API_URL = process.env.NEXT_PUBLIC_AUTH_API_ROOT;

// Scale Settings
export const DESKTOP_SIZE = 960;
export const MIN_SIZE = 300;
export const MAX_SIZE = 1200;

// Social Share Settings
export const SOCIAL_SHARE = {
    site: "BeyondRarity.com",
    siteName: "BeyondRarity.com",
    siteTagline: "Next Level NFT Valuation",
    description: "Get the Best NFT Rarity, Ranking, and Valuation on BeyondRarity.com",
    image: "/images/br-socialshare.png",
    siteUrl: rootUrl || "https://www.beyondrarity.com",
    twitterName: "@Beyond_Rarity",
};

// Sorting options for AUTHORIZED users
export const AUTH_SORT_OPTIONS: RadioButtonProps[] = [
    { value: "rank", label: "Rarity Rank" },
    { value: "currentPrice", label: "Price" },
    { value: "priceDeviation", label: "Price Deviation" },
];

// Sorting options for GUEST users (Price Deviation is disabled)
export const GUEST_SORT_OPTIONS: RadioButtonProps[] = [
    { value: "rank", label: "Rarity Rank" },
    { value: "currentPrice", label: "Price" },
    { value: "priceDeviation", label: "Price Deviation", isDisabled: true, isLocked: true },
];

export const AUTH_DEFAULT_SORT_CRITERIA: SortCriteria = "priceDeviation";
export const AUTH_DEFAULT_SORT_ORDER: SortOrder = "desc";

export const GUEST_DEFAULT_SORT_CRITERIA: SortCriteria = "rank";
export const GUEST_DEFAULT_SORT_ORDER: SortOrder = "desc";

export const BETA_CODES = [
    new RegExp("WAGMI2[2|3]"),
    new RegExp("LFG2[2|3]"),
    new RegExp("UNICORN2[2|3]"),
    new RegExp("DEGEN2[2|3]"),
];

export const RANDOM_TOP_COLLECTION_COUNT = 20;

export const MARKETPLACES = [
    {
        id: 1,
        name: "OpenSea",
    },
    {
        id: 2,
        name: "LooksRare",
    },
    {
        id: 3,
        name: "X2Y2",
    },
];
