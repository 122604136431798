import { Button, Flex, Link, Text } from "@chakra-ui/react";
import TutorialsLinkButton from "@components/tutorials/TutorialsLinkButton";
import NextLink from "next/link";

type HeaderRightMenuProps = {
    isDesktop: boolean;
    onMenuClick?: Function;
    fallbackCollections?: any;
};

export default function HeaderRightMenu({ isDesktop = true, onMenuClick }: HeaderRightMenuProps) {
    const handleOnMenuClick = (e) => {
        if (onMenuClick) {
            onMenuClick(e);
        }
    };

    return (
        <>
            <Flex
                direction={isDesktop ? "row" : "column"}
                w="100%"
                h="100%"
                gap={{ base: 4, lg: 8 }}
                align="center"
                justify="center"
                fontSize="20px"
                fontFamily="regular"
            >
                <TutorialsLinkButton />
                {/* <NextLink shallow href="/blog">
                    <Link href="/blog" _hover={{ textDecor: "none" }}>
                        <Text
                            _hover={{ color: "lavenderGray" }}
                            cursor="pointer"
                            onClick={handleOnMenuClick}
                        >
                            Blog
                        </Text>
                    </Link>
                </NextLink> */}
                <NextLink shallow href="/pricing">
                    <Link href="/pricing" _hover={{ textDecor: "none" }}>
                        <Text
                            _hover={{ color: "lavenderGray" }}
                            cursor="pointer"
                            onClick={handleOnMenuClick}
                        >
                            Pricing
                        </Text>
                    </Link>
                </NextLink>
                <NextLink shallow href="/topdeals">
                    <Link href="/topdeals" _hover={{ textDecor: "none" }}>
                        <Text
                            _hover={{ color: "lavenderGray" }}
                            cursor="pointer"
                            onClick={handleOnMenuClick}
                        >
                            Top Deals
                        </Text>
                    </Link>
                </NextLink>
                <NextLink shallow href="/app" passHref>
                    <Link href="/app" _hover={{ textDecor: "none" }}>
                        <Flex _hover={{ textDecor: "none" }} onClick={handleOnMenuClick}>
                            <Button py={5} w="105px" variant="white" fontFamily="regular">
                                <Flex justify="center" align="center" gap="4px">
                                    App
                                    {/* <Flex
                                        fontSize={{ base: "14px", md: "15px" }}
                                        mb={{ base: -0.5, md: 0 }}
                                        color="sorbet"
                                        fontWeight="600"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        BETA
                                    </Flex> */}
                                </Flex>
                            </Button>
                        </Flex>
                    </Link>
                </NextLink>
            </Flex>
        </>
    );
}
