import { getSession } from "next-auth/react";
import { AUTH_API_URL } from "@config/app";

const getUserToken = async (): Promise<string> => {
    const session = await getSession();
    return session?.accessToken || "";
};

const getUserId = async (): Promise<string> => {
    const session = await getSession();
    return session?.user?.id || "";
};

const getAuthHeaders = async (session?): Promise<HeadersInit> => {
    let headers: HeadersInit = {
        "Content-Type": "application/json",
    };

    const token = session ? session.accessToken : await getUserToken();

    if (!token) return headers;

    headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
    };

    return headers;
};

export const authFetcher = async (input: RequestInfo, init?: RequestInit) => {
    const headers = await getAuthHeaders();

    return fetch(input, { headers }).then((res) => {
        return res.json();
    });
};

export const getWalletVerifyNonce = async (address: string) => {
    const fetchOptions: Partial<RequestInit> = {
        method: "POST",
        headers: await getAuthHeaders(),
        body: JSON.stringify({ address }),
    };

    const response = await fetch(`${AUTH_API_URL}/user/wallet/get-sign-nonce`, fetchOptions);
    const result = await response.json();

    return result;
};

export const verifySignature = async (address, signature) => {
    const fetchOptions: Partial<RequestInit> = {
        method: "POST",
        headers: await getAuthHeaders(),
        body: JSON.stringify({ address, signature }),
    };

    const response = await fetch(`${AUTH_API_URL}/user/wallet/verify`, fetchOptions);
    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return { error: true, message: data.message };
    }
};

export const disconnectWallet = async (address: string) => {
    const fetchOptions: Partial<RequestInit> = {
        method: "POST",
        headers: await getAuthHeaders(),
        body: JSON.stringify({ address }),
    };

    const response = await fetch(`${AUTH_API_URL}/user/wallet/disconnect`, fetchOptions);
    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return { error: true, message: data.message };
    }
};

export const isUsernameAvailable = async (username: string): Promise<boolean> => {
    const fetchOptions: Partial<RequestInit> = {
        method: "GET",
        headers: await getAuthHeaders(),
    };

    try {
        const response = await fetch(`${AUTH_API_URL}/user/available/${username}`, fetchOptions);
        const data = await response.json();

        return data.available;
    } catch (e: any) {
        return false;
    }
};

export const updateUserProfile = async (
    updateData: UserUpdateData | UserAboutUpdateData,
    mode: "welcome" | "edit",
) => {
    const fetchOptions: Partial<RequestInit> = {
        method: "PATCH",
        headers: {
            ...(await getAuthHeaders()),
            "x-update-mode": mode,
        },
        body: JSON.stringify({ uid: await getUserId(), user: updateData }),
    };

    const response = await fetch(`${AUTH_API_URL}/user`, fetchOptions);

    const data = await response.json();

    if (data?.status && data.status === "updated") {
    }

    return data;
};

export const getAddressTokens = async (address: string) => {
    const fetchOptions: Partial<RequestInit> = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(`${AUTH_API_URL}/user/tokens/${address}`, fetchOptions);
    const data = await response.json();

    if (response.ok) {
        return data;
    } else {
        return { error: true, message: data.message };
    }
};

export const getUser = async (accessToken: string) => {
    if (!accessToken) return null;

    const headers: HeadersInit = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };

    // try to get user from database (userId can be a username, address or ens domain)
    const response = await fetch(`${AUTH_API_URL}/user`, {
        headers,
    });

    if (!response.ok) return null;

    const data = await response.json();
    return data.user;
};
