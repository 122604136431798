import { Flex, Icon, Image, Link, Text } from "@chakra-ui/react";
import { ExternalIcon } from "@components/icons";
import TransparentModal from "@components/site/layout/TransparentModal";
import { useState } from "react";

export const RequestFeature = ({ children }) => {
    const [openFeatureModal, setOpenFeatureModal] = useState(false);

    const RequestFeatureModal = () => {
        return (
            <>
                <TransparentModal open={openFeatureModal} close={() => setOpenFeatureModal(false)}>
                    <Flex direction={"column"} pt={{ base: "32px", md: 0 }}>
                        <Flex direction={"column"} align="center" justify={"center"}>
                            <Text textStyle={"site.header"}>Let&apos;s get in touch</Text>
                            <Text textStyle={"site.gray.text"}>What do you want to do?</Text>
                            <Flex
                                direction={{ base: "column", md: "row" }}
                                gap={{ base: 8, md: 16 }}
                                pt={8}
                            >
                                <Link
                                    href="https://form.jotform.com/BeyondRarity/suggest-feature"
                                    isExternal
                                    _hover={{ textDecoration: "none" }}
                                >
                                    <Flex direction={"column"} cursor="pointer" role="group">
                                        <Icon
                                            as={Image}
                                            src="/icons/suggest-feature.svg"
                                            fontSize={{ base: 64, md: 80 }}
                                            color="lavenderGray"
                                        />
                                        <Text
                                            textStyle={"site.gray.iconText"}
                                            _groupHover={{ textColor: "white" }}
                                        >
                                            Suggest
                                            <br />
                                            feature
                                            <ExternalIcon ml="4px" fontSize="60%" />
                                        </Text>
                                    </Flex>
                                </Link>
                                <Link
                                    href="https://form.jotform.com/BeyondRarity/report-bug"
                                    isExternal
                                    _hover={{ textDecoration: "none" }}
                                >
                                    <Flex direction={"column"} cursor="pointer" role="group">
                                        <Icon
                                            as={Image}
                                            src="/icons/bug.svg"
                                            fontSize={{ base: 64, md: 80 }}
                                            color="lavenderGray"
                                        />
                                        <Text
                                            textStyle={"site.gray.iconText"}
                                            _groupHover={{ textColor: "white" }}
                                        >
                                            Report a<br />
                                            bug
                                            <ExternalIcon ml="4px" fontSize="60%" />
                                        </Text>
                                    </Flex>
                                </Link>
                                <Link
                                    href="https://form.jotform.com/BeyondRarity/request-project"
                                    isExternal
                                    _hover={{ textDecoration: "none" }}
                                >
                                    <Flex direction={"column"} cursor="pointer" role="group">
                                        <Icon
                                            as={Image}
                                            src="/icons/project.svg"
                                            fontSize={{ base: 64, md: 80 }}
                                            color="lavenderGray"
                                        />
                                        <Text
                                            textStyle={"site.gray.iconText"}
                                            _groupHover={{ textColor: "white" }}
                                        >
                                            Request a<br />
                                            project
                                            <ExternalIcon ml="4px" fontSize="60%" />
                                        </Text>
                                    </Flex>
                                </Link>
                            </Flex>
                        </Flex>
                    </Flex>
                </TransparentModal>
            </>
        );
    };

    return (
        <>
            <RequestFeatureModal />
            <Flex onClick={() => setOpenFeatureModal(true)}>{children}</Flex>
        </>
    );
};
