import type { ComponentStyleConfig } from "@chakra-ui/theme";

const AccordionButton: ComponentStyleConfig = {
    baseStyle: {
        fontWeight: 500,
        color: "white",
        borderRadius: "base",
        boxSizing: "border-box",
        minW: "70px",
    },
};

export default AccordionButton;
