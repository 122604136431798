import React from "react";
import Banner from "./Banner";
import { Box, Link } from "@chakra-ui/react";
import useIsMounted from "@hooks/useIsMounted";
import { ExternalIcon } from "@components/icons";

export const CallingNFTBanner = ({ ...props }) => {
    return (
        <Banner {...props}>
            {/* <Link href="/onboarding" _hover={{textDecor: "none"}}> */}
            <Box fontSize="site.siteText">
                We&apos;re releasing this Beta with a limited selection of projects and adding more
                daily. Looking for something in particular?{" "}
                <Box as="span" whiteSpace="nowrap">
                    <Link
                        href="https://form.jotform.com/BeyondRarity/request-project"
                        isExternal
                        textDecor="underline"
                        fontWeight={500}
                    >
                        Request a project and we&apos;ll list it asap.
                    </Link>{" "}
                    <Link
                        href="https://form.jotform.com/BeyondRarity/request-project"
                        isExternal
                        _hover={{ textDecor: "none" }}
                    >
                        <ExternalIcon fontSize="70%" />
                    </Link>
                </Box>
            </Box>
            {/* </Link> */}
        </Banner>
    );
};

export default CallingNFTBanner;
