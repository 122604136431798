import { createContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSession, signOut, signIn } from "next-auth/react";
import { useAccount, useDisconnect } from "wagmi";
import { signMessage } from "@wagmi/core";
import { disconnectWallet, getWalletVerifyNonce, verifySignature } from "@apiSrc/auth";
import { useWeb3Modal } from "@web3modal/react";
import { reloadSession } from "auth";

type WalletStatus = "disconnected" | "connecting" | "verifying" | "connected" | "disconnecting";

type UserContextProviderProps = {
    children: React.ReactNode;
};

const defaultValue = {
    user: null,
    status: "disconnected",
    signOut: null,
    signIn: null,
    walletStatus: "disconnected",
    connectWallet: null,
    disconnectWallet: null,
};

export const UserContext = createContext(defaultValue);

const UserContextProvider = ({ children }: UserContextProviderProps): JSX.Element => {
    const lastWeb3ModalIsOpen = useRef<boolean>(false);
    const [walletStatus, setWalletStatus] = useState<WalletStatus>("disconnected");
    const { data: session, status } = useSession();
    const { disconnect } = useDisconnect();
    const { isOpen, open: openWeb3Modal } = useWeb3Modal();
    const { address, isConnected } = useAccount();

    const user = session?.user;
    const userHasVerifiedWallet = user?.wallet?.address && user?.wallet?.verified;

    // set initial state based on user data (wallet address & wallet verified)
    useEffect(() => {
        if (walletStatus === "disconnected" && userHasVerifiedWallet) {
            setWalletStatus("connected");
        } else if (walletStatus === "connected" && !session?.user?.wallet?.address) {
            setWalletStatus("disconnected");
        }
    }, [session]);

    // sets wallet status back to disconnected if user closes web3Modal without connecting
    useEffect(() => {
        if (isOpen === lastWeb3ModalIsOpen.current) return;

        lastWeb3ModalIsOpen.current = isOpen;
        if (!isOpen && !address) setWalletStatus("disconnected");
    }, [isOpen]);

    // Attempts to sign a nonce message for the current wallet
    async function signNonceMessage(message: string): Promise<string> {
        try {
            return await signMessage({ message: message });
        } catch (e: any) {
            if (e.code && e.code === "ACTION_REJECTED") return null;
        }
    }

    // Attempts to verify a wallet address by signing a nonce message
    async function verifyWallet(address: string): Promise<boolean> {
        if (!address) return;

        const { nonce } = await getWalletVerifyNonce(address);
        const signature = await signNonceMessage(`${nonce}`);

        const { error } = await verifySignature(address, signature);

        if (!error) {
            return true;
        } else {
            onDisconnectWallet();
        }
    }

    const connectWallet = async () => {
        // ser already connected a wallet. Disconnect first if need to connect to another wallet.
        if (user?.wallet?.verified) return;

        // User connected a wallet but it's not verified, need to reconect
        if (address || user?.wallet?.address) await disconnect();

        // All set to connect.
        setWalletStatus("connecting");
        openWeb3Modal();
    };

    // Sign out callback
    const onSignOut = async () => {
        // if (address && isConnected) disconnect();
        disconnect();
        console.debug("onSignOut - after wallet disconnect");
        await signOut({ redirect: false })
            .then((result) => {
                console.debug("onSignOut - signout success");
                return result;
            })
            .catch((error) => {
                console.debug("onSignOut - signOut error: ", error);
            });
        console.debug("onSignOut - after next-auth sign out");
        await reloadSession()
            .then((result) => {
                console.debug("onSignOut - reloadSession success");
                return result;
            })
            .catch((error) => {
                console.debug("onSignOut - reloadSession error: ", error);
            });
        console.debug("onSignOut - after next-auth reload ssstion");
    };

    const onDisconnectWallet = async () => {
        //console.log("Disconnecting wallet...");
        if (user?.wallet?.address) {
            // remove any wallet data from database
            setWalletStatus("disconnecting");
            //console.log("  - Removing wallet info from database...");
            await disconnectWallet(user.wallet.address);
            //console.log("  - Removed!");
        }

        if (address && isConnected) {
            disconnect();
            //console.log("  - Disconnected wallet from Dapp");
        }
        await reloadSession();
        setWalletStatus("disconnected");
    };

    const walletConnectCallback = async (address, isReconnected) => {
        //console.log("Wallet connected callback.");
        // isReconnected is TRUE on page load if there's a wallet connected to our DApp.
        // Every time we get a connected callback with isReconnected = true, we need to
        // check the use has verified the wallet, otherwise we must disconnect
        if (isReconnected) {
            //console.log("Is reconnected!");
            //console.log("User has verifiedWallet?", userHasVerifiedWallet);
            if (!userHasVerifiedWallet) disconnect();
        } else {
            setWalletStatus("verifying");
            const isVerified = await verifyWallet(address);

            if (isVerified) {
                setWalletStatus("connected");
                await reloadSession();
            } else {
                await disconnectWallet(address);
                disconnect();
                await reloadSession();
            }
        }
    };

    const walletDisconnectCallback = async () => {
        //console.log("Wallet disconnected callback!");
        if (!userHasVerifiedWallet) setWalletStatus("disconnected");
    };

    const {} = useAccount({
        onConnect({ address, isReconnected }) {
            walletConnectCallback(address, isReconnected);
        },
        onDisconnect() {
            walletDisconnectCallback();
        },
    });

    const contextValue = {
        user: session?.user,
        status,
        signOut: onSignOut,
        signIn,
        walletStatus,
        connectWallet,
        disconnectWallet: onDisconnectWallet,
    };

    return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
