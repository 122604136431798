import { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { setCompletedQuickTour } from "@store/persistedStore";
import TutorialsModal from "./TutorialsModal";

type TutorialsLinkButtonProps = {};

export const TutorialsLinkButton = ({}: TutorialsLinkButtonProps) => {
    const [shouldRenderModal, setShouldRenderModal] = useState<boolean>(false);

    const handleOnTutorialsClick = () => {
        setShouldRenderModal(true);
    };

    const onboardCompleted = () => {
        setCompletedQuickTour(Date.now());
        setShouldRenderModal(false);
    };

    const onTutorialsCloseComplete = () => {
        setCompletedQuickTour(Date.now());
        setShouldRenderModal(false);
    };
    
    return (
        <>
            <Text
                _hover={{ color: "lavenderGray" }}
                cursor="pointer"
                onClick={handleOnTutorialsClick}
            >
                Tutorials
            </Text>
            {shouldRenderModal && (
                <TutorialsModal
                    isOpen={shouldRenderModal}
                    onClose={onboardCompleted}
                    onCloseComplete={onTutorialsCloseComplete}
                />
            )}
        </>
    );
};

export default TutorialsLinkButton;
