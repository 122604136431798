import styles from "../../styles/Nav.module.css";
import PropTypes from "prop-types";

export default function HamburgerButton({ isOpen, onClickHamburger = null, invisible }) {
    const cls = invisible ? styles.navIconInvisible : styles.navIcon;
    const buttonClass = `${cls} ${isOpen ? styles.open : ""}`;

    const handleOnClick = (e) => {
        if (onClickHamburger) onClickHamburger(e);
    };

    return (
        <button className={buttonClass} onClick={handleOnClick}>
            <div></div>
        </button>
    );
}

HamburgerButton.defaultProps = {
    isOpen: false,
    onClick: undefined,
};

HamburgerButton.propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
};
