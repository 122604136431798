const colors = {
    transparent: "transparent",
    black: "#000",
    white: "#fff",
    // magenta: "#E93766",
    snowfall: "#F4F7FA",
    sorbet: "#E93766",
    sorbetScheme: {
        500: "#E93766",
    },
    sorbetAlpha: {
        100: "#E9376611",
        200: "#E9376622",
        300: "#E9376633",
        400: "#E9376644",
        500: "#E9376655",
        600: "#E9376666",
        700: "#E9376677",
        800: "#E9376688",
        900: "#E9376699",
        AA: "#E93766AA",
        BB: "#E93766BB",
        CC: "#E93766CC",
        DD: "#E93766DD",
        EE: "#E93766EE",
        FF: "#E93766FF",
    },
    emerald: "#6AB77B",
    violet: "#100d1e",
    hot: "#FA9600",
    hotOrange: "#F77A59",
    cold: "#80B4F0",
    unlisted: "#E93766",
    lavenderGray: "#BBBBD1",
    lavenderGrayAlpha: {
        100: "#BBBBD111",
        200: "#BBBBD122",
        300: "#BBBBD133",
        400: "#BBBBD144",
        500: "#BBBBD155",
        600: "#BBBBD166",
        700: "#BBBBD177",
        800: "#BBBBD188",
        900: "#BBBBD199",
        AA: "#BBBBD1AA",
        BB: "#BBBBD1BB",
        CC: "#BBBBD1CC",
        DD: "#BBBBD1DD",
        EE: "#BBBBD1EE",
        FF: "#BBBBD1FF",
    },
    spaceCadet: "#282D41",
    spaceCadetLight: "#31384F",
    spaceCadetLightAlpha: {
        0: "#31384F00",
        100: "#2F354611",
        200: "#2F354622",
        300: "#2F354633",
        400: "#2F354644",
        500: "#2F354655",
        600: "#2F354666",
        700: "#2F354677",
        800: "#2F354688",
        900: "#2F354699",
        AA: "#2F3546AA",
        BB: "#2F3546BB",
        CC: "#2F3546CC",
        DD: "#2F3546DD",
        EE: "#2F3546EE",
        FF: "#2F3546FF",
    },
    spaceCadetCard: "#3F4964",
    spaceCadetCardAlpha: {
        0: "#3F496400",
        100: "#3F496411",
        200: "#3F496422",
        300: "#3F496433",
        400: "#3F496444",
        500: "#3F496455",
        600: "#3F496466",
        700: "#3F496477",
        800: "#3F496488",
        900: "#3F496499",
        AA: "#3F4964AA",
        BB: "#3F4964BB",
        CC: "#3F4964CC",
        DD: "#3F4964DD",
        EE: "#3F4964EE",
        FF: "#3F4964FF",
    },
    arsenic: "#31384F",
    rythm: "#767A90",
    independence: "#464E6C",
    blackCoral: "#62677A",
    menuPriceTextColor: "#FF648D",
    arsenicLight: "#3F445A",
    hotGradient: {
        start: "#FF2A00",
        end: "#FA9600",
    },
    coldGradient: {
        start: "#80B4F0",
        end: "#AFD7F8",
    },
    gray: {
        light: "#BBBBD1",
        faint: "#BBBBD1",
        dark: "#5d5d5d",
        tokenListItem: "#31384F", // spaceCadetLight
    },
    raisinBlack: "#1C212F",
    raisinBlackAlpha: {
        0: "#1C212F00",
        10: "#1C212F1A",
        20: "#1C212F33",
        30: "#1C212F4D",
        40: "#1C212F66",
        50: "#1C212F80",
        60: "#1C212F00",
        70: "#1C212FB3",
        80: "#1C212FCC",
        90: "#1C212FE6",
        100: "#1C212FFF",
    },
    blue: {
        darker: "#1C212F",
        dark: "#282D41",
        mid: "#30374f",
        med: "#3a415f",
        light: "#49CCFF",
        darkAlpha: {
            100: "#282D4111",
            200: "#282D4122",
            300: "#282D4133",
            400: "#282D4144",
            500: "#282D4155",
            600: "#282D4166",
            700: "#282D4177",
            800: "#282D4188",
            900: "#282D4199",
            AA: "#282D41AA",
            BB: "#282D41BB",
            CC: "#282D41CC",
            DD: "#282D41DD",
            EE: "#282D41EE",
            FF: "#282D41FF",
        },
    },
    button: {
        default: {
            default: "#596388",
            hover: "#49516F",
            active: "#404863",
            disabled: "#4E546A",
        },
        gold: {
            default: "#FFD569", // vegasGold
            hover: "#FFBF1F",
            active: "#E6A90F",
            disabled: "#C8BDA3",
        },
        dimmed: {
            default: "#BBBBD1", // lavendarGray
            hover: "#AAAAC5",
            active: "#9E9EBD",
            disabled: "#A4A4B6",
        },
    },
    site: {
        // header: "#282D41",
        header: "transparent",
        headerAlpha: "#1d2130E0",
        // headerAlpha: "#282D4199",
        body: "#282D41",
    },
    panel: {
        bg: {
            dark: "#282D41", // spaceCadet
            darkAlpha: {
                100: "#282D4111",
                200: "#282D4122",
                300: "#282D4133",
                400: "#282D4144",
                500: "#282D4155",
                600: "#282D4166",
                700: "#282D4177",
                800: "#282D4188",
                900: "#282D4199",
                AA: "#282D41AA",
                BB: "#282D41BB",
                CC: "#282D41CC",
                DD: "#282D41DD",
                EE: "#282D41EE",
                FF: "#282D41FF",
            },
            light: "#31384F", //"#2F3546",
            lightAlpha: {
                0: "#31384F00",
                100: "#2F354611",
                200: "#2F354622",
                300: "#2F354633",
                400: "#2F354644",
                500: "#2F354655",
                600: "#2F354666",
                700: "#2F354677",
                800: "#2F354688",
                900: "#2F354699",
                AA: "#2F3546AA",
                BB: "#2F3546BB",
                CC: "#2F3546CC",
                DD: "#2F3546DD",
                EE: "#2F3546EE",
                FF: "#2F3546FF",
            },
            med: "#303547",
        },
    },
    // gold: {
    //     50: "#f1d334",
    //     100: "#f0d038",
    //     200: "#efcc3c",
    //     300: "#eec841",
    //     400: "#ecc347",
    //     500: "#eabd4e",
    //     600: "#d3ac4b",
    //     700: "#cba64a",
    //     800: "#c7a44a",
    //     900: "#bb9b48",
    // },
    gold: {
        // VegasGold
        50: "#FFD569",
        100: "#FFD569",
        200: "#FFD569",
        300: "#FFD569",
        400: "#FFD569",
        500: "#FFD569",
        600: "#FFD569",
        700: "#FFD569",
        800: "#FFD569",
        900: "#FFD569",
    },
    steelblue: {
        50: "#828695",
        100: "#797e8d",
        200: "#6e7485",
        300: "#62687b",
        400: "#545a6e",
        500: "#434A60",
        600: "#3d4357",
        700: "#383e50",
        800: "#34394a",
        900: "#303545",
    },
    lightblue: {
        50: "#eff1f5",
        100: "#d0d4e2",
        200: "#b1b8ce",
        300: "#919bba",
        400: "#727fa7",
        500: "#58658d",
        600: "#454f6e",
        700: "#31384e",
        800: "#1d222f",
        900: "#0a0b10",
    },
    chart: {
        faint: "#343a49",
        light: "#A7A7A7",
        datapoint: {
            listed: {
                hot: {
                    point: "#ffb13c",
                    pointBorder: "#fff",
                    hover: "#FA9600",
                    hoverBorder: "#fff",
                },
                cold: {
                    point: "#516c93",
                    pointBorder: "#888",
                    hover: "#80B4F0",
                    hoverBorder: "#fff",
                },
                disconnected: {
                    point: "#49CCFF",
                    pointBorder: "#fff",
                    hover: "#eabd4e",
                    hoverBorder: "#fff",
                },
            },
            unlisted: {
                connected: {
                    point: "#E93766",
                    pointBorder: "#E93766",
                    hover: "#E93766",
                    hoverBorder: "#fff",
                },
                disconnected: {
                    point: "#888",
                    pointBorder: "#888",
                    hover: "#eabd4e", //"#5d5d5d",
                    hoverBorder: "#fff",
                },
            },
            owned: {
                point: "#927CFF",
                pointBorder: "#fff",
                over: "#584f98",
                hover: "#ffb13c",
                hoverBorder: "#fff",
                from: "#FFFFFF",
                to: "#927CFF",
            },
        },
        tooltip: {
            background: "#1C212FC0",
        },
    },
    table: {
        even: "#383F56",
        odd: "#464E6C", // independence
    },
};

export default colors;
