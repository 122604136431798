import React from "react";
import { GoogleLogo } from "@components/icons";
import { BsTwitter, BsFacebook } from "react-icons/bs";
import { Box, BoxProps, Icon, Text } from "@chakra-ui/react";

const ICONS = {
    google: GoogleLogo,
    twitter: BsTwitter,
    facebook: BsFacebook,
};
const COLORS = {
    google: "auto",
    twitter: "#00ACED",
    facebook: "#1877F2",
};

type PlatformButtonProps = {
    platform: "google" | "twitter" | "facebook";
    label?: string;
    onClick?: (platform: "google" | "twitter" | "facebook") => void;
} & Omit<BoxProps, "onClick">;

const PlatformButton = ({
    platform,
    label,
    onClick,
    ...props
}: PlatformButtonProps): JSX.Element => {
    const onButtonClick = () => {
        onClick?.(platform);
    };

    return (
        <Box
            as="button"
            display="flex"
            h="50px"
            minW="50px"
            border="1px solid #D9E1EC"
            rounded="4px"
            alignItems="center"
            justifyContent="center"
            _hover={{ bg: "#EFF1F5" }}
            _active={{ bg: "#e6e8f0" }}
            _focus={{ border: "1px solid #D9E1EC" }}
            _focusVisible={{ outline: "none" }}
            transition="background .15s"
            onClick={onButtonClick}
            {...props}
        >
            <Icon as={ICONS[platform]} color={COLORS[platform]} w="20px" h="20px" />
            {label && (
                <Text fontSize={{ base: "16px", sm2: "18px" }} fontWeight="600" ml="10px">
                    {label}
                </Text>
            )}
        </Box>
    );
};

export default PlatformButton;
