import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Box, BoxProps, Center, Spinner } from "@chakra-ui/react";
import ReactPlayer from "react-player";

type TutorialVideoPlayerProps = {
    videoId: string;
    onVideoComplete?: () => void;
    isDesktop?: boolean;
    source?: "vimeo" | "youtube";
    useSetMotionBoxHeight?: boolean;
    motionBoxProps?: BoxProps;
    containerProps?: BoxProps;
};

const MotionBox = motion(Box);

const TutorialVideoPlayer = ({
    videoId,
    onVideoComplete,
    isDesktop,
    source = "youtube",
    useSetMotionBoxHeight = true,
    motionBoxProps,
    containerProps,
}: TutorialVideoPlayerProps): JSX.Element => {
    const isMounted = useRef<boolean>(false);
    const container = useRef<HTMLDivElement>();
    const [playerReady, setPlayerReady] = useState<boolean>(false);

    useEffect(() => {
        const onResize = () => {
            if (container.current && useSetMotionBoxHeight)
                container.current.style.height = `${window.innerHeight - 70}px`;
        };

        if (!isMounted.current) {
            isMounted.current = true;

            if (!isDesktop) {
                window.addEventListener("resize", onResize);
                onResize();
            }
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const onPlayerReady = () => {
        setPlayerReady(true);
    };

    const containerStyle = isDesktop ? { height: "100%" } : { aspectRatio: 16 / 9 };

    return (
        <MotionBox
            ref={container}
            position="absolute"
            top={{ base: "70px", sm2: "0" }}
            left="0"
            w="100%"
            h={{ base: "calc(100% - 70px)", sm2: "100%" }}
            zIndex="10"
            bg={{ base: "black", sm2: "raisinBlack" }}
            initial={{ transform: "scale(0)", opacity: 0 }}
            animate={{ transform: "scale(1)", opacity: 1 }}
            exit={{ transform: "scale(0)", opacity: 0 }}
            transition={{ duration: 0.35, easing: "easeOut" }}
            {...motionBoxProps}
        >
            <Box
                position="relative"
                w="100%"
                style={containerStyle}
                mt={{ base: "50%", sm2: "auto" }}
                transform={{ base: "translateY(-50%)", sm2: "none" }}
                zIndex="2"
                overflow="hidden"
                {...containerProps}
            >
                <ReactPlayer
                    url={
                        source == "vimeo"
                            ? `https://www.vimeo.com/${videoId}`
                            : `https://www.youtube.com/watch?v=${videoId}`
                    }
                    playing
                    onEnded={onVideoComplete}
                    width="100%"
                    height="100%"
                    style={{ position: "relative", zIndex: 2 }}
                    controls
                    onReady={onPlayerReady}
                    playsinline={isDesktop}
                    muted={!isDesktop}
                />
                {!playerReady && (
                    <Center position="absolute" top="0" left="0" w="100%" h="100%">
                        <Spinner />
                    </Center>
                )}
            </Box>
        </MotionBox>
    );
};

export default TutorialVideoPlayer;
