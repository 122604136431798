import Head from "next/head";
import { SOCIAL_SHARE } from "@config/app";

type SocialShareProps = {
    browserTabTitle?: string;
    socialShareTitle?: string;
    description?: string;
    relImage?: string;
    image?: string;
    imageAlt?: string;
    urlSubPath?: string;
    type?: string;
    twitterCard?: string;
    siteName?: string;
    twitterSite?: string;
};

export const SocialShare = ({
    browserTabTitle,
    socialShareTitle = `${SOCIAL_SHARE.site} - ${SOCIAL_SHARE.siteTagline}`,
    description = SOCIAL_SHARE.description,
    relImage = SOCIAL_SHARE.image,
    image = null,
    imageAlt = "Beyond Rarity Logo",
    urlSubPath = "",
    type = "website",
    twitterCard = "summary_large_image",
    siteName = SOCIAL_SHARE.siteName,
    twitterSite = SOCIAL_SHARE.twitterName,
}: SocialShareProps) => {
    const siteUrl = process.env.NEXT_PUBLIC_SITE_URL
        ? process.env.NEXT_PUBLIC_SITE_URL
        : process.env.VERCEL_URL
        ? process.env.VERCEL_URL
        : SOCIAL_SHARE.siteUrl;

    return (
        <Head>
            <title key="title">{`Beyond Rarity${browserTabTitle ? " - " + browserTabTitle : ""}`}</title>

            {/* <!--  SEO Tags --> */}
            <meta key="description" name="description" content={description} />
            {/* <!--  Essential META Tags --> */}
            <meta key="og:title" property="og:title" content={socialShareTitle} />
            <meta key="og:type" property="og:type" content={type} />
            <meta key="og:image" property="og:image" content={image ? image : siteUrl + relImage} />
            <meta key="og:url" property="og:url" content={siteUrl + urlSubPath} />
            <meta key="twitter:card" name="twitter:card" content={twitterCard} />

            {/* <!--  Non-Essential, But Recommended --> */}
            <meta key="og:description" property="og:description" content={description} />
            <meta key="og:site_name" property="og:site_name" content={siteName} />
            <meta key="twitter:title" name="twitter:title" content={socialShareTitle} />
            <meta key="twitter:description" name="twitter:description" content={description} />
            <meta key="twitter:image" name="twitter:image" content={image ? image : siteUrl + relImage} />
            <meta key="twitter:image:alt" name="twitter:image:alt" content={imageAlt} />

            {/* <!--  Non-Essential, But Required for Analytics --> */}
            {/* <meta key="fb:app_id" content="your_app_id"  property="fb:app_id" content="your_app_id" /> */}
            <meta key="twitter:site" name="twitter:site" content={twitterSite} />
        </Head>
    );
};

export const CollectionSocialShare = ({
    collectionId,
    collectionName,
    description,
}) => {
    const title = `${collectionName} on Beyond Rarity`;
    const urlSubPath = `/c/${collectionId}`

    return (<>
        <SocialShare browserTabTitle={`${collectionName}`} socialShareTitle={title} description={description} imageAlt={title} urlSubPath={urlSubPath} />
        </>
    );
};

export const TokenSocialShare = ({
    collectionId,
    collectionName,
    collectionSize,
    tokenStringId,
    tokenName,
    tokenRank,
    image,
}) => {

    const urlSubPath = `/c/${collectionId}/${tokenStringId}`;
    const title = `${collectionName} - #${tokenStringId}`;
    const description = `Ranked ${tokenRank} of ${collectionSize}. Get the Best NFT Rarity, Ranking, and Valuation on BeyondRarity.com`;

    return (
        <SocialShare browserTabTitle={`${tokenName ? tokenName : `${collectionName} - Token #${tokenStringId}`}`} socialShareTitle={title} description={description} image={image} imageAlt={title} urlSubPath={urlSubPath} />
    );
};

export default SocialShare;
