import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Grid,
    GridItem,
    Link,
    Image,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { GetAccess } from "@components/site/GetAccess";
import { RequestFeature } from "@components/site/RequestFeature";
import dynamic from "next/dynamic";
//import Image from "next/image";
import { useState } from "react";

import SocialMediaIcons from "@components/site/layout/SocialMediaIcons";
//import BrLogoMobile from "/public/images/br-logotype-full-negative.png";
//import BrLogo from "/public/images/br-logotype-negative.png";
import { useWindowSiteStore } from "@store/windowSiteStore";
import BetaSignupToast from "@components/site/JoinBeta/BetaSignupToast";
import TutorialsLinkButton from "@components/tutorials/TutorialsLinkButton";

export const Footer = (): JSX.Element => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const logo = isDesktop
        ? "/images/br-logotype-negative.png"
        : "/images/br-logotype-full-negative.png";

    const AccordianItemWrapper = ({ label, children }) => {
        if (isDesktop) return <>{children}</>;

        return (
            <>
                <AccordionItem border="none">
                    <AccordionButton _hover={{ bgColor: "none" }}>
                        <Flex w="100%" justify="center" align="center">
                            <AccordionIcon boxSize="8" />
                            <Box textStyle="site.gray.text">{label}</Box>
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel pb={4}>{children}</AccordionPanel>
                </AccordionItem>
            </>
        );
    };

    const AccordianWrapper = ({ children }) => {
        if (isDesktop) return <>{children}</>;

        return (
            <>
                <Accordion
                    //w="100%"
                    //minW="300px"
                    border="none"
                    allowToggle
                >
                    {children}
                </Accordion>
            </>
        );
    };

    return (
        <>
            <Flex
                as="footer"
                role="contentinfo"
                direction="column"
                w="100vw"
                maxW="100vw"
                pt={10}
                pb={20}
                px={isDesktop ? "30px" : "20px"}
                justify="center"
                align="center"
                bg="arsenic"
            >
                <Flex textStyle="site.gray.text" pb={10} textAlign="center">Beyond Rarity is a web app that helps fans answer the question: &quot;Is this token a good deal?&quot;</Flex>
                <AccordianWrapper>
                    <Grid
                        w="100%"
                        maxW="1100px"
                        templateColumns={isDesktop ? "auto 1fr auto 1fr auto 1fr auto" : undefined}
                        templateRows={isDesktop ? "auto auto auto auto" : undefined}
                        gap={0}
                    >
                        <GridItem pb={isDesktop ? 0 : 8}>
                            <Flex
                                direction="column"
                                justify="center"
                                align={isDesktop ? "start" : "center"}
                                gap={4}
                            >
                                <Box
                                    w={isDesktop ? "260px" : "90%"}
                                    //h={isDesktop ? "51px" : "29px"}
                                    maxW={isDesktop ? "260px" : "280px"}
                                    position="relative"
                                    cursor="pointer"
                                    textAlign="center"
                                >
                                    <NextLink shallow href="/" passHref={true}>
                                        <Link href="/">
                                            <Image src={logo} objectFit="fill" alt="BeyondRarity" />
                                        </Link>
                                    </NextLink>
                                </Box>
                                <Box
                                    textAlign={isDesktop ? "left" : "center"}
                                    ml={isDesktop ? "57px" : 0}
                                >
                                    <Box textStyle="site.gray.text">Next level NFT valuation.</Box>
                                </Box>

                                <Box
                                    alignSelf={isDesktop ? "start" : "center"}
                                    ml={isDesktop ? "57px" : 0}
                                >
                                    <SocialMediaIcons />
                                </Box>
                            </Flex>
                        </GridItem>

                        {isDesktop && <GridItem />}

                        <GridItem>
                            <AccordianItemWrapper label="SITEMAP">
                                <Flex
                                    direction="column"
                                    justifyContent="center"
                                    align={isDesktop ? "start" : "center"}
                                    gap={2}
                                >
                                    {isDesktop && <Box textStyle="site.gray.text">SITEMAP</Box>}
                                    <NextLink shallow href="/" passHref>
                                        <Link href="/" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Home</Box>
                                        </Link>
                                    </NextLink>
                                    {/* <NextLink shallow href="/blog" passHref>
                                        <Link href="/blog" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Blog</Box>
                                        </Link>
                                    </NextLink> */}
                                    <NextLink shallow href="/about" passHref>
                                        <Link href="/about" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">About</Box>
                                        </Link>
                                    </NextLink>

                                    <NextLink
                                        shallow
                                        href="mailto:marketing@beyondrarity.com"
                                        passHref
                                    >
                                        <Link
                                            href="mailto:marketing@beyondrarity.com"
                                            _hover={{ textDecor: "none" }}
                                        >
                                            <Box textStyle="site.footerLink">Affiliates</Box>
                                        </Link>
                                    </NextLink>
                                </Flex>
                            </AccordianItemWrapper>
                        </GridItem>

                        {isDesktop && <GridItem />}

                        <GridItem>
                            <AccordianItemWrapper label="APP">
                                <Flex
                                    direction="column"
                                    justifyContent="center"
                                    align={isDesktop ? "start" : "center"}
                                    gap={2}
                                >
                                    {isDesktop && <Box textStyle="site.gray.text">APP</Box>}

                                    <NextLink shallow href="/app" passHref>
                                        <Link href="/app" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">View App</Box>
                                        </Link>
                                    </NextLink>
                                    <Box textStyle="site.footerLink">
                                        <TutorialsLinkButton />
                                    </Box>
                                    <NextLink shallow href="/getlisted" passHref>
                                        <Link href="/getlisted" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Get listed</Box>
                                        </Link>
                                    </NextLink>
                                    <Box textStyle="site.footerLink">
                                        <RequestFeature>Request feature</RequestFeature>
                                    </Box>
                                    <NextLink shallow href="/pricing" passHref>
                                        <Link href="/pricing" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Pricing</Box>
                                        </Link>
                                    </NextLink>
                                </Flex>
                            </AccordianItemWrapper>
                        </GridItem>

                        {isDesktop && <GridItem />}

                        <GridItem>
                            <AccordianItemWrapper label="COMPANY">
                                <Flex
                                    direction="column"
                                    justifyContent="center"
                                    align={isDesktop ? "start" : "center"}
                                    gap={2}
                                >
                                    {isDesktop && <Box textStyle="site.gray.text">COMPANY</Box>}
                                    <NextLink shallow href="/media" passHref>
                                        <Link href="/media" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Media kit</Box>
                                        </Link>
                                    </NextLink>
                                    <NextLink shallow href="/contact" passHref>
                                        <Link href="/contact" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Contact</Box>
                                        </Link>
                                    </NextLink>
                                    <NextLink shallow href="/careers" passHref>
                                        <Link href="/careers" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Careers</Box>
                                        </Link>
                                    </NextLink>
                                    <NextLink shallow href="/terms" passHref>
                                        <Link href="/terms" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Term of use</Box>
                                        </Link>
                                    </NextLink>
                                    <NextLink shallow href="/privacy" passHref>
                                        <Link href="/privacy" _hover={{ textDecor: "none" }}>
                                            <Box textStyle="site.footerLink">Privacy policy</Box>
                                        </Link>
                                    </NextLink>
                                </Flex>
                            </AccordianItemWrapper>
                        </GridItem>
                    </Grid>
                </AccordianWrapper>
                <Flex
                    direction={isDesktop ? "row" : "column"}
                    w="100%"
                    gap={2}
                    justify="center"
                    align="center"
                    pt={12}
                    textStyle="site.gray.text"
                >
                    <Box>© {(new Date()).getFullYear()} Beyond Rarity.</Box>
                    <Box>All rights reserved.</Box>
                </Flex>
            </Flex>
        </>
    );
};

export const DynamicFooter = dynamic(() => import("./Footer").then((mod) => mod.Footer), {
    ssr: false,
});

export default DynamicFooter;
