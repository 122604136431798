import useCollectionTokensStore from "./collectionTokensStore";
import useFiltersStore, {
    hasTopFiltersApplied,
    hasTokenIdFiltersApplied,
    hasMarketplaceFilterApplied,
} from "./filtersStore";
import { isBetween } from "./helpers";

export const getTopFilteredTokens = (): Token[] => {
    const tokensList = useCollectionTokensStore.getState().tokensList;

    if (!hasTokenIdFiltersApplied() && !hasTopFiltersApplied()) {
        return tokensList;
    }

    const tokensMap = useCollectionTokensStore.getState().tokensMap;

    let filteredTokens: Token[] = tokensList;

    if (hasTokenIdFiltersApplied()) {
        const tokenStringIds = useFiltersStore.getState().tokenStringIds;
        filteredTokens = tokenStringIds.map((tokenStringId) => tokensMap.get(tokenStringId));
    }

    if (hasTopFiltersApplied()) {
        const { minRank, maxRank, minPrice, maxPrice, minDeviation, saleStatus } =
            useFiltersStore.getState();

        filteredTokens = filteredTokens.filter((token) => {
            return (
                isBetween(token.rank, minRank, maxRank) &&
                isBetween(token.currentPrice, minPrice, maxPrice) &&
                (minDeviation === null ||
                    // TODO: Not filtering well. Check token #857 and set miDeviation filter to 10. It should pass (the deviation for this token is 10) but it's excluded from the list.
                    Math.abs(token.priceProjection - token.currentPrice) > minDeviation) &&
                (saleStatus === "all" ? true : token.currentPrice > 0)
            );
        });
    }

    // console.info("Top filters applied in", timeSince(now));

    return filteredTokens;
};

export const getMarketplaceFilteredTokens = (tokensList: Token[]): Token[] => {
    const { marketplaces } = useFiltersStore.getState();

    let filteredTokens: Token[] = tokensList;

    if (marketplaces?.size > 0) {
        const marketplacesIds = [...marketplaces.keys()];

        filteredTokens = tokensList.filter(
            (token) => !!token.marketplace && marketplacesIds.includes(token.marketplace),
        );
    }

    return filteredTokens;
};
