import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Checkbox: ComponentStyleConfig = {
    baseStyle: {
        colorScheme: "gold",
        control: {
            bg: "steelblue.500",
            boxShadow:
                "inset 0px 1px 3px rgba(0, 0, 0, 0.5), inset 0px -12px 8px rgba(0, 0, 0, 0.115931), inset 0px -1px 0px rgba(255, 255, 255, 0.143357)",
            border: "base",
            _focus: {
                ring: 0,
            },
            _hover: {
                bg: "base",
            },
            _checked: {
                bg: "gold.500 !important",
                border: "2px solid #434A60",
                boxShadow: "none",
                _hover: {
                    bg: "gold.500 !important",
                    borderColor: "steelblue.500",
                },
                _before: {
                    color: "gold.500 !important",
                },
            },
            _active: {
                border: "2px solid #434A60",
                bg: "gold.500",
            },
        },
        label: {
            color: "gray.faint",
        },
    },
};

export default Checkbox;
