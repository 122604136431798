import { memo, useEffect, useState } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Grid,
    GridItem,
    Icon,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { BlockchainNetworkPricesDynamic } from "@components/ui/BlockchainNetworkPricesDynamic";
import HamburgerButton from "@components/ui/HamburgerButton";
import NextImage from "next/image";
import NextLink from "next/link";
import BRMonogram from "/public/images/br-mark-negative.png";
import HeaderRightMenu from "@components/site/layout/header/HeaderRightMenu";
import useSessionStore from "@store/sessionStore";
import BannerButton from "@components/banners/BannerButton";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { LinkIcon } from "@components/icons";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import SocialMediaIcons from "../SocialMediaIcons";
import TutorialsLinkButton from "@components/tutorials/TutorialsLinkButton";
import { RequestFeature } from "../../RequestFeature";
import { isDesktop } from "react-device-detect";
69;
import UserLogin from "@components/auth/UserLogin";

const SHARE_LINK = "https://www.beyondrarity.com";
const SHARE_MESSAGE = "I just found the best way to visualize NFT rarity and price estimates with ";

type MobileModalMenuProps = {};

export const MobileModalMenu = ({}: MobileModalMenuProps) => {
    // const displayBanner = useSessionStore((state) => state.displayBanner);
    const mobileMenuIsOpen = useSessionStore((state) => state.mobileMenuIsOpen);
    const [linkCopied, setLinkCopied] = useState(false);

    useEffect(() => {
        if (mobileMenuIsOpen) {
            document.documentElement.style.overflowY = "hidden";
        } else {
            document.documentElement.style.overflowY = "auto";
        }
    }, [mobileMenuIsOpen]);

    useEffect(() => {
        return onMobileModalMenuClose;
    }, []);

    const handleCopyLinkClick = (e) => {
        // console.debug("full url: ", fullUrl);
        navigator.clipboard.writeText(SHARE_LINK);
        setLinkCopied(true);
    };

    const onMobileModalMenuClose = () => {
        document.documentElement.style.overflowY = "auto";
        // useBasicStore.setState(() => ({ displayBannerExpanded: false }));
        useSessionStore.setState(() => ({ mobileMenuIsOpen: false }));
    };

    const AccordianItemWrapper = ({ label, children }) => {
        return (
            <>
                <AccordionItem border="none">
                    <AccordionButton _hover={{ bgColor: "none" }}>
                        <Flex w="100%" justify="center" align="center">
                            <AccordionIcon boxSize="8" />
                            <Box textStyle="site.gray.text">{label}</Box>
                        </Flex>
                    </AccordionButton>
                    <AccordionPanel pb={4}>{children}</AccordionPanel>
                </AccordionItem>
            </>
        );
    };

    const MemoBlockchainNetworkPricesDynamic = memo(BlockchainNetworkPricesDynamic);

    return (
        <>
            <Modal
                isOpen={mobileMenuIsOpen}
                onClose={onMobileModalMenuClose}
                size="full"
                motionPreset="none"
                blockScrollOnMount
            >
                <ModalContent
                    bgColor="spaceCadet"
                    boxShadow="none"
                    m={0}
                    p={0}
                    maxW="100vw"
                    maxH="100vh"
                    overflow="hidden"
                >
                    <ModalBody
                        m={0}
                        p={0}
                        w="100%"
                        overflow="auto"
                        sx={{
                            "&::-webkit-scrollbar": {
                                width: "0px",
                                display: "none",
                                WebkitAppearance: "none",
                            },
                        }}
                    >
                        <Flex w="100%" mt="10px" px={2}>
                            <MemoBlockchainNetworkPricesDynamic isDesktop={isDesktop} />
                        </Flex>
                        <Grid w="100%" templateColumns={"1fr auto 1fr"} p={4}>
                            <GridItem></GridItem>
                            <GridItem>
                                <Box
                                    maxW="156px"
                                    w="90vw"
                                    h={["50px", , , "55px"]}
                                    position="relative"
                                    cursor="pointer"
                                >
                                    <NextLink shallow href="/" passHref={true}>
                                        <Link
                                            href="/"
                                            onClick={() => {
                                                onMobileModalMenuClose();
                                            }}
                                        >
                                            <NextImage
                                                src={BRMonogram}
                                                layout="fill"
                                                priority
                                                alt="BeyondRarity"
                                            />
                                        </Link>
                                    </NextLink>
                                </Box>
                            </GridItem>
                            <GridItem>
                                <Flex direction="row" justify="end" gap={2}>
                                    <Flex h="100%" align="center" justify="end" mt="3px">
                                        <BannerButton />
                                    </Flex>
                                    <Flex justify="end">
                                        <HamburgerButton
                                            onClickHamburger={onMobileModalMenuClose}
                                            isOpen={mobileMenuIsOpen}
                                            invisible={false}
                                        />
                                    </Flex>
                                </Flex>
                            </GridItem>
                        </Grid>
                        <Flex
                            w="100%"
                            direction="column"
                            justify="center"
                            align="center"
                            gap={12}
                            pb={20}
                        >
                            <Box pt={4}>
                                <UserLogin isMobile onMobileModalMenuClose={onMobileModalMenuClose} />
                            </Box>
                            <Box>
                                <HeaderRightMenu isDesktop={false} onMenuClick={onMobileModalMenuClose} />
                            </Box>
                            <Flex
                                direction="column"
                                fontSize="18px"
                                fontWeight="light"
                                gap={4}
                                justify="center"
                                align="center"
                            >
                                <Tooltip
                                    // isDisabled={!linkCopied.current}
                                    isOpen={linkCopied}
                                    placement="top"
                                    label={
                                        <Text px={2} fontWeight="light">
                                            Copied
                                        </Text>
                                    }
                                    onClose={() => {
                                        setLinkCopied(false);
                                    }}
                                >
                                    <Flex gap={2} onClick={handleCopyLinkClick}>
                                        <Icon as={LinkIcon} w="20px" h="20px" />
                                        <Box>Copy link</Box>
                                    </Flex>
                                </Tooltip>
                                <FacebookShareButton
                                    url={SHARE_LINK}
                                    quote={`${SHARE_MESSAGE}${"@BeyondRarityNFT"}`}
                                >
                                    <Flex gap={2}>
                                        <Icon as={FaFacebook} w="20px" h="20px" />
                                        <Box>Share on Facebook</Box>
                                    </Flex>
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={SHARE_LINK}
                                    title={`${SHARE_MESSAGE}${"@beyond_rarity"}`}
                                    related={["Beyond_Rarity"]}
                                >
                                    <Flex gap={2}>
                                        <Icon as={FaTwitter} w="20px" h="20px" />
                                        <Box>Share on Twitter</Box>
                                    </Flex>
                                </TwitterShareButton>
                            </Flex>

                            <SocialMediaIcons />

                            <Accordion mt={-8} border="none" allowToggle>
                                <Grid w="100%" gap={0}>
                                    <GridItem>
                                        <AccordianItemWrapper label="SITEMAP">
                                            <Flex
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                gap={2}
                                            >
                                                <NextLink shallow href="/" passHref>
                                                    <Link
                                                        href="/"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">Home</Box>
                                                    </Link>
                                                </NextLink>
                                                {/* <NextLink shallow href="/blog" passHref>
                                                    <Link
                                                        href="/blog"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">Blog</Box>
                                                    </Link>
                                                </NextLink> */}
                                                <NextLink shallow href="/about" passHref>
                                                    <Link
                                                        href="/about"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">About</Box>
                                                    </Link>
                                                </NextLink>

                                                <NextLink
                                                    shallow
                                                    href="mailto:marketing@beyondrarity.com"
                                                    passHref
                                                >
                                                    <Link
                                                        href="mailto:marketing@beyondrarity.com"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Affiliates
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                            </Flex>
                                        </AccordianItemWrapper>
                                    </GridItem>

                                    <GridItem>
                                        <AccordianItemWrapper label="APP">
                                            <Flex
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                gap={2}
                                            >
                                                <NextLink shallow href="/app" passHref>
                                                    <Link
                                                        href="/app"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            View App
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                                <Box textStyle="site.footerLink">
                                                    <TutorialsLinkButton />
                                                </Box>
                                                <NextLink shallow href="/getlisted" passHref>
                                                    <Link
                                                        href="/getlisted"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Get listed
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                                <Box textStyle="site.footerLink">
                                                    <RequestFeature>Request feature</RequestFeature>
                                                </Box>
                                                <NextLink shallow href="/pricing" passHref>
                                                    <Link
                                                        href="/pricing"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Pricing
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                            </Flex>
                                        </AccordianItemWrapper>
                                    </GridItem>

                                    <GridItem>
                                        <AccordianItemWrapper label="COMPANY">
                                            <Flex
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                gap={2}
                                            >
                                                <NextLink shallow href="/media" passHref>
                                                    <Link
                                                        href="/media"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Media kit
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                                <NextLink shallow href="/contact" passHref>
                                                    <Link
                                                        href="/contact"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Contact
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                                <NextLink shallow href="/careers" passHref>
                                                    <Link
                                                        href="/careers"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Careers
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                                <NextLink shallow href="/terms" passHref>
                                                    <Link
                                                        href="/terms"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Term of use
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                                <NextLink shallow href="/privacy" passHref>
                                                    <Link
                                                        href="/privacy"
                                                        _hover={{ textDecor: "none" }}
                                                        onClick={onMobileModalMenuClose}
                                                    >
                                                        <Box textStyle="site.footerLink">
                                                            Privacy policy
                                                        </Box>
                                                    </Link>
                                                </NextLink>
                                            </Flex>
                                        </AccordianItemWrapper>
                                    </GridItem>
                                </Grid>
                            </Accordion>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default MobileModalMenu;
