import { useWindowSiteStore } from "@store/windowSiteStore";
import DesktopHeader from "./header/DesktopHeader";
import MobileHeader from "./header/MobileHeader";
import MobileModalMenu from "./header/MobileModalMenu";

const SHARE_LINK = "https://www.beyondrarity.com";

export const Header = () => {
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);

    if (isDesktop) {
        return <DesktopHeader />;
    }

    // Mobile
    return (
        <>
            {/* <MobileHeader /> */}
            <MobileHeader />
            <MobileModalMenu />
        </>
    );
};

export default Header;
