import create from "zustand";
import { subscribeWithSelector, persist } from "zustand/middleware";
import {
    AUTH_SORT_OPTIONS,
    GUEST_SORT_OPTIONS,
    AUTH_DEFAULT_SORT_CRITERIA,
    AUTH_DEFAULT_SORT_ORDER,
    GUEST_DEFAULT_SORT_CRITERIA,
    GUEST_DEFAULT_SORT_ORDER,
} from "@config/app";

type SessionState = {
    mobileTokenItemExpanded: boolean;
    // Quick hack: connected === 0 means that the user is connected but BriteLine is hidden
    connected: false | true | 0;
    traitSortColumn: number;
    traitSortOrder: SortOrder;
    vibeSortColumn: number;
    vibeSortOrder: SortOrder;
    quickTourIsOpen: boolean;
    displayBanner: boolean;
    displayBannerCount: number;
    mobileMenuIsOpen: boolean;
    viewAppUrl?: string;
};

const defaultSessionState: SessionState = {
    mobileTokenItemExpanded: false,
    connected: true,
    traitSortColumn: 1,
    traitSortOrder: "asc",
    vibeSortColumn: 1,
    vibeSortOrder: "asc",
    quickTourIsOpen: false,
    displayBanner: true,
    displayBannerCount: 0,
    mobileMenuIsOpen: false,
    viewAppUrl: null,
};

// Super hacky: Keep this value in a variable to restore it when a user connects from the CONNECT btn.
let lastBritelineVisibility: boolean = true;

// Store for Settings
export const useSessionStore = create<SessionState>()(
    persist(
        subscribeWithSelector(() => defaultSessionState),
        {
            name: "beyond-rarity",
            getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
        },
    ),
);

export const setMobileTokenItemExpanded = (isExpanded: boolean) => {
    useSessionStore.setState(() => ({ mobileTokenItemExpanded: isExpanded }));
};

export const toggleMobileTokenItemExpanded = () => {
    useSessionStore.setState((oldState) => ({
        mobileTokenItemExpanded: !oldState.mobileTokenItemExpanded,
    }));
};

export const setConnected = (isConnected: boolean) => {
    useSessionStore.setState(() => ({ connected: isConnected }));
};

//
export const setBritelineVisibility = (visible: boolean) => {
    lastBritelineVisibility = visible;
    const connected = useSessionStore.getState().connected;

    if (connected && !visible) {
        useSessionStore.setState((state) => ({ connected: 0 }));
    } else if (connected === 0 && visible) {
        useSessionStore.setState((state) => ({ connected: true }));
    }
};

export const toggleConnected = () => {
    const isConnected = useSessionStore.getState().connected;

    if (isConnected === true || isConnected === 0) {
        useSessionStore.setState((oldState) => ({ connected: false }));
    } else if (isConnected === false) {
        const newConnectedValue = lastBritelineVisibility ? true : 0;
        useSessionStore.setState((oldState) => ({ connected: newConnectedValue }));
    }
};

export const getSortOptions = (): RadioButtonProps[] => {
    const { connected } = useSessionStore.getState();
    return connected ? AUTH_SORT_OPTIONS : GUEST_SORT_OPTIONS;
};

export const getDefaultSortOptions = () => {
    const { connected } = useSessionStore.getState();
    return connected
        ? { sortCriteria: AUTH_DEFAULT_SORT_CRITERIA, sortOrder: AUTH_DEFAULT_SORT_ORDER }
        : { sortCriteria: GUEST_DEFAULT_SORT_CRITERIA, sortOrder: GUEST_DEFAULT_SORT_ORDER };
};

export const setQuickTourIsOpen = (isOpen: boolean) => {
    useSessionStore.setState(() => ({ quickTourIsOpen: isOpen }));
};

export default useSessionStore;
