import { AUTH_API_URL } from "@config/app";

/**
 * Rebuilds the session object after a user document is updated in the database.
 * REF: https://stackoverflow.com/questions/70405436/next-auth-how-to-update-the-session-client-side
 */
export const reloadSession = async () => {
    await fetch("/api/auth/session?update");

    // To refresh the client side session we need to fire a visibilitychange event
    if (document) {
        const event = new Event("visibilitychange");
        document.dispatchEvent(event);
    }
};

export const getUserAccessToken = async (user): Promise<string> => {
    // Asks for an API JWT with two fields: userId and email
    const body = { userId: user.id, email: user.email };

    // Gets our own API JWT for authentication
    const response = await fetch(`${AUTH_API_URL}/auth/login`, {
        method: "POST",
        headers: {
            "x-api-key": process.env.AUTH_API_KEY,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    });

    const data = await response.json();
    const { accessToken } = data;

    return accessToken;
};

export const getAddressTokens = async (
    address: string,
    next?: string,
    abortController?: AbortController,
) => {
    const fetchOptions: Partial<RequestInit> = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    if (abortController) {
        fetchOptions.signal = abortController.signal;
    }

    const cursor = next ? `?cursor=${next}` : "";
    const ENDPOINT = `${AUTH_API_URL}/user/tokens/${address}${cursor}`;

    try {
        const response = await fetch(ENDPOINT, fetchOptions);

        if (!response.ok) {
            return {
                error: "Failed to fetch.",
                tokens: null,
            };
        } else {
            const data = await response.json();

            return {
                error: data.error,
                tokens: data.tokens,
                pageTotalFloorPrice: data?.pageTotalFloorPrice,
                floorPriceErrorCount: data?.floorPriceErrorCount || 0,
                next: data?.next,
            };
        }
    } catch (e) {
        return {
            error: "Failed to fetch.",
            tokens: null,
        };
    }
};
