import { Box, Icon, SystemStyleObject } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight, FiCheck } from "react-icons/fi";

type NavArrowProps = {
    side: "right" | "left";
    disabled?: boolean;
    completed?: boolean;
    onClick: () => void;
};

const IDLE_STYLE: SystemStyleObject = {
    color: "white",
    opacity: 1,
    transition: "all .25s ease-out",
};

const TOUCHED_STYLE: SystemStyleObject = {
    color: "gold.500",
    opacity: 1,
    transition: "all .25s ease-out",
};

const DISABLED_STYLE: SystemStyleObject = {
    color: "arsenicLight",
    opacity: 0,
    transition: "all .25s ease-out",
};

const HOVER_STYLE: SystemStyleObject = {
    color: "gold.500",
    opacity: 1,
};

const NavArrow = ({ side, disabled, completed, onClick }: NavArrowProps): JSX.Element => {
    const btn = useRef<HTMLDivElement>();
    const preventHover = useRef<boolean>(false);
    const touchEnded = useRef<boolean>(false);
    const [btnStyle, setBtnStyle] = useState<SystemStyleObject>(() => IDLE_STYLE);

    useEffect(() => {
        if (disabled) {
            if (btn.current) btn.current.style.pointerEvents = "none";
            setBtnStyle(DISABLED_STYLE);
        } else {
            if (btn.current) btn.current.style.pointerEvents = "all";
            setBtnStyle(IDLE_STYLE);
        }
    }, [disabled]);

    const onWindowTouchEnd = () => {
        window.removeEventListener("touchend", onWindowTouchEnd);
        preventHover.current = false;
        touchEnded.current = true;
        setBtnStyle(IDLE_STYLE);
    };

    const onTouchStart = () => {
        preventHover.current = true;
        touchEnded.current = false;

        if (typeof window !== undefined) window.addEventListener("touchend", onWindowTouchEnd);
        setBtnStyle(TOUCHED_STYLE);
    };

    const onMouseOut = () => {
        if (preventHover.current) return;
        preventHover.current = false;
        if (!disabled) setBtnStyle(IDLE_STYLE);
    };

    const onHover = () => {
        if (preventHover.current || touchEnded.current) return;
        setBtnStyle(HOVER_STYLE);
    };

    const onPressed = () => {
        onClick?.();
        if (preventHover.current || touchEnded.current) return;
        setBtnStyle(TOUCHED_STYLE);
    };

    let icon = side === "left" ? FiChevronLeft : FiChevronRight;
    if (completed) icon = FiCheck;

    return (
        <Box
            ref={btn}
            cursor="pointer"
            transition="all .25s ease-out"
            sx={btnStyle}
            onClick={onPressed}
            onTouchStart={onTouchStart}
            onTouchEnd={onMouseOut}
            onMouseOver={onHover}
            onMouseOut={onMouseOut}
            onMouseLeave={onMouseOut}
            pointerEvents={!disabled ? "all" : "none"}
            display="inline-block"
        >
            <Icon as={icon} fontSize={{ base: "40px", sm2: !completed ? "80px" : "74px" }} />
        </Box>
    );
};

export default NavArrow;
