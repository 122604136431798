import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Input: ComponentStyleConfig = {
    baseStyle: {
        field: {
            fontWeight: 500,
            color: "white",
            borderRadius: "base",
            _autofill: {
                caretColor: "white",
                textFillColor: "#fff",
                boxShadow: "0 0 0px 1000px #31384F inset",
                transition: "background-color 5000s ease-in-out 0s",
            },
        },
    },

    sizes: {
        sm: {
            field: {
                fontSize: "12px",
                px: "10px",
                height: "32px",
                maxH: "32px",
            },
        },
    },

    defaultProps: {
        size: "sm",
    },
};

export default Input;
