import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { useWindowSiteStore } from "@store/windowSiteStore";

type FullOnMobileProps = {
    containerProps?: FlexProps;
    children?: React.ReactNode;
};

export const FullOnMobile = ({
    containerProps,
    children,
    ...props
}: FullOnMobileProps & FlexProps): FullOnMobileType => {
    // const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: { basisType: "site" } });
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);

    return (
        <>
            <Flex
                position="relative"
                direction="column"
                w="100%"
                justify="center"
                align="center"
                {...props}
            >
                <Flex
                    position="relative"
                    direction="column"
                    w="100%"
                    maxW="1100px"
                    justify="center"
                    align="center"
                    {...containerProps}
                >
                    {children}
                </Flex>
            </Flex>
        </>
    );
};

export default FullOnMobile;
