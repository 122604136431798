import { Flex, Spacer } from "@chakra-ui/react";
import SocialShare from "@components/socialshare/SocialShare";
import { ReactNode, useEffect, useState } from "react";
import { Header, Footer } from "@components/site/layout";
import { useWindowSiteStore } from "@store/windowSiteStore";
import useSessionStore from "@store/sessionStore";

type SiteLayoutProps = {
    children: ReactNode;
};

const SiteLayout = ({ children }: SiteLayoutProps): JSX.Element => {
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const isDesktop = useWindowSiteStore((state) => state.isDesktop);
    const displayBanner = useSessionStore((state) => state.displayBanner);

    useEffect(() => {
        if (!isMounted) setIsMounted(true);
    }, []);

    if (!isMounted) return null;

    return (
        <>
            <SocialShare />
            <Flex
                pos="relative"
                direction="column"
                w="100%"
                minH="100vh"
                justify="center"
                align="center"
                overflowX="hidden"
            >
                {/* <MainBackground /> */}
                <Header />
                <Flex
                    position="relative"
                    direction="column"
                    w="100%"
                    justify="center"
                    align="center"
                    gap={isDesktop ? "100px" : "75px"}
                    py={isDesktop ? "100px" : "75px"}
                    pt={isDesktop ? (displayBanner ? "160px" : "100px") : "20px"}
                >
                    {children}
                </Flex>
                <Spacer />
                <Footer />
            </Flex>
        </>
    );
};

export default SiteLayout;
