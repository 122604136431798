import { API } from "@config/app";
import { getPathInfo } from "@utils";
import axios from "axios";

const activeFetchControllers: Map<string, AbortController> = new Map();
const cancelAndRemoveFetchFor = (fetchId: string): void => {
    if (activeFetchControllers.has(fetchId)) {
        const fetchAbortController = activeFetchControllers.get(fetchId);
        if (!fetchAbortController.signal.aborted) fetchAbortController.abort();
        activeFetchControllers.delete(fetchId);
    }
};

export const cancellableFetch = async (
    url: string,
    fetchId: string,
    isStage?: boolean,
): Promise<Response> => {
    let internalIsStage = isStage;

    if (internalIsStage == null) {
        const { isStage: isStageFromWindow } = getPathInfo();
        internalIsStage = isStageFromWindow;
    }

    const apiPath = `${API.DOMAIN}${internalIsStage ? "/stage" : ""}${API.PATH}`;
    cancelAndRemoveFetchFor(fetchId);

    const newFetchAbortController = new AbortController();
    activeFetchControllers.set(fetchId, newFetchAbortController);

    try {
        // Removes all starting slashes
        const cleanURL = url.replace(/^\/+/g, "");

        const apiURL = `${apiPath}/${cleanURL}`;

        const response: Response = await fetch(apiURL, {
            signal: newFetchAbortController.signal,
        });

        // console.debug("cancellableFetch.isStage: ", isStage);
        // console.debug("cancellableFetch.internalIsStage: ", internalIsStage);
        // console.debug("cancellableFetch.apiPath: ", apiPath);
        // console.debug("cancellableFetch.apiURL: ", apiURL);
        // // console.debug("cancellableFetch.response: ", response);

        return response;
    } catch (e: any) {
        cancelAndRemoveFetchFor(fetchId);
        throw e;
    }
};

/**
 * Retrieves the basic social share data for a collection
 */
export const getCollectionSocialShareData = async (
    collectionId: string,
    isStage: boolean,
): Promise<CollectionSocialShareData> => {
    const apiPath = `${API.DOMAIN}${isStage ? "/stage" : ""}${API.PATH}`;
    const apiUrl = `${apiPath}/collections/${collectionId}/social-share`;
    const fetchResult = await axios.get(apiUrl);

    // console.debug("getCollectionSocialShareData.isStage: ", isStage);
    // console.debug("getCollectionSocialShareData.apiPath: ", apiPath);
    // console.debug("getCollectionSocialShareData.apiUrl: ", apiUrl);
    // // console.debug("getCollectionSocialShareData.fetchResult: ", fetchResult);

    if (fetchResult.data == "" || fetchResult.status != 200) return null;

    return fetchResult.data;
};

/**
 * Gets all collections data for the collections search bar.
 */
export const getAllCollections = async (isStage: boolean): Promise<AllCollectionsData> => {
    const response: Response = await cancellableFetch(
        `/collections/all`,
        "collections.all",
        isStage,
    );
    const data: AllCollectionsData = await response.json();

    return data;
};

/**
 * Gets the basic collection info: name, supply, owners, floor price, volume, links, etc.
 */
export const getCollectionData = async (collectionId: string): Promise<CollectionData> => {
    const response: Response = await cancellableFetch(
        `/collections/${collectionId}/info`,
        "collection.info",
    );
    const data: CollectionData = await response.json();
    return data;
};

export const getBriteLine = async (collectionId: string): Promise<FlatBriteLineDataList> => {
    const response: Response = await cancellableFetch(
        `/collections/${collectionId}/projectionLine`,
        "collection.projectionLine",
    );
    const data = await response.json();
    return data;
};

export const getListedTokens = async (collectionId: string): Promise<[FlatToken[], string]> => {
    const response: Response = await cancellableFetch(
        `/collections/${collectionId}/tokens/listed`,
        "collection.listedTokens",
    );
    const data = await response.json();
    return [data.tokens, data.lastListingsUpdate];
};

export const getUnlistedTokens = async (collectionId: string): Promise<FlatToken[]> => {
    const response: Response = await cancellableFetch(
        `/collections/${collectionId}/tokens/unlisted`,
        "collection.unlistedTokens",
    );
    const data = await response.json();
    return data;
};

export const getTokenData = async (
    collectionId: string,
    tokenId: TokenId | string,
    isStage: boolean,
): Promise<Token> => {
    if (!tokenId) return null;
    const apiPath = `${API.DOMAIN}${isStage ? "/stage" : ""}${API.PATH}`;
    const apiUrl = `${apiPath}/tokens/${collectionId}/${tokenId}`;

    try {
        const fetchResult = await axios.get(apiUrl);

        // console.debug("getCollectionSocialShareData.isStage: ", isStage);
        // console.debug("getCollectionSocialShareData.apiPath: ", apiPath);
        // console.debug("getCollectionSocialShareData.apiUrl: ", apiUrl);
        // // console.debug("getCollectionSocialShareData.fetchResult: ", fetchResult);

        if (fetchResult.data == "" || fetchResult.status != 200) return null;
        const tokenResult = fetchResult.data;

        return {
            tokenStringId: tokenResult[0],
            tokenId: +tokenResult[0],
            name: tokenResult[1],
            rank: tokenResult[2],
            currentPrice: tokenResult[3],
            deviation: tokenResult[3] ? tokenResult[4] - tokenResult[3] : null,
            priceProjection: tokenResult[4],
            confidence: tokenResult[5] * 100, // Raw data is decimal from 0 to 1, converting to percentage
            imageUrl: tokenResult[6],
            animationUrl: tokenResult[7],
            traits: tokenResult[8],
            vibes: tokenResult[9],
            marketplace: +tokenResult[10],
        };
    } catch (e: any) {
        return null;
    }
};

/**
 * posts contact form values to mailchimp and returns response
 * @param values values from submit contact form
 * @returns response from mailchimp
 */
export const postContactForm = async (values: ContactFormValues): Promise<string> => {
    // const contactFormUrl = "https://us17.list-manage.com/contact-form/post?u=d3dbffdde7ea064448fe70fda&form_id=8fd64fd5933be42d2a804d119d3f5078";
    const contactFormUrl =
        "https://us17.list-manage.com/contact-form/post?u=d3dbffdde7ea064448fe70fda&form_id=84736b4bbf47b97c9ff01d468e19d54b";

    const axiosResult = await axios.post(contactFormUrl, values);

    return JSON.stringify(axiosResult.data);
};
