import {
    Box,
    Flex,
    HStack,
    Icon,
    IconButton,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    VStack,
} from "@chakra-ui/react";
import useUser from "@hooks/useUser";
import NextLink from "next/link";
import { FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { MemoSignedInUserAvatar } from "./SignedInUserAvatar";

type UserMenuButtonProps = {
    buttonSize: string;
    isMobile?: boolean;
    onMobileModalMenuClose?: Function;
};

export const UserMenuButton = ({
    buttonSize,
    isMobile,
    onMobileModalMenuClose,
}: UserMenuButtonProps) => {
    const { user, signOut } = useUser();

    const onMobileNavigate = (e) => {
        onMobileModalMenuClose();
    };

    const onMobileSignOut = () => {
        signOut();
        onMobileModalMenuClose();
    };

    if (isMobile) {
        return (
            <>
                <VStack fontFamily="regular" fontSize="20px" gap={1}>
                    <MemoSignedInUserAvatar
                        name={user.name}
                        image={user.image}
                        buttonSize={buttonSize}
                        isMobile={isMobile}
                    />
                    <Flex direction="column" w="100%" justify="center" align="center">
                        <Box fontWeight="semibold">{user.name}</Box>
                        <Box fontSize=".8em">{user.username}</Box>
                    </Flex>
                    <NextLink shallow href="/user/me">
                        <Link href="/" _hover={{ textDecor: "none", cursor: "pointer" }}>
                            <HStack pt={4} onClick={onMobileNavigate}>
                                <Icon as={FaUserCircle} />
                                <Box>View Profile</Box>
                            </HStack>
                        </Link>
                    </NextLink>
                    <HStack onClick={onMobileSignOut}>
                        <Icon as={FaSignOutAlt} />
                        <Box>Sign Out</Box>
                    </HStack>
                </VStack>
            </>
        );
    }

    return (
        <>
            <Menu placement="bottom-end">
                <MenuButton
                    as={IconButton}
                    aria-label="My Profile"
                    icon={
                        <MemoSignedInUserAvatar
                            name={user.name}
                            image={user.image}
                            buttonSize={buttonSize}
                            isMobile={isMobile}
                        />
                    }
                    variant="none"
                    minH={buttonSize}
                    minW={buttonSize}
                />
                <MenuList color="spaceCadetLight" minW={0} fontFamily="regular">
                    <Flex direction="column" w="100%" justify="center" align="center">
                        <Box fontWeight="semibold">{user.name}</Box>
                        <Box fontSize=".8em">{user.username}</Box>
                    </Flex>
                    <MenuDivider />
                    <NextLink shallow href="/user/me">
                        <Link href="/" _hover={{ textDecor: "none", cursor: "pointer" }}>
                            <MenuItem as="a" icon={<FaUserCircle />}>
                                View Profile
                            </MenuItem>
                        </Link>
                    </NextLink>
                    <MenuDivider />
                    <MenuItem icon={<FaSignOutAlt />} onClick={signOut}>
                        Sign Out
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};

export default UserMenuButton;
