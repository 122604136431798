import { useEffect, useRef } from "react";
import { isIOS } from "react-device-detect";

// hack to disable PINCH + ZOOM on Safari mobile
const useSafariMobilePinchZoom = () => {
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        if (!isIOS) return;

        const disablePinchZoom = (event: SafariTouchEvent) => {
            if (event.scale && event.scale !== 1) {
                event.preventDefault();
            }
        };

        if (!isMounted.current) {
            isMounted.current = true;
            document.addEventListener("touchmove", disablePinchZoom, { passive: false });
        }

        return () => {
            document.removeEventListener("touchmove", disablePinchZoom);
        };
    }, []);
};

export default useSafariMobilePinchZoom;
