import React, { useEffect, useState } from "react";
import { Box, Center, Spinner, Icon } from "@chakra-ui/react";
import { BsFillPlayFill } from "react-icons/bs";
import Image from "next/image";

type TutorialListItemProps = {
    videoId?: string;
    onPlayVideo?: (string) => void;
    source?: "vimeo" | "youtube";
};

type VideoData = {
    thumbURL: string;
    title: string;
    videoId: string | number;
};

const TutorialListItem = ({
    videoId,
    onPlayVideo,
    source = "youtube",
}: TutorialListItemProps): JSX.Element => {
    const [error, setError] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [videoData, setVideoData] = useState<VideoData>();

    useEffect(() => {
        const getVideoThumbnail = async () => {
            try {
                const vimeoUrl = `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}&responsive=true&width=960&height=540`;
                const youtubeUrl = `https://youtube.com/oembed?url=http://www.youtube.com/watch?v=${videoId}&format=json`;
                const oEmbedDataUrl = source == "vimeo" ? vimeoUrl : youtubeUrl;
                const response = await fetch(oEmbedDataUrl);
                const data = await response.json();

                setVideoData({
                    thumbURL: data.thumbnail_url,
                    title: data.title,
                    videoId: data.videoId,
                });
            } catch (e: any) {
                setError(true);
            }
        };

        if (!videoData && videoId) getVideoThumbnail();
    }, []);

    const onThumbnailClick = () => {
        if (videoId) onPlayVideo(videoId);
    };

    const onThumbLoaded = () => {
        setImageLoaded(true);
    };

    const renderPlayButton = () => (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" p="20px">
            <Icon
                as={BsFillPlayFill}
                boxSize={16}
                color="#fff"
                opacity={0.75}
                filter="drop-shadow(0px 0px 6px #000)"
            />
        </Box>
    );

    const renderContent = () => {
        if (!videoId) {
            return (
                <Center w="100%" h="100%" color="rythm" fontSize="20px" fontWeight={300}>
                    Coming soon
                </Center>
            );
        }

        if (error) {
            return (
                <Center w="100%" h="100%" color="rythm" fontSize="20px" fontWeight={300}>
                    Couldn&apos;t load video!
                </Center>
            );
        }

        if (!error && !videoData) return null;

        return (
            <Box
                position="relative"
                w="100%"
                h="100%"
                opacity={imageLoaded ? 1 : 0}
                transition="opacity 0.25s"
            >
                <Image
                    src={videoData.thumbURL}
                    objectFit="cover"
                    layout="fill"
                    alt={videoData.title}
                    title={videoData.title}
                    draggable={false}
                    onLoadingComplete={onThumbLoaded}
                />
                {imageLoaded && renderPlayButton()}
            </Box>
        );
    };

    const showSpinner = !!videoId && !imageLoaded && !error;

    return (
        <Box
            position="relative"
            style={{ aspectRatio: 16 / 9 }}
            bg="spaceCadet"
            rounded="6px"
            overflow="hidden"
            transition="opacity .25s"
            cursor={videoData ? "pointer" : "default"}
            onClick={onThumbnailClick}
        >
            {showSpinner && (
                <Center
                    position="absolute"
                    w="100%"
                    h="100%"
                    color="rythm"
                    fontSize="20px"
                    fontWeight={300}
                >
                    <Spinner />
                </Center>
            )}
            {renderContent()}
        </Box>
    );
};

export default TutorialListItem;
