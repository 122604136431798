import {
    buildSortedTokensListFromTokensMap,
    sortTokens,
    findPricesRange,
    findProjectionsRange,
    findPriceEdges,
    findDeviationsRange,
} from "./helpers";
import useCollectionTokensStore, { buildFilteredTraitsList } from "./collectionTokensStore";
import { getTopFilteredTokens } from "./filterHelpers";
import { getFilteredTokensTraitsMap, getTraitsFilteredTokens } from "./traitsHelpers";
import useFiltersStore from "./filtersStore";
import { getVibesFilteredTokens } from "./vibesHelpers";

export const buildTokensListFromTokenIdsList = (tokenIds: TokenStringId[] | null): Token[] => {
    if (!tokenIds) return [];

    const tokensMap = useCollectionTokensStore.getState().tokensMap;
    const tokens: Token[] = tokenIds.map((tokenStringId) => tokensMap.get(tokenStringId));

    return tokens;
};

export const rebuildTokensData = (tokensMap: TokensMap) => {
    // builds the tokens list sorted by RANK
    const rankSortedTokensList: Token[] = buildSortedTokensListFromTokensMap(tokensMap);

    // searchs for the highest an lowest priced tokens inside a collection
    const { highestPrice, lowestPrice } = findPricesRange(tokensMap);

    // searchs for the highest an lowest price deviations inside a collection
    const { highestDeviation, lowestDeviation } = findDeviationsRange(tokensMap);

    // searchs for the highest an lowest price projections inside a collection
    const { lowestProjection, highestProjection } = findProjectionsRange(tokensMap);

    // searchs for the lowest and highest 10% valued priced tokens
    const { lowest10PctPrices, highest10PctPrices } = findPriceEdges(rankSortedTokensList);

    // First let's get the top filtered tokens: saleStatus, rank, price, minDeviation and tokenIds list.
    let topFilteredTokens: Token[] = getTopFilteredTokens();

    // Once we have the top filtered list, let's build a traits map only for these filtered tokens.
    // We need to get this list BEFORE applying any traits or vibes filters because the traits and
    // vibes filters list are faceted.
    let topFilteredTraitsMap: TraitsMap = getFilteredTokensTraitsMap(topFilteredTokens);

    // Now let's create the final filtered tokens list. Let's start with the top filtered tokens.
    let filteredTokens: Token[] = [...topFilteredTokens];

    const traits = useFiltersStore.getState().traits;
    const vibes = useFiltersStore.getState().vibes;

    // Now let's apply the traits & vibes filters, if needed
    const traitsFilteredTokenIds: TokenStringId[] = getTraitsFilteredTokens(traits);
    const vibesFilteredTokenIds: TokenStringId[] = getVibesFilteredTokens(vibes);

    if (traitsFilteredTokenIds?.length || vibesFilteredTokenIds?.length) {
        let groupedFilteredTokens = [topFilteredTokens];

        if (traitsFilteredTokenIds?.length)
            groupedFilteredTokens.push(buildTokensListFromTokenIdsList(traitsFilteredTokenIds));

        if (vibesFilteredTokenIds?.length)
            groupedFilteredTokens.push(buildTokensListFromTokenIdsList(vibesFilteredTokenIds));

        filteredTokens = groupedFilteredTokens.reduce((a, b) => a.filter((c) => b.includes(c)));
    }

    // Finally let's sort the tokens list by criteria
    const sortBy: SortCriteria = useFiltersStore.getState().sortBy;
    const sortOrder: SortOrder = useFiltersStore.getState().sortOrder;
    const filteredSortedTokens = [...filteredTokens].sort((a: Token, b: Token) =>
        sortTokens(a, b, sortBy, sortOrder),
    );

    const filteredSortedPrevNextMap = new Map();
    filteredSortedTokens.forEach((token, index) => {
        const prevNextTokens: PrevNextTokens = {
            prevToken: index - 1 >= 0 ? filteredSortedTokens[index - 1] : null,
            nextToken:
                index + 1 < filteredSortedTokens.length
                    ? filteredSortedTokens[index + 1]
                    : null,
        };
        filteredSortedPrevNextMap.set(token.tokenStringId, prevNextTokens);
    });

    // As last step we need to rebuild the faceted TraitsMap
    const filteredTraitsMap = buildFilteredTraitsList(topFilteredTokens, topFilteredTraitsMap);

    return {
        rankSortedTokensList,
        highestPrice,
        lowestPrice,
        highestDeviation,
        lowestDeviation,
        highestProjection,
        lowestProjection,
        lowest10PctPrices,
        highest10PctPrices,
        filteredTokens,
        filteredSortedTokens,
        filteredSortedPrevNextMap,
        filteredTraitsMap,
    };
};
