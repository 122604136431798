import { Box, Collapse, Flex, Link, useDisclosure } from "@chakra-ui/react";
import UserLogin from "@components/auth/UserLogin";
import CallingNFTBanner from "@components/banners/CallingNFTBanner";
import { BlockchainNetworkPricesDynamic } from "@components/ui/BlockchainNetworkPricesDynamic";
import { useScrollPosition } from "@hooks/useScroll";
import { ScrollDirection, useScrollDirection } from "@hooks/useScrollDirection";
import useSessionStore from "@store/sessionStore";
import chakraTheme from "@theme";
import NextImage from "next/image";
import NextLink from "next/link";
import { memo } from "react";
import HeaderRightMenu from "./HeaderRightMenu";
import BRMonogram from "/public/images/br-mark-negative.png";

type DesktopHeaderProps = {};

export const DesktopHeader = ({}: DesktopHeaderProps) => {
    const { isOpen, onClose: onModalClose } = useDisclosure();

    const scrollPosition = useScrollPosition();
    const scrollDirecton = useScrollDirection();

    const collapseTop = scrollPosition && scrollDirecton != ScrollDirection.up;

    const backdropFilter = collapseTop ? "blur(10px)" : "initial";
    const bgStyle = {
        transition: "all .5s",
        background: scrollPosition
            ? chakraTheme.colors.site.headerAlpha
            : chakraTheme.colors.site.header,
        backdropFilter: backdropFilter,
    };

    const onClose = () => {
        useSessionStore.setState((oldState) => ({ mobileMenuIsOpen: false }));
    };

    const MemoBlockchainNetworkPricesDynamic = memo(BlockchainNetworkPricesDynamic);

    return (
        <>
            <Box minH="118px" />
            <Flex
                direction="column"
                as="header"
                style={bgStyle}
                position="fixed"
                zIndex="sticky"
                w="100%"
                top={0}
                justifyContent="center"
                alignItems="center"
            >
                <CallingNFTBanner useReopen />

                <Flex w="100vw" maxW={1200} h="100%" px="50px">
                    <Flex
                        direction="row"
                        w="100%"
                        h="100%"
                        justify="space-between"
                        align="end"
                        my={4}
                    >
                        <Box w="156px" h="50px" position="relative" cursor="pointer">
                            <NextLink shallow href="/" passHref={true}>
                                <Link href="/" _hover={{ textDecor: "none" }}>
                                    <Box onClick={onClose}>
                                        <NextImage
                                            src={BRMonogram}
                                            layout="fill"
                                            priority
                                            alt="BeyondRarity"
                                        />
                                    </Box>
                                </Link>
                            </NextLink>
                        </Box>

                        <Flex h="100%">
                            <Flex h="100%" direction="column" align="end">
                                <Flex zIndex={1}>
                                    <Collapse
                                        in={!collapseTop}
                                        transition={
                                            !scrollPosition
                                                ? {
                                                      enter: { duration: 0 },
                                                  }
                                                : undefined
                                        }
                                    >
                                        <Flex
                                            direction="row"
                                            pb={2}
                                            gap={4}
                                            justify="center"
                                            align="center"
                                        >
                                            <MemoBlockchainNetworkPricesDynamic isDesktop />

                                            <UserLogin />
                                        </Flex>
                                    </Collapse>
                                </Flex>

                                <Flex h="50px">
                                    <HeaderRightMenu isDesktop={true} />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

export default DesktopHeader;
