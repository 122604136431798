import { Box, FlexProps } from "@chakra-ui/react";
import GradientButton from "@components/ui/GradientButton";
import { SignInModalState, useBasicStore } from "@store/basicStore";

type UserSignInButtonProps = {
    isMobile?: boolean;
    onMobileModalMenuClose?: Function;
    buttonStyle?: FlexProps;
};

export const UserSignInButton = ({
    isMobile,
    onMobileModalMenuClose,
    buttonStyle,
}: UserSignInButtonProps) => {
    const openSignInModal = (state: SignInModalState) => {
        useBasicStore.setState((oldState) => {
            return {
                signInModalState: state,
            };
        });
    };

    const onClickHandler = () => {
        openSignInModal(SignInModalState.SignIn);
        if (onMobileModalMenuClose) onMobileModalMenuClose();
    };

    return (
        <>
            <Box h="100%">
                <GradientButton
                    label="Sign In"
                    onClick={onClickHandler}
                    containerStyle={{
                        borderRadius: "4px",
                        background: "button.default.default",
                        color: "white",
                        _focus: {
                            background: "button.default.hover",
                        },
                        _hover: {
                            background: "button.default.hover",
                        },
                        _active: {
                            background: "button.default.active",
                        },
                        _disabled: {
                            color: "rythm",
                            boxShadow: "none",
                            cursor: "not-allowed",
                            pointerEvents: "none",
                            background: "button.default.disabled",
                        },
                    }}
                    gradientBoxStyle={{ display: "none" }}
                    buttonStyle={{
                        fontSize: isMobile ? "16px" : "12px",
                        px: "18px",
                        ...buttonStyle
                    }}
                />
            </Box>
        </>
    );
};

export default UserSignInButton;
