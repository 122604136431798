import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import textStyles from "./textStyles";
import layerStyles from "./layerStyles";
import Button from "./components/Button";
import AccordionButton from "./components/AccordionButton";
import Input from "./components/Input";
import Radio from "./components/Radio";
import Menu from "./components/Menu"

const theme = extendTheme({
    // Font families
    fonts: {
        body: `"Fira Sans Condensed", sans-serif`,
        condensed: `"Fira Sans Condensed", sans-serif`,
        regular: `"Fira Sans", sans-serif`,
    },
    // Base UI components styling
    components: {
        Button,
        Input,
        AccordionButton,
        Radio,
        Menu,
    },
    colors: colors,
    textStyles: textStyles,
    layerStyles: layerStyles,
    styles: {
        global: {
            "*": {
                scrollbarColor: "#49CCFF #767A90",
                scrollbarWidth: "thin",
            },
            body: {
                color: "white",
                minH: "100vh",
                bg: "spaceCadet",
                backgroundImage: {base: "url('/images/br-background-mobile.jpg')", sm2: "url('/images/br-background-desktop.jpg')"},
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top",
                bgSize: "100% auto",
            },
            "&::-webkit-scrollbar": {
                width: "10px",
                // width: 0,
                height: "6px",
            },
            "&::-webkit-scrollbar-track": {
                width: "10px",
                height: "6px",
                background: "gray.dark",
                borderRadius: "50em",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "blue.light",
                borderRadius: "50em",
            },
        },
    },
    shadows: {
        outline: "none",
        boxShadow: "none",
    },
    // 2. Update the breakpoints as key-value pairs
    breakpoints: {
        xs: "300px",
        sm: "380px",
        sm1: "400px",
        sm2: "600px",
        md: "768px",
        lg: "960px",
        lg2: "1100px",
        xl: "1200px",
        "2xl": "1536px",
    },
});

export default theme;
