import React, { useState } from "react";
import Image from "next/image";
import { Box, Center, Flex, Spinner } from "@chakra-ui/react";

type QuickStartItemProps = { onClick: () => void };

const QuickStartItem = ({ onClick }: QuickStartItemProps): JSX.Element => {
    const [imageLoaded, setImageLoaded] = useState<boolean>();

    const onImageLoaded = () => {
        setImageLoaded(true);
    };

    return (
        <Box
            position="relative"
            style={{ aspectRatio: 16 / 9 }}
            bg="spaceCadet"
            rounded="6px"
            overflow="hidden"
            cursor="pointer"
            onClick={onClick}
        >
            <Box
                w="100%"
                h="100%"
                position="relative"
                opacity={imageLoaded ? 1 : 0}
                transition="opacity .25s"
            >
                <Image
                    src="/images/onboard/landscape/beyond-rarity-onboard-1.jpg"
                    layout="fill"
                    alt="Quick start guide"
                    draggable={false}
                    onLoadingComplete={onImageLoaded}
                />
                <Flex
                    textAlign="right"
                    w="100%"
                    h="100%"
                    position="absolute"
                    fontSize="36px"
                    fontWeight={300}
                    lineHeight="110%"
                    justifyContent="flex-end"
                    alignItems="center"
                    pr="20px"
                >
                    Quick
                    <br />
                    Start
                    <br />
                    Guide
                </Flex>
            </Box>
            {!imageLoaded && (
                <Center
                    position="absolute"
                    top="0"
                    left="0"
                    w="100%"
                    h="100%"
                    color="rythm"
                    fontSize="20px"
                    fontWeight={300}
                >
                    <Spinner />
                </Center>
            )}
        </Box>
    );
};

export default QuickStartItem;
