import { randWord, randNumber, seed } from "@ngneat/falso";

// Returns a Capitalized random word
export const getCapitalizedRandWord = (): string => {
    seed();
    const word = randWord();
    return word.charAt(0).toUpperCase() + word.slice(1);
};

// Builds a fake traits group
export const buildFakeTraitsGroup = (): TraitValueData[] =>
    Array.from({ length: randNumber({ min: 6, max: 12 }) }, () => ({
        traitId: 0,
        id: randNumber({ min: 0, max: 100000 }),
        label: getCapitalizedRandWord(),
        amount: randNumber({ min: 0, max: 150 }),
    })).sort((a, b) => a.amount - b.amount);

export const getCollectionFakeTraits = (traitsAmount = 8) =>
    Array.from({ length: traitsAmount }, () => ({
        id: `${randWord()}-${randNumber({ min: 0, max: 100000 })}`,
        name: `Group name ${randWord()}`,
        traits: buildFakeTraitsGroup(),
    }));

export const getFakeToken = (): TokenListItemProps => {
    const id = randNumber({ min: 0, max: 10000 });
    const collectionName = getCapitalizedRandWord();

    return {
        token: {
            name: getCapitalizedRandWord(),
            tokenStringId: id.toString(),
            tokenId: id,
            rank: randNumber({ min: 0, max: 10000 }),
            imageUrl: "",
            currentPrice: 1,
            priceProjection: 2,
            deviation: 1,
            confidence: 30,
            traits: [],
            vibes: [],
            marketplace: 0,
        },
        itemIndex: randNumber({ min: 0, max: 10000 }),
        collection: {
            collectionId: collectionName,
            name: collectionName,
            slug: collectionName,
            contract: "",
            itemsAmount: 0,
            ownersAmount: 0,
            floorPrice: 0,
            volume: 0,
        },
        triggerTooltip: false,
    };
};

export const getFakeTokensList = (amount: number = 200): Array<TokenListItemProps> => {
    return Array.from({ length: amount }, () => getFakeToken());
};

export const slugify = (str) =>
    str
        .trim() // remove whitespaces at the start and end of string
        .toLowerCase()
        .replace(/^-+/g, "") // remove one or more dash at the start of the string
        .replace(/[^\w-]+/g, "-") // convert any on-alphanumeric character to a dash
        .replace(/-+/g, "-") // convert consecutive dashes to singuar one
        .replace(/-+$/g, "");

export const formatToSimpleDuration = (duration) => {
    let absDuration = Math.abs(duration);

    let result = "";

    let remainder = absDuration;

    if (Math.floor(remainder / 1000.0 / 60 / 60 / 24) >= 1) {
        let days = Math.floor(remainder / 1000.0 / 60 / 60 / 24);
        remainder = remainder % (1000.0 * 60 * 60 * 24);
        result += days + "d";
    }

    if (Math.floor(remainder / 1000.0 / 60 / 60) >= 1) {
        let hours = Math.floor(remainder / 1000.0 / 60 / 60);
        remainder = remainder % (1000.0 * 60 * 60);
        if (result.length) result += " ";
        result += hours + "h";
    }

    if (Math.floor(remainder / 1000.0 / 60) >= 1) {
        let minutes = Math.floor(remainder / 1000.0 / 60);
        remainder = remainder % (1000.0 * 60);
        if (result.length) result += " ";
        result += String(minutes).padStart(2, "0") + "m";
    }

    if (Math.floor(remainder / 1000.0) >= 1) {
        let seconds = Math.floor(remainder / 1000.0);
        if (result.length) result += " ";
        result += String(seconds).padStart(2, "0") + "s";
    } else {
        if (result.length) result += " ";
        result += "0s";
    }

    if (duration == null) {
        result = "";
    } else if (duration < 1000 && duration >= 0) {
        result = "Just now";
    } else if (duration < 0) {
        // console.debug("duration: ", duration);
        result = "In " + result;
    } else {
        result += " ago";
    }

    return result;
};

export const getPathInfo = () : PathInfo => {

    if (window == null) {
        return {
            protocol: null,
            basePath: null,
            parts: null,
            subdomain: null,
            isStage: null,
        };
    }
    
    const origin = window?.location.origin;
    const protocol = origin.split(/:\/\//)[0];
    const basePath = origin.replace(/.+:\/\//, "");
    const parts = basePath.split(/\.|\/\//);
    const subdomain = parts[0];
    const isStage = subdomain.toLowerCase().endsWith("stage");

    const result: PathInfo = {
        protocol: protocol,
        basePath: basePath,
        parts: parts,
        subdomain: subdomain,
        isStage: isStage,
    };

    return result;
}

export const encodeSvg = (svgString)  => {
  return svgString.replace('<svg',(~svgString.indexOf('xmlns')?'<svg':'<svg xmlns="http://www.w3.org/2000/svg"'))
        
        //
        //   Encode (may need a few extra replacements)
        //
        .replace(/"/g, '\'')
        .replace(/%/g, '%25')
        .replace(/#/g, '%23')       
        .replace(/{/g, '%7B')
        .replace(/}/g, '%7D')         
        .replace(/</g, '%3C')
        .replace(/>/g, '%3E')

        .replace(/\s+/g,' ') 
        // 
        //    The maybe list (add on documented fail)
        // 
        //  .replace(/&/g, '%26')
        //  .replace('|', '%7C')
        //  .replace('[', '%5B')
        //  .replace(']', '%5D')
        //  .replace('^', '%5E')
        //  .replace('`', '%60')
        //  .replace(';', '%3B')
        //  .replace('?', '%3F')
        //  .replace(':', '%3A')
        //  .replace('@', '%40')
        //  .replace('=', '%3D')
  ;}