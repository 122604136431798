import create from "zustand";
import useCollectionStore from "@store/collectionStore";
import useFiltersStore, {
    hasTopFiltersApplied,
    hasMarketplaceFilterApplied,
} from "@store/filtersStore";
import shallow from "zustand/shallow";
import { enableMapSet, produce } from "immer";
import { subscribeWithSelector } from "zustand/middleware";
import { getBriteLine, getListedTokens, getUnlistedTokens } from "@apiSrc";
import {
    buildTokensMapFromFlatData,
    buildSortedTokensListFromTokensMap,
    buildTokensPerTraitsMap,
    buildTokensPerVibeMap,
    isBetween,
    sortTokens,
    timeSince,
    findStartEndTokenIds,
    findStartEndRanks,
    findPricesRange,
    findProjectionsRange,
    findPriceEdges,
    findDeviationsRange,
    sortTokensList,
    getPrevNextTokensMap,
    buildTokensPerMarketplaceMap,
    getUserCollectionTokensPage,
} from "./helpers";
import useSessionStore, { getDefaultSortOptions } from "./sessionStore";
import { getFilteredTokensTraitsMap, getTraitsFilteredTokens } from "./traitsHelpers";
import { getMarketplaceFilteredTokens, getTopFilteredTokens } from "./filterHelpers";
import { rebuildTokensData, buildTokensListFromTokenIdsList } from "./tokensHelper";
import { getVibesFilteredTokens } from "./vibesHelpers";

// Enables ES6 Map support for immer https://immerjs.github.io/immer/map-set
enableMapSet();

type CollectionTokensState = {
    loading: boolean;
    briteLineData: [number, number][];
    unlistedTokens: Token[];
    listedTokensIds: TokenStringId[];
    tokensMap: TokensMap;
    tokensList: Token[]; // This list is ALWAYS sorted by Rank, descending
    filteredTokens: Token[];
    filteredSortedTokens: Token[];
    userOwnedTokens: TokenStringId[] | null;
    filteredSortedPrevNextMap: PrevNextMap;
    filteredTraitsMap?: TraitsMap;
    tokensPerTraitMap: TokensPerTraitMap;
    tokensPerVibeMap: TokensPerVibeMap;
    tokensPerMarketplaceMap: TokensPerMarketplaceMap;
    startTokenId: TokenId;
    endTokenId: TokenId;
    minTokenPrice: number;
    maxTokenPrice: number;
    topRank: number;
    bottomRank: number;
    lowestPrice: number;
    highestPrice: number;
    lowestDeviation: number;
    highestDeviation: number;
    lowestProjection: number;
    highestProjection: number;
    lowest10PctPrices: { min: number; max: number };
    highest10PctPrices: { min: number; max: number };
    selectedToken?: Token;
    highlightMobileToken: boolean;
};

const initialState = {
    loading: true,
    briteLineData: [],
    unlistedTokens: [],
    listedTokensIds: [],
    tokensMap: new Map(),
    tokensList: [],
    filteredTokens: [],
    filteredSortedTokens: [],
    userOwnedTokens: null,
    filteredSortedPrevNextMap: new Map(),
    filteredTraitsMap: null,
    tokensPerTraitMap: new Map(),
    tokensPerVibeMap: new Map(),
    tokensPerMarketplaceMap: new Map(),
    startTokenId: 0,
    endTokenId: 0,
    minTokenPrice: 0,
    maxTokenPrice: 0,
    topRank: 0,
    bottomRank: 0,
    lowestPrice: 0,
    highestPrice: 0,
    lowestDeviation: 0,
    highestDeviation: 0,
    lowestProjection: 0,
    highestProjection: 0,
    lowest10PctPrices: { min: 0, max: 0 },
    highest10PctPrices: { min: 0, max: 0 },
    // selectedToken: null,
    highlightMobileToken: false,
};

// Main store for app state.
export const useCollectionTokensStore = create<CollectionTokensState>()(
    subscribeWithSelector(() => ({ ...initialState })),
);

const unsubscribeResetTokens = useCollectionStore.subscribe(
    (state) => state.collectionId,
    (newCollectionId) => {
        useCollectionTokensStore.setState(() => ({ ...initialState }));
    },
);

/**
 * Listens for changes in the filter values of filtersStore.
 * Every time a filter changes it runs a new filtering of the full tokens list.
 */
// TODO: What's this DummyType?? It should be a Pick from FiltersState (filtersStore.ts)?
type DummyType = [
    minPrice: number | null,
    maxPrice: number | null,
    minRank: number | null,
    maxRank: number | null,
    minDeviation: number | null,
    marketplaces: MarketplacesFilters,
    saleStatus: SaleStatus,
    tokenStringIds: TokenStringId[],
    traits: FilteredTraitsValues,
    vibes: FilteredVibes,
];

const unsubscribeFiltersStore = useFiltersStore.subscribe(
    (state) => [
        state.minPrice,
        state.maxPrice,
        state.minRank,
        state.maxRank,
        state.minDeviation,
        state.marketplaces,
        state.saleStatus,
        state.tokenStringIds,
        state.traits,
        state.vibes,
    ],
    (newValues: DummyType) => {
        const [, , , , , , , , traits, vibes] = newValues;

        // First let's get the top filtered tokens: saleStatus, rank, price, minDeviation,
        // marketplaces and tokenIds list.
        let topFilteredTokens: Token[] = getTopFilteredTokens();

        // Once we have the top filtered list, let's build a traits map only for these filtered tokens.
        // We need to get this list BEFORE applying any traits or vibes filters because the traits and
        // vibes filters list are faceted.
        let topFilteredTraitsMap: TraitsMap = getFilteredTokensTraitsMap(topFilteredTokens);

        // Now let's create the final filtered tokens list. Let's start with the top filtered tokens.
        let filteredTokens: Token[] = [...topFilteredTokens];

        // Now let's apply the traits & vibes filters, if needed
        const traitsFilteredTokenIds: TokenStringId[] = getTraitsFilteredTokens(traits);
        const vibesFilteredTokenIds: TokenStringId[] = getVibesFilteredTokens(vibes);

        if (traitsFilteredTokenIds?.length || vibesFilteredTokenIds?.length) {
            let groupedFilteredTokens = [topFilteredTokens];

            if (traitsFilteredTokenIds?.length)
                groupedFilteredTokens.push(buildTokensListFromTokenIdsList(traitsFilteredTokenIds));

            if (vibesFilteredTokenIds?.length)
                groupedFilteredTokens.push(buildTokensListFromTokenIdsList(vibesFilteredTokenIds));

            filteredTokens = groupedFilteredTokens.reduce((a, b) => a.filter((c) => b.includes(c)));
        }

        // We have all the filters applied (rank, price, minDeviation, tokenIds, traits & vibes)
        // We should create the tokens per marketplace BEFORE applying any marketplace filter
        // so each marketplace option shows the correct number of tokens.
        const tokensPerMarketplaceMap = buildTokensPerMarketplaceMap(filteredTokens);

        // Now we can apply the marketplaces filter.
        filteredTokens = getMarketplaceFilteredTokens(filteredTokens);

        // Finally let's sort the tokens list by criteria
        const sortBy: SortCriteria = useFiltersStore.getState().sortBy;
        const sortOrder: SortOrder = useFiltersStore.getState().sortOrder;
        const filteredSortedTokens = [...filteredTokens].sort((a: Token, b: Token) =>
            sortTokens(a, b, sortBy, sortOrder),
        );

        const filteredSortedPrevNextMap = new Map();
        filteredSortedTokens.forEach((token, index) => {
            const prevNextTokens: PrevNextTokens = {
                prevToken: index - 1 >= 0 ? filteredSortedTokens[index - 1] : null,
                nextToken:
                    index + 1 < filteredSortedTokens.length
                        ? filteredSortedTokens[index + 1]
                        : null,
            };
            filteredSortedPrevNextMap.set(token.tokenStringId, prevNextTokens);
        });

        // As last step we need to rebuild the faceted TraitsMap
        const theTraits = buildFilteredTraitsList(filteredTokens, topFilteredTraitsMap);

        useCollectionTokensStore.setState(() => ({
            filteredTokens: filteredTokens,
            filteredSortedTokens: filteredSortedTokens,
            filteredSortedPrevNextMap: filteredSortedPrevNextMap,
            filteredTraitsMap: theTraits,
            tokensPerMarketplaceMap,
        }));
    },
    { equalityFn: shallow },
);

/**
 * Listens for changes in the sort values of filtersStore.
 * Every time a sort changes it runs a new sorting on the FilteredTokens list.
 */
const unsubscribeFiltersSortStore = useFiltersStore.subscribe(
    (state) => [state.sortBy, state.sortOrder],
    (newValues: [SortCriteria, SortOrder], oldValues: [SortCriteria, SortOrder]) => {
        const [sortBy, sortOrder] = newValues;
        const filteredTokens: Token[] = useCollectionTokensStore.getState().filteredTokens;
        const sortedFilteredTokensList: Token[] = [...filteredTokens].sort((a: Token, b: Token) =>
            sortTokens(a, b, sortBy, sortOrder),
        );

        const filteredSortedPrevNextMap = new Map();
        sortedFilteredTokensList.forEach((token, index) => {
            const prevNextTokens: PrevNextTokens = {
                prevToken: index - 1 >= 0 ? sortedFilteredTokensList[index - 1] : null,
                nextToken:
                    index + 1 < sortedFilteredTokensList.length
                        ? sortedFilteredTokensList[index + 1]
                        : null,
            };
            filteredSortedPrevNextMap.set(token.tokenStringId, prevNextTokens);
        });

        useCollectionTokensStore.setState(() => ({
            filteredSortedTokens: sortedFilteredTokensList,
            filteredSortedPrevNextMap: filteredSortedPrevNextMap,
        }));
    },
    { equalityFn: shallow },
);

type UserTokensSubcriberProps = [userOwnedTokens: TokenStringId[] | null, tokensMap: TokensMap];

// const unsubscribeUserOwnedTokens = useCollectionTokensStore.subscribe(
//     (state) => [state.userOwnedTokens, state.tokensMap],
//     (
//         [newUserOwnedTokens, newTokensMap]: UserTokensSubcriberProps,
//         [oldUserOwnedTokens, oldTokensMap]: UserTokensSubcriberProps,
//     ) => {
//         // We should only update the tokens data once the collection tokens and
//         const ownedTokensHasChanged = oldUserOwnedTokens?.length !== newUserOwnedTokens?.length;
//         const tokensMapHasChanged = true; // oldTokensMap.size !== newTokensMap.size;

//         if (
//             (ownedTokensHasChanged && newTokensMap.size > 0) ||
//             (tokensMapHasChanged && newUserOwnedTokens?.length >= 0)
//         ) {
//             // The user doesn't own any token for this collection
//             if (!newUserOwnedTokens?.length || !newTokensMap.size) return;

//             useCollectionTokensStore.setState(
//                 produce((state) => {
//                     const { tokensMap } = state;

//                     newUserOwnedTokens.forEach((tokenStringId) => {
//                         const token = tokensMap.get(tokenStringId);
//                         token.own = true;
//                         tokensMap.set(tokenStringId, token);
//                     });
//                 }),
//             );
//         }
//     },
// );

//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------
//-------------------------------------------------------------------------------------------------------

export const clearUserOwnedTokens = () => {
    const userOwnedTokens = useCollectionTokensStore.getState().userOwnedTokens;

    if (userOwnedTokens && userOwnedTokens.length) {
        const updatedTokens = new Map(useCollectionTokensStore.getState().tokensMap);

        userOwnedTokens.forEach((tokenStringId) => {
            const token = updatedTokens.get(tokenStringId);
            token.own = null;
            updatedTokens.set(tokenStringId, token);
        });

        const filteredTokens = useCollectionTokensStore.getState().filteredTokens;
        const filteredSortedTokens = useCollectionTokensStore.getState().filteredSortedTokens;
        const tokensList = useCollectionTokensStore.getState().tokensList;

        useCollectionTokensStore.setState(() => ({
            userOwnedTokens: null,
            tokensList: [...tokensList],
            tokensMap: updatedTokens,
            filteredTokens: [...filteredTokens],
            filteredSortedTokens: [...filteredSortedTokens],
        }));
    }
};

export const updateCollectionTokensData = (
    listings: ListingsData,
    briteline: BritelineData,
): boolean => {
    const collectionTokensMap = useCollectionTokensStore.getState().tokensMap;

    if (!collectionTokensMap || !collectionTokensMap.size) {
        return true;
    }

    const updatedTokensMap = new Map(collectionTokensMap);

    // const updatedTokensMap: TokensMap = new Map(
    //     JSON.parse(JSON.stringify(Array.from(collectionTokensMap))),
    // );

    const [listingsDidUpdate, newListedTokenIds] = updateListings(updatedTokensMap, listings.data);
    const britelineDidUpdate = updateBriteLine(updatedTokensMap, briteline.data);

    // if (!listingsDidUpdate && !britelineDidUpdate) return false;

    if (listingsDidUpdate)
        console.log(
            `%cListings updated for ${useCollectionStore.getState().collectionId}`,
            "color:#f60;",
        );

    if (britelineDidUpdate)
        console.log(
            `%cBriteline updated for ${useCollectionStore.getState().collectionId}`,
            "color:#0f0;",
        );

    if (!listingsDidUpdate && !britelineDidUpdate) return false;

    const {
        highestPrice,
        lowestPrice,
        highestDeviation,
        lowestDeviation,
        highestProjection,
        lowestProjection,
        lowest10PctPrices,
        highest10PctPrices,
        filteredTokens,
        filteredSortedTokens,
        filteredSortedPrevNextMap,
        filteredTraitsMap,
    } = rebuildTokensData(updatedTokensMap);

    useCollectionTokensStore.setState(() => ({
        tokensMap: updatedTokensMap,
        listedTokensIds: newListedTokenIds,
        highestPrice,
        lowestPrice,
        highestDeviation,
        lowestDeviation,
        highestProjection,
        lowestProjection,
        lowest10PctPrices,
        highest10PctPrices,
        filteredTokens,
        filteredSortedTokens,
        filteredSortedPrevNextMap,
        filteredTraitsMap,
    }));

    return true;
};

/**
 * Updates the updatedTokensMap with new listings data if available.
 * Also returns a new list of listedTokensIds.
 */
const updateListings = (
    updatedTokensMap: TokensMap,
    listings: string[],
): [boolean, TokenStringId[]] => {
    if (!listings || !listings.length) return [false, []];

    const listedTokensIds = useCollectionTokensStore.getState().listedTokensIds;
    const newListedTokensIds = listings.map((listedToken) => listedToken.split(",")[0]);

    // Find listed tokens that have been removed from listings
    const removedFromListings = listedTokensIds.filter(
        (tokenId) => !newListedTokensIds.includes(tokenId),
    );

    // Update the listings
    let updatedTokensCount = 0;

    listings.forEach((listing) => {
        const [tokenId, currentPrice, marketplace] = listing.split(",");
        const tokenData = updatedTokensMap.get(tokenId);
        if (
            tokenData &&
            (tokenData.currentPrice !== Number(currentPrice) ||
                tokenData.marketplace !== Number(marketplace))
        ) {
            updatedTokensCount++;

            tokenData.currentPrice = Number(currentPrice);
            tokenData.deviation = tokenData.priceProjection - Number(currentPrice);
            tokenData.marketplace = Number(marketplace);
        }
    });

    // nothing changed from previous listings

    if (!updatedTokensCount && !removedFromListings.length) {
        return [false, newListedTokensIds];
    }

    removedFromListings.forEach((tokenId) => {
        const tokenData = updatedTokensMap.get(tokenId);
        if (tokenData) {
            tokenData.currentPrice = null;
            tokenData.marketplace = null;
        }
    });

    // Some listings have changed, return true
    return [true, newListedTokensIds];
};

/**
 *  Updates ALL tokens price projection, confidence and deviation for a collection
 * base on the briteLineData endpoint.
 */
const updateBriteLine = (updatedTokensMap: TokensMap, tokens: BritelineTokenData[]): boolean => {
    if (!tokens || !tokens?.length) return false;

    let tokenChanged: boolean = false;

    tokens.forEach((updatedToken) => {
        const [tokenStringId, priceProjection, confidence] = updatedToken;

        const token = updatedTokensMap.get(tokenStringId);

        if (token) {
            if (
                token.priceProjection !== priceProjection ||
                token.confidence !== confidence * 100
            ) {
                tokenChanged = true;
                token.priceProjection = priceProjection;
                token.confidence = confidence * 100;
                if (token.currentPrice)
                    token.deviation = priceProjection - Number(token.currentPrice);
            }
        }
    });

    return tokenChanged;
};

/**
 * Loads tokens data for a new collection when the URL change.
 * There are 3 different sets of data being loaded:
 *    - Brite Line (a tuple of [x,y] values for the brite line, one for each token)
 *    - Listed Tokens (a list of listed tokens data)
 *    - Unlisted Tokens (a list of unlisted tokens)
 */
export const loadCollectionTokensData = async (collectionId) => {
    const [listedTokensData, unlistedTokensData, userOwnedTokens] = await Promise.all([
        getListedTokens(collectionId).then((listedTokensData) => {
            return listedTokensData;
        }),
        getUnlistedTokens(collectionId).then((unlistedTokensData) => {
            return unlistedTokensData;
        }),
        loadUserCollectionTokens(collectionId).then((userTokens) => {
            return userTokens;
        }),
    ]);

    console.log("Tokens data loaded");

    const [listedTokens, lastListingsUpdate] = listedTokensData;

    const listedTokensIds = listedTokens.map((token) => token[0]);

    const flatTokensList: FlatToken[] = [...listedTokens, ...unlistedTokensData];

    // build the tokens map, list and find start and end token ids
    const tokensMap: TokensMap = buildTokensMapFromFlatData(flatTokensList, userOwnedTokens);

    // builds the tokens list sorted by RANK
    const tokensList: Token[] = buildSortedTokensListFromTokensMap(tokensMap);

    // Searchs for the start and end TokenId
    const { startTokenId, endTokenId } = findStartEndTokenIds(tokensMap);

    // searchs for the top an bottom rank inside a collection
    const { topRank, bottomRank } = findStartEndRanks(tokensMap);

    // searchs for the highest an lowest priced tokens inside a collection
    const { highestPrice, lowestPrice } = findPricesRange(tokensMap);

    // searchs for the highest an lowest price deviations inside a collection
    const { highestDeviation, lowestDeviation } = findDeviationsRange(tokensMap);

    // searchs for the highest an lowest price projections inside a collection
    const { lowestProjection, highestProjection } = findProjectionsRange(tokensMap);

    // searchs for the lowest and highest 10% valued priced tokens
    const { lowest10PctPrices, highest10PctPrices } = findPriceEdges(tokensList);

    // builds a map of tokens per collection trait
    const tokensPerTraitMap = buildTokensPerTraitsMap(tokensList);

    // now builds a map of tokens per collection vibe
    const tokensPerVibeMap = buildTokensPerVibeMap(tokensList);

    const tokensPerMarketplaceMap = buildTokensPerMarketplaceMap(tokensList);

    const filteredTokens: Token[] = [...tokensList];

    // TODO: Should we keep the sorting while switching between collections?
    const { sortCriteria, sortOrder } = getDefaultSortOptions();

    // Initial sort for each collection
    const filteredSortedTokens = sortTokensList(filteredTokens, sortCriteria, sortOrder);

    const filteredSortedPrevNextMap = getPrevNextTokensMap(filteredSortedTokens);

    useCollectionTokensStore.setState(() => ({
        tokensMap,
        lastListingsUpdate,
        tokensList,
        userOwnedTokens,
        listedTokensIds,
        filteredTokens,
        filteredSortedTokens,
        filteredSortedPrevNextMap,
        tokensPerTraitMap,
        tokensPerVibeMap,
        tokensPerMarketplaceMap,
        startTokenId,
        endTokenId,
        topRank,
        bottomRank,
        lowestPrice,
        highestPrice,
        lowestDeviation,
        highestDeviation,
        lowestProjection,
        highestProjection,
        lowest10PctPrices,
        highest10PctPrices,
        loading: false,
    }));
};

export const loadUserCollectionTokens = async (collectionId: string) => {
    let userTokens = [];
    let nextPageCursor = null;

    const { tokens, next } = await getUserCollectionTokensPage(collectionId);

    nextPageCursor = next;
    userTokens = [...tokens];

    while (nextPageCursor) {
        const { tokens, next } = await getUserCollectionTokensPage(collectionId, nextPageCursor);
        if (tokens && tokens.length) userTokens = [...userTokens, ...tokens];
        nextPageCursor = next;
    }

    return userTokens;
};

// TODO: Refactor this helper functions and move them to helpers.ts
// HELPERS -------------------------------------------------------------------------------

/**
 * Given a trait filter return all the tokens matching the selected trait values.
 */
const getFilteredTokenIdsByTrait = ({ id, values }: TraitFilter): TokenStringId[] => {
    let traitTokensIds: TokenStringId[] = [];

    for (const traitValueId of values.keys()) {
        traitTokensIds = traitTokensIds.concat(getTraitValueTokenIds(id, traitValueId));
    }

    return traitTokensIds;
};

/**
 * Returns all the tokenStringIds for a given trait value
 */
export const getTraitValueTokenIds = (
    traitId: TraitId,
    traitValueId: TraitValueId,
): TokenStringId[] => {
    const tokensPerTraitMap = useCollectionTokensStore.getState().tokensPerTraitMap;
    return [...tokensPerTraitMap.get(traitId).get(traitValueId)];
};

/**
 * Dynamically updates the traits filters options based on the filtered tokens.
 **/
export const buildFilteredTraitsList = (
    filteredTokens: Token[],
    filteredTraitsMap: TraitsMap,
): TraitsMap => {
    const tokensMap = useCollectionTokensStore.getState().tokensMap;
    const filteredTraits = useFiltersStore.getState().traits;
    const filteredTokenStringIds: TokenStringId[] = filteredTokens.map(
        (token) => token.tokenStringId,
    );

    const filteredTraitsCountMap: TraitsMap = new Map();

    // Let's iterate over EACH collection trait (collectionTraitsMap).
    // For each trait we should:
    //   1 - Find all the tokens filtered by ANY OTHER trait (getOtherTraitsFilteredTokenIds)
    //   2 - For all these tokens create a map of how many of these have a value for
    //       the current trait.
    //
    filteredTraitsMap.forEach(({ name, values, rankedNumerically }, traitId) => {
        const tokenStringIds = getOtherTraitsFilteredTokenIds(traitId, filteredTokenStringIds);

        if (tokenStringIds.length) {
            // there's at least one token filtered by other traits, so the current
            // trait shoud be in the filteredTraitsCountMap. Let's add it:
            filteredTraitsCountMap.set(traitId, {
                id: traitId,
                name: name,
                rankedNumerically: rankedNumerically,
                values: new Map(),
            });

            // Now let's build all the trait values for the current trait that matches
            // the tokens filtered by other traits:
            const currentTraitValues = filteredTraitsCountMap.get(traitId).values;

            tokenStringIds.forEach((tokenStringId) => {
                tokensMap.get(tokenStringId).traits.forEach((tokenTraitValues) => {
                    if (tokenTraitValues) {
                        const [tokenTraitId, tokenTraitValueId] = tokenTraitValues;
                        if (tokenTraitId === traitId) {
                            if (!currentTraitValues.has(tokenTraitValueId)) {
                                currentTraitValues.set(tokenTraitValueId, {
                                    id: tokenTraitValueId,
                                    traitId: traitId,
                                    label: values.get(tokenTraitValueId)?.label || "(None)",
                                    amount: 1,
                                });
                            } else {
                                currentTraitValues.get(tokenTraitValueId).amount =
                                    currentTraitValues.get(tokenTraitValueId).amount + 1;
                            }
                        }
                    }
                });
            });
        } else {
            filteredTraitsCountMap.set(traitId, {
                id: traitId,
                name: name,
                rankedNumerically: rankedNumerically,
                values: values,
            });
        }
    });

    // One final step. Some combination of filters can lead to selected trait values with
    // no matches. This values should be visible on the traits lists even if the have
    // zero matching tokens because they're checked.
    // Example: For the BAYC choose these filter traits in this order:
    // - CLOTHES: Black Suit
    // - EARRING: Silver Stud
    // - EARRING: Diamond Stud
    // - EYES: Sleepy
    // After applying these filters the "Earring - Diamond Stud" has 0 matches.

    for (const [traitId, { values }] of filteredTraits.entries()) {
        values.forEach(({ id: valueId }) => {
            if (filteredTraitsCountMap.get(traitId)?.values.get(valueId)) {
            } else {
                filteredTraitsCountMap.get(traitId).values.set(valueId, {
                    traitId,
                    id: valueId,
                    label: filteredTraits.get(traitId).values.get(valueId).label,
                    amount: 0,
                });
            }
        });
    }

    return filteredTraitsCountMap;
};

/**
 * Given a Trait ID returns all the token IDs filtered by any other active
 * traits filters.
 * I.E: For BAYC, if the traitId is "CLOTHES" (id = 1) this function returns all
 * the token IDs filtered by all the other traits (0, 2, 3, 4, 5 and 6).
 */
const getOtherTraitsFilteredTokenIds = (
    traitId: TraitId,
    filteredTokenStringIds?: TokenStringId[],
): TokenStringId[] => {
    const traits = useFiltersStore.getState().traits;

    let tokenStringIds: TokenStringId[][] = [];

    traits.forEach((filteredTrait, currentTraitId) => {
        if (traitId !== currentTraitId) {
            tokenStringIds.push(getFilteredTokenIdsByTrait(filteredTrait));
        }
    });

    // Token IDs is an array of arrays, where each inner array is a list of token IDs
    // filtered by a trait. We should interesect all these lists.
    let intersectedTokenStringIds: TokenStringId[] = [];
    if (tokenStringIds.length === 1) {
        intersectedTokenStringIds = [...tokenStringIds[0]];
    } else {
        intersectedTokenStringIds = getDuplicates(tokenStringIds.flat());
    }

    if (filteredTokenStringIds?.length) {
        // Join both arrays and find only the duplicated values! (intersection).
        // Not optimal, but way much faster than using the reduce version.
        return getDuplicates([...intersectedTokenStringIds, ...filteredTokenStringIds]);
    }

    return intersectedTokenStringIds;
};

/**
 * Returns an array of every element present at least 2 times in the array.
 */
const getDuplicates = (arr: string[]): string[] => {
    const map = new Map();
    const duplicates: string[] = [];
    arr.forEach((item: string) => {
        if (map.has(item)) {
            if (map.get(item) === 1) {
                duplicates.push(item);
            }
            map.set(item, map.get(item) + 1);
        } else {
            map.set(item, 1);
        }
    });

    return duplicates;
};

/**
 *
 * @param tokenStringId
 * @returns
 */
export const tokenExists = (tokenStringId: TokenStringId): boolean =>
    !!useCollectionTokensStore.getState().tokensMap.get(tokenStringId);

/**
 * Sets the selected token in the store
 */
export const setSelectedToken = (token: Token | null) => {
    useCollectionTokensStore.setState(() => ({
        selectedToken: token,
    }));
};

/**
 * sets whether mobile tokens shoudl be highlighted. if a different token or data point is selected, the mobile token should not be highlighted.
 */
export const setHighlightMobileToken = (shouldHightight: boolean) => {
    useCollectionTokensStore.setState(() => ({ highlightMobileToken: shouldHightight }));
};

export const getTokenById = (tokenStringId: string): Token | null => {
    const tokensMap = useCollectionTokensStore.getState().tokensMap;
    return tokensMap.get(tokenStringId) || null;
};
export default useCollectionTokensStore;
