import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import BRMonogram from "/public/images/Beyond_Rarity_Logo.png";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Grid,
    GridItem,
    Box,
    Center,
    AspectRatio,
    Flex,
    Text,
    Icon,
    useMediaQuery,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import TutorialListItem from "./TutorialListItem";
import QuickStartItem from "./QuickStartItem";
import TutorialVideoPlayer from "./TutorialVideoPlayer";
import { BiChevronLeft } from "react-icons/bi";
import { AnimatePresence } from "framer-motion";
import QuickStart from "./QuickStart";
import MobileCloseButton from "./MobileCloseButton";
import { isIOS } from "react-device-detect";

type TutorialsModalProps = {
    isOpen: boolean;
    onClose?: () => void;
    onCloseComplete?: () => void;
};

const TutorialsModal = ({
    isOpen = false,
    onClose,
    onCloseComplete,
}: TutorialsModalProps): JSX.Element => {
    const modalBody = useRef<HTMLDivElement>();
    const [isMounted, setIsMounted] = useState<boolean>(false);
    const [playingVideoId, setPlayingVideoId] = useState<string>();
    const [quickStartVisible, setQuickStartVisible] = useState<boolean>();
    const [isDesktop] = useMediaQuery("(min-width: 600px)");

    useEffect(() => {
        const onResize = () => {
            if (modalBody.current)
                modalBody.current.style.flex = `0 1 ${window.innerHeight - 70}px`;
        };

        if (!isMounted) {
            setIsMounted(true);

            if (!isDesktop && isIOS) {
                window.addEventListener("resize", onResize);
                setTimeout(() => {
                    onResize();
                }, 50);
            }
        }

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, []);

    const onGoBack = () => {
        if (playingVideoId) setPlayingVideoId("");
        if (quickStartVisible) setQuickStartVisible(false);
    };

    const onVideoComplete = () => {
        setPlayingVideoId("");
    };

    const onShowQuickStart = () => {
        setQuickStartVisible(true);
    };

    const onCloseQuickStart = () => {
        setQuickStartVisible(false);
    };

    const onMobileCloseClick = () => {
        if (quickStartVisible) {
            setQuickStartVisible(false);
        } else if (playingVideoId) {
            setPlayingVideoId("");
        } else {
            onClose?.();
        }
    };

    if (!isMounted) return null;

    const bodyAspectRatio = !quickStartVisible ? 960 / 540 : 960 / 540;

    const modalBodyStyle: React.CSSProperties = !isDesktop
        ? { overflowY: "auto" }
        : { aspectRatio: bodyAspectRatio, transition: "aspect-ratio .25s", width: "100%" };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                onCloseComplete={onCloseComplete}
                isCentered
                blockScrollOnMount={true}
                id="tutorials-modal"
            >
                <ModalOverlay layerStyle="tutorials.modal.overlay" />
                <ModalContent
                    w={{ base: "100%", sm2: "95%" }}
                    h={{ base: "100%", sm2: "initial" }}
                    maxW={{ base: "420px", sm2: "960px" }}
                    bg="spaceCadet"
                    p="0"
                    m="0"
                    rounded={{ base: "none", sm2: "lg" }}
                >
                    {isDesktop && (
                        <Flex
                            layerStyle="tutorials.modal.closeButton"
                            display={{ base: "none", sm2: "flex" }}
                            _hover={{ bgColor: "gold.500", cursor: "pointer" }}
                            onClick={onClose}
                        >
                            <CloseIcon w="12px" h="12px" />
                        </Flex>
                    )}

                    <Box
                        layerStyle="tutorials.modal.headerContainer"
                        h="70px"
                        transition="height .25s"
                    >
                        <Center h="100%">
                            <Flex flexDir="column">
                                {isDesktop && (
                                    <Flex
                                        layerStyle="tutorials.modal.header"
                                        left={
                                            playingVideoId || quickStartVisible ? "15px" : "-15px"
                                        }
                                        opacity={playingVideoId || quickStartVisible ? 1 : 0}
                                        onClick={onGoBack}
                                    >
                                        <Icon as={BiChevronLeft} h="24px" w="20px" /> Back to all
                                        videos
                                    </Flex>
                                )}
                                <Text fontSize="12px">Welcome to</Text>
                                <AspectRatio
                                    minW="280px"
                                    ratio={BRMonogram.width / BRMonogram.height}
                                >
                                    <Image
                                        src={BRMonogram}
                                        alt="Beyond Rarity Logo"
                                        layout="fill"
                                    />
                                </AspectRatio>
                            </Flex>
                        </Center>
                    </Box>
                    <ModalBody
                        ref={modalBody}
                        layerStyle="tutorials.modal.body"
                        transition="all 0.25s"
                        h={{ base: "100%", sm2: "auto" }}
                        style={modalBodyStyle}
                    >
                        <Grid
                            layerStyle="tutorials.modal.grid"
                            templateColumns={{
                                base: "1",
                                sm2: "repeat(2, 1fr)",
                                md: "repeat(3, 1fr)",
                            }}
                        >
                            <GridItem w="100%">
                                <QuickStartItem onClick={onShowQuickStart} />
                            </GridItem>
                            <GridItem w="100%">
                                <TutorialListItem
                                    // VIDEO = FIND DEALS FAST
                                    // videoId="762465313" // vimeo
                                    videoId="yKZaVG3AAIg" // youtube
                                    onPlayVideo={setPlayingVideoId}
                                />
                            </GridItem>
                            <GridItem w="100%">
                                <TutorialListItem
                                    // VIDEO = FIND YOUR EXIT POINT
                                    // videoId="762465293" // vimeo
                                    videoId="8UrVTAqX3OE" // youtube
                                    onPlayVideo={setPlayingVideoId}
                                />
                            </GridItem>
                            <GridItem w="100%">
                                <TutorialListItem
                                    // VIDEO = FIND YOUR FAVORITE
                                    // videoId="762465272" // vimeo
                                    videoId="37RyzOZIBCM" // youtube
                                    onPlayVideo={setPlayingVideoId}
                                />
                            </GridItem>
                            <GridItem w="100%">
                                <TutorialListItem
                                    // VIDEO = SEE EVERYTHING
                                    // videoId="762465261" // vimeo
                                    videoId="A1DwVBfoOdU" // youtube
                                    onPlayVideo={setPlayingVideoId}
                                />
                            </GridItem>
                            <GridItem>
                                <TutorialListItem
                                    // VIDEO = INSIDE OUR TECH
                                    videoId="8IBIfjyOaq0" // youtube
                                    onPlayVideo={setPlayingVideoId}
                                />
                            </GridItem>
                        </Grid>

                        <AnimatePresence>
                            {playingVideoId && (
                                <TutorialVideoPlayer
                                    videoId={playingVideoId}
                                    onVideoComplete={onVideoComplete}
                                    isDesktop={isDesktop}
                                />
                            )}
                            {quickStartVisible && (
                                <QuickStart onClose={onCloseQuickStart} isDesktop={isDesktop} />
                            )}
                        </AnimatePresence>
                    </ModalBody>
                    {!isDesktop && <MobileCloseButton onClick={onMobileCloseClick} />}
                </ModalContent>
            </Modal>
        </>
    );
};

export default TutorialsModal;
