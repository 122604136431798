import { Box, Flex, Icon, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { XIcon } from "@components/icons";
import useDebouncedWindowSize from "@hooks/useDebouncedWindowSize";
import { useWindowSiteStore } from "@store/windowSiteStore";
import { useEffect, useState } from "react";

type ModalLayoutProps = {
    children: React.ReactNode;
    open: boolean;
    close: any;
};

export default function TransparentModal({ children, open, close }: ModalLayoutProps): JSX.Element {
    const [isMounted, setIsMounted] = useState(false);
    // const { isDesktop } = useDebouncedWindowSize({ useIsDesktop: { basisType: "site" } });
    const isDesktop = useWindowSiteStore(state => state.isDesktop);

    const [isOpen, setIsOpen] = useState(open);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (!isMounted) return null;

    return (
        <Modal isOpen={isOpen} onClose={close} size="full">
            <ModalOverlay bgColor="#000A" backdropFilter={"blur(10px)"} />
            <ModalContent
                textAlign="center"
                textColor={"#fff"}
                bgColor="transparent"
                // h="100%"
                // w="100%"
                py="40px"
                px="30px"
            >
                <Flex direction={"column"} pos="relative" maxW={1100} m="auto" maxH={"1000px"}>
                    {children}
                    <Flex
                        pos={isDesktop ? "absolute" : "fixed"}
                        right={isDesktop ? "-15" : "20px"}
                        top={isDesktop ? "-15" : undefined}
                        bottom={isDesktop ? undefined : "20px"}
                        borderRadius="50%"
                        bgColor={isDesktop ? undefined : "#8883"}
                        onClick={close}
                        cursor="pointer"
                        p="10px"
                        _hover={{ opacity: 0.66 }}
                    >
                        <Icon as={XIcon} />
                    </Flex>
                </Flex>
            </ModalContent>
        </Modal>
    );
}
