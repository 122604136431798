import useCollectionStore from "@store/collectionStore";
import useCollectionTokensStore from "./collectionTokensStore";
import {
    hasMarketplaceFilterApplied,
    hasTokenIdFiltersApplied,
    hasTopFiltersApplied,
} from "./filtersStore";

/**
 * Given a list of tokens returns a TraitsMap only for the traits and values that
 * matches all the tokens in the list.
 */
export const getFilteredTokensTraitsMap = (filteredTokens: Token[]): TraitsMap => {
    const collectionTraitsMap: TraitsMap = useCollectionStore.getState().collectionTraitsMap;

    if (!hasTokenIdFiltersApplied() && !hasTopFiltersApplied() && !hasMarketplaceFilterApplied()) {
        return collectionTraitsMap;
    }

    const filteredTokenTraits = new Map();

    collectionTraitsMap.forEach(({ name, rankedNumerically }, traitId) => {
        filteredTokenTraits.set(traitId, {
            id: traitId,
            name: name,
            rankedNumerically: rankedNumerically,
            values: new Map(),
        });
    });

    // let's iterate over every token and recreate the traits map
    filteredTokens.forEach(({ traits }) => {
        traits.forEach((tokenTraitValues) => {
            if (tokenTraitValues) {
                const [traitId, traitValueId] = tokenTraitValues;
                const trait = collectionTraitsMap.get(traitId);
                const value = trait.values.get(traitValueId);

                const { values } = filteredTokenTraits.get(traitId);

                if (!values.has(traitValueId)) {
                    values.set(traitValueId, {
                        id: traitValueId,
                        traitId: traitId,
                        label: value.label,
                        amount: 1,
                    });
                } else {
                    const value = values.get(traitValueId);
                    value.amount = value.amount + 1;
                }
            }
        });
    });

    return filteredTokenTraits;
};

/**
 *  Given a set of trait values filters choosen by the user, returns a list of all the tokenIds
 *  that match this trait filters.
 */
export const getTraitsFilteredTokens = (filteredTraits: FilteredTraitsValues): TokenStringId[] => {
    // if no trait filters applied
    if (!filteredTraits.size) {
        return null;
    }

    const tokensPerTraitMap = useCollectionTokensStore.getState().tokensPerTraitMap;

    // collection tokens not loaded yet
    if (!tokensPerTraitMap.size) {
        return null;
    }

    const tokensPerTrait: TokenStringId[][] = [];

    // builds an array of tokens filtered by trait (union of all trait's selected values).
    // For each TRAIT we build a value of all the tokens that match this trait's selected values.
    for (const filteredTrait of filteredTraits.values()) {
        const currentTraitFilteredTokenIds: TokenStringId[] =
            getFilteredTokenIdsByTrait(filteredTrait);
        if (currentTraitFilteredTokenIds?.length) tokensPerTrait.push(currentTraitFilteredTokenIds);
    }

    // Check that at least one token passed the filters
    if (!tokensPerTrait?.length) {
        return null;
    }

    // We can now merge all the traits. This is an AND operation, we need to filter the tokens
    // that appear on each filtered trait.
    const filteredTokens: TokenStringId[] = tokensPerTrait.reduce((a, b) =>
        a.filter((c) => b.includes(c)),
    );

    // Check that at least one token is present on all filtered traits
    if (!filteredTokens?.length) {
        return null;
    }

    return filteredTokens;
};

//-----------------------------------------------------------------------

/**
 * Given a trait filter return all the tokens matching the selected trait values.
 */
const getFilteredTokenIdsByTrait = ({ id, values }: TraitFilter): TokenStringId[] => {
    let traitTokensIds: TokenStringId[] = [];

    for (const traitValueId of values.keys()) {
        traitTokensIds = traitTokensIds.concat(getTraitValueTokenIds(id, traitValueId));
    }

    return traitTokensIds;
};

/**
 * Returns all the tokenStringIds for a given trait value
 */
export const getTraitValueTokenIds = (
    traitId: TraitId,
    traitValueId: TraitValueId,
): TokenStringId[] => {
    const tokensPerTraitMap = useCollectionTokensStore.getState().tokensPerTraitMap;
    return [...tokensPerTraitMap.get(traitId).get(traitValueId)];
};
