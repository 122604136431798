import create from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export enum SignInModalState {
    Closed = 1,
    SignUp,
    SignIn
}

type BasicStorState = {
    promoCode?: string | null;
    betaSignupToastIsOpen?: boolean;
    signInModalState: SignInModalState;
};

const defaultState: BasicStorState = {
    promoCode: null,
    signInModalState: SignInModalState.Closed,
}

export const useBasicStore = create<BasicStorState>()(
    subscribeWithSelector((set) => (defaultState)),
);