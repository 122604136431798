import { useEffect, useState, useRef } from "react";
import { isDesktopSize, isPortraitSize, isNarrowPortraitSize } from "@utils/helpers";
import { type } from "os";

type UseIsDesktopOptions = {
    basisType: "app" | "site";
    basisWidth?: number;
};

type UseDebouncedWindowSizeRegistrations = {
    useIsMounted?: boolean;
    useWindowHeight?: boolean;
    useWindowWidth?: boolean;
    useIsDesktop?: boolean | UseIsDesktopOptions;
    useIsNarrowPortrait?: boolean;
    useIsPortrait?: boolean;
};

type UseDebouncedWindowSizeOptions = {
    debounceRateMilliseconds?: number;
};

const useDebouncedWindowSize = (
    {
        useIsMounted,
        useWindowHeight,
        useWindowWidth,
        useIsDesktop,
        useIsNarrowPortrait,
        useIsPortrait,
    }: UseDebouncedWindowSizeRegistrations = {
        useIsMounted: false,
        useWindowHeight: false,
        useWindowWidth: false,
        useIsDesktop: false,
        useIsNarrowPortrait: false,
        useIsPortrait: false,
    },
    { debounceRateMilliseconds }: UseDebouncedWindowSizeOptions = { debounceRateMilliseconds: 100 },
) => {
    const windowSizeRef = useRef({
        isDesktop: true,
        isNarrowPortrait: false,
        isPortrait: false,
        innerHeight: 400,
        innerWidth: 960,
    });

    const isDesktopSizeHelper = () => {
        const useIsDesktopAsOptions = useIsDesktop as UseIsDesktopOptions;
        if (useIsDesktopAsOptions?.basisType != null) {
            return isDesktopSize(useIsDesktopAsOptions.basisType, useIsDesktopAsOptions.basisWidth);
        } else {
            return isDesktopSize("app");
        }
    };

    const [windowSize, setWindowSize] = useState({
        isMounted: false,
        windowHeight: 0,
        windowWidth: 0,
        isDesktop: isDesktopSizeHelper(),
        isNarrowPortrait: isNarrowPortraitSize(),
        isPortrait: isPortraitSize(),
    });

    useEffect(() => {
        if (!windowSize.isMounted && typeof window !== "undefined") {
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const isDesktop: boolean = isDesktopSizeHelper();
            const isPortrait: boolean = isPortraitSize();
            const isNarrowPortrait: boolean = isNarrowPortraitSize();

            setWindowSize({
                isMounted: true,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                isDesktop,
                isNarrowPortrait,
                isPortrait,
            });
        }

        let debounceTimeoutId = null;

        function handleResize() {
            clearTimeout(debounceTimeoutId);

            debounceTimeoutId = setTimeout(() => {
                const { innerHeight, innerWidth } = window;

                const isDesktop: boolean = isDesktopSizeHelper();
                const isPortrait: boolean = isPortraitSize();
                const isNarrowPortrait: boolean = isNarrowPortraitSize();

                const sizeRef = windowSizeRef.current;

                if (
                    // isDesktop !== sizeRef.isDesktop ||
                    // isPortrait !== sizeRef.isPortrait ||
                    // isNarrowPortrait !== sizeRef.isNarrowPortrait ||
                    // (!isPortrait && sizeRef.innerWidth < 960 && innerWidth >= 960)

                    (useWindowHeight && innerHeight != sizeRef.innerHeight) ||
                    (useWindowWidth && innerWidth != sizeRef.innerWidth) ||
                    (useIsDesktop && isDesktop !== sizeRef.isDesktop) ||
                    (useIsNarrowPortrait && isNarrowPortrait !== sizeRef.isNarrowPortrait) ||
                    (useIsPortrait && isPortrait != sizeRef.isPortrait)
                ) {
                    // console.debug("resizing...");
                    windowSizeRef.current = {
                        isDesktop,
                        isNarrowPortrait,
                        isPortrait,
                        innerHeight,
                        innerWidth,
                    };

                    setWindowSize({
                        isMounted: true,
                        windowHeight: innerHeight,
                        windowWidth: innerWidth,
                        isDesktop,
                        isNarrowPortrait,
                        isPortrait,
                    });
                }
            }, debounceRateMilliseconds);
        }

        window.addEventListener("resize", handleResize, false);
        window.addEventListener("orientationchange", handleResize, false);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("orientationchange", handleResize);
        };
    }, []);

    return windowSize;
};

export default useDebouncedWindowSize;
