import Script from "next/script";
import { useEffect, useState } from "react";

type GoogleAnalyticsControllerProps = { measurementId: string };

export const GoogleAnalyticsController = ({ measurementId }: GoogleAnalyticsControllerProps) => {
    return (
        <>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
            ></Script>
            <Script
                id="google-analytics"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${measurementId}', {
                    page_path: window.location.pathname,
                    });
                    `,
                }}
            />
        </>
    );
};

export default GoogleAnalyticsController;
