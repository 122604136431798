import React, { useEffect, useRef } from "react";
import Image from "next/image";
import {
    Box,
    CloseButton,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useMediaQuery,
    VStack,
} from "@chakra-ui/react";
import useUser from "@hooks/useUser";
import PlatformButton from "./PlatformButton";
import { SignInModalState, useBasicStore } from "@store/basicStore";
import { useRouter } from "next/router";

type SignUpModalProps = {};

const SignUpModal = ({}: SignUpModalProps): JSX.Element => {
    const [isFullScreen] = useMediaQuery("(max-width: 768px)");
    const router = useRouter();
    const signInModalState = useBasicStore((state) => state.signInModalState);
    const { signIn } = useUser();

    const isModalOpen = signInModalState !== SignInModalState.Closed;

    const onModalClose = () => {
        useBasicStore.setState((oldState) => {
            return {
                signInModalState: SignInModalState.Closed,
            };
        });
    };

    useEffect(() => {
        if (signInModalState !== SignInModalState.Closed) {
            document.documentElement.style.overflowY = "hidden";
            document.documentElement.style.paddingRight = "10px";
        } else {
            document.documentElement.style.overflowY = "auto";
            document.documentElement.style.paddingRight = "initial";
        }
    }, [signInModalState]);

    const loginWithPlatform = async (
        platform: "google" | "twitter" | "facebook",
    ): Promise<void> => {
        await signIn(platform, {
            redirect: false,
            callbackUrl: `/user/welcome?redirect=${router.asPath}`,
        });
    };

    return (
        <Modal
            id="signup-modal"
            isOpen={isModalOpen}
            onClose={onModalClose}
            isCentered={!isFullScreen}
            size={isFullScreen ? "full" : undefined}
        >
            <ModalOverlay />
            <ModalContent
                w="100%"
                bg="transparent"
                background="transparent"
                maxW="740px"
                m={{ base: 0 }}
            >
                <ModalBody
                    bg="transparent"
                    background="transparent"
                    maxW="740px"
                    w="100%"
                    p="0"
                    position="relative"
                >
                    <Box layerStyle="auth.modals.base" w="100%" maxW="740px" position="relative">
                        <Box
                            px={{ base: "30px", md: "115px" }}
                            pt={{ base: "30px", md: "280px" }}
                            pb="16px"
                            position="relative"
                            textAlign="center"
                        >
                            <Box
                                w={{ base: "200px", sm2: "280px" }}
                                h={{ base: "200px", sm2: "280px" }}
                                position={{ base: "relative", md: "absolute" }}
                                left="50%"
                                top="0"
                                transform={{
                                    base: "translate(-50%, 0)",
                                    md: "translate(-50%, -30px)",
                                }}
                                mb={{ base: "20px", md: "auto" }}
                            >
                                <Image
                                    src="/images/auth/yeti.png"
                                    alt="Beyond Rarity-yeti"
                                    layout="fill"
                                    objectFit="contain"
                                />
                            </Box>
                            <Text
                                fontSize={{ base: "24px", sm2: "30px" }}
                                mb={6}
                                lineHeight={{ base: "110%", md: "initial" }}
                            >
                                Sign{" "}
                                {signInModalState == SignInModalState.SignUp ? "up on" : "into"}{" "}
                                Beyond Rarity
                            </Text>
                            <Text
                                fontWeight={300}
                                fontSize={{ base: "18px", sm2: "20px" }}
                                color="#3F4964"
                                lineHeight={{ base: "120%", md: "initial" }}
                            >
                                Join our community of folks discovering and sharing the latest
                                collections of digital collectibles
                            </Text>
                            <VStack mt="36px" gap="16px" minH="156px">
                                <PlatformButton
                                    platform="google"
                                    label="Sign in with Google"
                                    maxW="370px"
                                    w="94%"
                                    onClick={loginWithPlatform}
                                />
                                <HStack my="22px" gap="16px">
                                    <PlatformButton
                                        platform="twitter"
                                        onClick={loginWithPlatform}
                                    />
                                    {/* <PlatformButton
                                        platform="facebook"
                                        onClick={loginWithPlatform}
                                    /> */}
                                </HStack>
                            </VStack>
                            <Text
                                pt={{ base: "15px", md: "20px" }}
                                fontWeight={300}
                                fontSize={{ base: "14px", sm2: "16px" }}
                                color="#3F4964"
                            >
                                We’ll never post to any of your accounts without your permission.
                            </Text>
                        </Box>
                    </Box>
                    <CloseButton
                        display={isFullScreen ? undefined : "none"}
                        position="fixed"
                        bottom="10px"
                        right="10px"
                        zIndex={10000}
                        w="40px"
                        h="40px"
                        rounded="50em"
                        bg="gold.500"
                        color="blue.darker"
                        _hover={{
                            bg: "gold.700",
                        }}
                        _focus={{
                            ring: 0,
                            bg: "gold.700",
                        }}
                        boxShadow="0px 0px 20px 10px #0009"
                        onClick={onModalClose}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SignUpModal;
