import { CloseButton } from "@chakra-ui/react";
import React from "react";

type MobileCloseButtonProps = {
    onClick?: () => void;
};

const MobileCloseButton = ({ onClick }: MobileCloseButtonProps): JSX.Element => {
    return (
        <CloseButton
            position="fixed"
            bottom="10px"
            right="10px"
            zIndex={10000}
            w="40px"
            h="40px"
            rounded="50em"
            bg="gold.500"
            color="blue.darker"
            _hover={{
                bg: "gold.700",
            }}
            _focus={{
                ring: 0,
                bg: "gold.700",
            }}
            boxShadow="0px 0px 20px 10px #0009"
            onClick={onClick}
        />
    );
};

export default MobileCloseButton;
