
import { Flex, Icon, Link } from "@chakra-ui/react";
import { BsMedium } from "react-icons/bs";
import { FaDiscord, FaTwitter } from "react-icons/fa";

type SocialMediaIconsProps = {
    iconSize?: string | number;
}

export const SocialMediaIcons = ({iconSize=32} : SocialMediaIconsProps) => {

    return (
        <Flex alignItems="end" justifyContent="center" gap="4" >
            <Link href="https://twitter.com/Beyond_Rarity" _focus={{ outline: 'none' }} isExternal>
                <Icon _hover={{ color: "#FA9B1E" }} color="sorbet" fontSize={iconSize} as={FaTwitter} />
            </Link>
            <Link href="https://discord.gg/beyondrarity" _focus={{ outline: 'none' }} isExternal>
                <Icon _hover={{ color: "#FA9B1E" }} color="sorbet" fontSize={iconSize} as={FaDiscord} />
            </Link>
            <Link href="https://medium.com/the-brr" _focus={{ outline: 'none' }} isExternal>
                <Icon _hover={{ color: "#FA9B1E" }} color="sorbet" fontSize={iconSize} as={BsMedium} />
            </Link>
        </Flex>
    );
};

export default SocialMediaIcons;
