import { Avatar, Box, Flex, Icon, Image } from "@chakra-ui/react";
import { memo } from "react";

type SignedInUserAvatarProps = {
    name: string;
    image: string;
    buttonSize: any;
    isMobile: boolean;
};

export const SignedInUserAvatar = ({
    name,
    image,
    buttonSize,
    isMobile,
}: SignedInUserAvatarProps) => {
    // console.debug("avatar image: ", image)

    const initials = name
        .split(" ")
        .map((n) => n[0].toUpperCase())
        .join("");

    const buttonSizeNumber = +buttonSize.replace("px","");

    const FallBack = () => {
        return (
            <>
                <Flex
                    boxSize={buttonSize}
                    rounded="full"
                    color="white"
                    bgColor="sorbet"
                    justify="center"
                    align="center"
                    fontSize={(buttonSizeNumber * .55) + "px"}
                >
                    <Flex lineHeight="100%">{initials}</Flex>
                </Flex>
            </>
        );
    };
    return (
        <>
            {/* <Avatar
                name={name}
                src={image}
                boxSize={buttonSize}
                size={isMobile ? "md" : "sm"}
                color="white"
                bg="sorbet"
                loading="eager"
                referrerPolicy="no-referrer"
            /> */}
            <Image
                src={image}
                alt="Beyond Rarity - Profile Image"
                referrerPolicy="no-referrer"
                boxSize={buttonSize}
                rounded="full"
                loading="eager"
                fallback={<FallBack />}
            />
        </>
    );
};

export const MemoSignedInUserAvatar = memo(SignedInUserAvatar);
