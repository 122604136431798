import create from "zustand";
import produce from "immer";
import { subscribeWithSelector } from "zustand/middleware";
import {
    buildCollectionTraits,
    buildCollectionTraitsMap,
    buildCollectionVibesMap,
    removeEmptyVibes,
} from "./helpers";
import { setBritelineVisibility } from "./sessionStore";
import { getCollectionData } from "@apiSrc";

type CollectionState = {
    collectionId?: string;
    loading?: boolean;
    name?: string;
    collection?: CollectionData;
    // TODO: Check if we will finally be using the traits LIST or MAP!
    traits: Trait[];
    collectionTraitsMap: TraitsMap; // The traits map with the default count for each value
    traitsMap: TraitsMap; // The map used on TraitsFilterAccordion
    vibes: Vibe[];
    vibesMap: VibesMap;
    briteline: boolean;
    listingsLastUpdate?: string;
    britelineLastUpdate?: string;
    dataLastUpdate?: string;
    alreadyShowingLiveUdate?: boolean;
};

const initialState = {
    loading: true,
    traits: [],
    traitsMap: new Map(),
    collectionTraitsMap: new Map(),
    vibes: [],
    vibesMap: new Map(),
    briteline: true,
    alreadyShowingLiveUdate: false,
};

// Main store for app state.
const useCollectionStore = create<CollectionState>()(
    subscribeWithSelector((set) => ({ ...initialState })),
);

export const setCollectionId = (collectionId: string) => {
    useCollectionStore.setState((state) => ({
        ...state,
        ...initialState,
        collectionId: collectionId,
        briteline: state.briteline,
        listingsLastUpdate: "",
        britelineLastUpdate: "",
        dataLastUpdate: "",
    }));
};

export const switchCollection = (
    collectionId: string,
    name?: string,
    contract?: string,
    description?: string,
    briteline?: boolean,
): void => {
    // do nothing if users changes to the current visible collection
    if (useCollectionStore.getState().collectionId === collectionId) {
        return;
    }

    useCollectionStore.setState(() => ({
        ...initialState,
        collectionId: collectionId,
        loading: true,
        collection: {
            collectionId,
            name,
            contract,
            description,
            slug: collectionId,
            itemsAmount: 0,
            ownersAmount: 0,
            floorPrice: 0,
            volume: 0,
        },
        briteline: briteline || false,
    }));

    setBritelineVisibility(briteline || false);
};

/**
 * Saves a collection basic social data to the main store. This is done on each collection page load.
 * This data will be updated once the full collection data gets fetched after the collection page mounts.
 * @param collectionSocialData
 */
export const setCollectionSocialData = (collectionSocialData: CollectionSocialShareData) => {
    useCollectionStore.setState(
        produce((state) => {
            state.collection = { ...state.collection, ...collectionSocialData };
        }),
    );
};
/**
 * Fetches the full collection data from the API and saves it to the main store.
 * @param collectionId
 * @param withTraitsAndVibes
 */
export const loadCollectionData = async (collectionId: string): Promise<void> => {
    useCollectionStore.setState((state) => ({ ...initialState, briteline: state.briteline }));

    try {
        const collection: CollectionData = await getCollectionData(collectionId);

        const {
            traits,
            vibes,
            britelineLastUpdate,
            listingsLastUpdate,
            dataLastUpdate,
            ...collectionData
        } = collection;

        const collectionTraits = buildCollectionTraits(traits);
        const collectionTraitsMap = buildCollectionTraitsMap(traits);
        const collectionVibesMap = buildCollectionVibesMap(vibes);
        // Remove vibes with zero tokens because they break the filters (i.e. wallstmoms has 2 of these vibes).
        const filteredVibes = removeEmptyVibes(vibes);

        useCollectionStore.setState(() => ({
            loading: false,
            collection: { ...collectionData },
            traits: collectionTraits,
            traitsMap: collectionTraitsMap,
            collectionTraitsMap: collectionTraitsMap,
            vibes: filteredVibes,
            vibesMap: collectionVibesMap,
            briteline: collectionData?.briteline || false,
            listingsLastUpdate,
            britelineLastUpdate,
            dataLastUpdate,
        }));

        console.log(`Info loaded for ${collectionId}.`);

        setBritelineVisibility(collectionData?.briteline || false);
    } catch (e: any) {
        console.log(e);
        if (e.name == "AbortError") {
            // console.info("Fetch Aborted");
        }
    }
};

export default useCollectionStore;
